import axios from "axios";

const USERS = `${process.env.REACT_APP_DOMAIN_NAME}/api/users/`;
const DATA_COMBOS = `${process.env.REACT_APP_DOMAIN_NAME}/api/data-form-user`;

export const get_all_users = async () => {
    return await axios.get(`${USERS}`);
}

export const get_all_user_roles = async () => {
    return await axios.get(`${DATA_COMBOS}`);
}

export const store_new_user = async (values) => {
    return await axios.post(`${USERS}`, values)
} 

export const update_selected_user = async (user, values) => {
    return await axios.put(`${USERS}${user.id}`, values)
}