import React from "react";
import { useSubheader } from "../../../_metronic/layout";
import { useSelector } from "react-redux";
import MiInformacion from "../../components/profile/MiInformacion";
import MiDocumentacion from "../../components/profile/MiDocumentacion";
import { useFetchPersonCurses } from "./hooks/UsersHooks";
import Loader from "../../components/registerwizard/Loader/Loader";
import { useState } from "react";
import ModalUploadDocumentacion from "../../components/profile/ModalUploadDocumentacion";
import ErrorMessage from "../../components/General/ErrorMessage";
import MisCursos from "../../components/profile/MisCursos";
import MiSituacionLaboral from "../../components/profile/MiSituacionLaboral";

const Perfil = () => {
  const [showModal, setShowModal] = useState(false);
  const [showMotivoRechazo, setShowMotivoRechazo] = useState(false);
  const [trabajaActualmente, setTrabajaActualmente] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState();
  const { persona } = useSelector((state) => state.auth);
  const suhbeader = useSubheader();
  suhbeader.setTitle("Mi Perfil");
  useFetchPersonCurses(persona, setLoading, setTrabajaActualmente);

  const handleShow = () => {
    setShowModal(!showModal);
  };

  if (loading) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="card card-custom">
                <div className="container-fluid">
                  <div className="row">
                    <Loader></Loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="container-fluid bg-white rounded p-5 mb-3">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12 mb-5">
              <h3 className="text-center">
                {persona.apellido}, {persona.nombre}
              </h3>
              <hr />
            </div>
            <div className="col-md-12 col-sm-12 col-12 mt-2">
              <MiInformacion
                persona={persona}
                errorHandling={setError}
              ></MiInformacion>
              <hr />
            </div>
            <div className="col-xl-12 col-md-12 col-sm-12 col-12 mt2">
              <MiSituacionLaboral persona={persona}></MiSituacionLaboral>
              <hr />
            </div>
            <div className="col-md-12 col-sm-12 col-12 mt-2">
              <MiDocumentacion
                showModalDocumentacionHandle={setShowModal}
                persona={persona}
              ></MiDocumentacion>
              <hr />
            </div>
            <div className="col-md-12 col-sm-12 col-12 mt-2">
              <MisCursos></MisCursos>
            </div>
          </div>
        </div>
        <ModalUploadDocumentacion
          show={showModal}
          persona={persona}
          handleShow={handleShow}
          errorHandling={setError}
        ></ModalUploadDocumentacion>
        <ErrorMessage onLoad={error}></ErrorMessage>
      </>
    );
  }
};

export default Perfil;
