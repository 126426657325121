import * as Yup from "yup";

export const instanceValidationSchema = (carreras) => {
  return Yup.object().shape({
    carrera_id: Yup.lazy((value) =>
      value === undefined || value === null || value === ""
        ? Yup.string()
            .nullable()
            .required("La carrera es obligatoria.")
        : Yup.string()
            .oneOf(
              carreras?.map((carr) => `${carr.id}`) || [],
              "Por favor, seleccione un elemento."
            )
            .typeError("Por favor, seleccione un elemento.")
            .required("El género es obligatorio.")
    ),
    descripcion: Yup.string()
      .required("La descripción es obligatoria")
      .max(255, "El campo no debe exceder 255 caracteres."),
    fecha_publicacion: Yup.date("Por favor, ingrese una fecha.").required(
      "La fecha de publicación es obligatoria."
    ),
    fecha_inicio: Yup.date("Por favor, ingrese una fecha.").required(
      "La fecha de inicio es obligatoria."
    ),
    fecha_fin: Yup.date("Por favor, ingrese una fecha.")
      .required("La fecha de fin es obligatoria.")
      .test(
        "fecha-fin",
        "La fecha de fin no puede ser menor a la fecha de inicio.",
        function(fechaFin) {
          return fechaFin >= this.resolve(Yup.ref("fecha_inicio"));
        }
      ),
  });
};
