import React, { useState } from 'react'
import TablaUsuarios from '../../../components/usuarios/tables/TablaUsuarios'
import { useFetch_allUsers } from './_hooks/usersHooks'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/registerwizard/Loader/Loader';
import ModalNewUser from '../../../components/usuarios/modals/ModalNewUser';
import { addUser, updateUser } from './_redux/usersRedux';

export default function Usuarios() {

  const [modalNuevoUsuario, setModalNuevoUsuario] = useState(false);
  const [usuarioSelected, setSelectedUsuario] = useState();
  useFetch_allUsers();
  const users = useSelector((state) => state.users);

  const dispatch = useDispatch();

  const changeModalState = (val, item) => {
    setModalNuevoUsuario(val);
    if(item){
      setSelectedUsuario(item);
    }
    else{
      setSelectedUsuario(null);
    }
  }

  const updateUserInTable = (item) => {
    dispatch(updateUser(item));
  }

  const addUserInTable = (item) => {
    dispatch(addUser(item));
  }

 if(users?.users.length > 0 && users?.roles.length > 0) {
  return(
    <>
      <TablaUsuarios
        data={users?.users}
        changeModalState={changeModalState}
      ></TablaUsuarios>
      <ModalNewUser roles={users?.roles} show={modalNuevoUsuario} changeModalState={changeModalState} update={updateUserInTable} add={addUserInTable} userSelected={usuarioSelected}></ModalNewUser>
    </>
  )
 }
 else{
  return(
    <>
       <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="card card-custom">
                <div className="container-fluid">
                  <div className="row">
                    <Loader></Loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
 }
}
