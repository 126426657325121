import { UPDATE_INSTANCE } from "../../instances/_redux/instancesReducer";

export const SET_USERS = "SET_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const ADD_USER = "ADD_USER";
export const REMOVE_USER = "REMOVE_USER";
export const ROLES_COMBO = "ROLES_COMBO";

export function setUsers(users) {
  return {
    type: SET_USERS,
    payload: users,
  };
}

export function setCombos(combos) {
  return {
    type: ROLES_COMBO,
    payload: combos,
  };
}

export const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: user,
  };
};

export const addUser = (user) => {
  return {
    type: ADD_USER,
    payload: user,
  };
};

export const removeUser = (user) => {
  return {
    type: REMOVE_USER,
    payload: user,
  };
};

const initStates = {
  users: [],
  roles: [],
};

export function usersReducer(state = initStates, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case UPDATE_INSTANCE:
      return {
        ...state,
        users: state.users.map((int) =>
          int.id === action.payload.id ? action.payload : int
        ),
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case REMOVE_USER:
      return {
        ...state,
        users: state.users.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case ROLES_COMBO:
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
}
