import React from 'react'
import { NavLink } from "react-router-dom";

export default function HeaderAdminMenu({layoutProps, getMenuItemActive}) {
  return (
    <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/instancias')}`}>
                <NavLink className="menu-link" to="/instancias">
                    <span className="menu-text">Instancias</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}
            
            {/*begin::1 Level*/}
            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/cursos')}`}>
                <NavLink className="menu-link" to="/cursos">
                    <span className="menu-text">Cursos</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/inscripciones')}`}>
                <NavLink className="menu-link" to="/inscripciones">
                    <span className="menu-text">Inscripciones</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/usuarios')}`}>
                <NavLink className="menu-link" to="/usuarios">
                    <span className="menu-text">Usuarios</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*Classic submenu*/}
            {/*begin::1 Level*/}
            
        </ul>
    </>
  )
}
