import React from "react";
import SuccessMessage from "../General/SuccessMessage";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { inscribeme } from "../../pages/Usuarios/_redux/UsuariosCrud";
import { transformText } from "../../../_metronic/_helpers/GeneralHelpers";
import { CarrerasNewArr } from "./exampleCarreras";

export default function CustomModal({ show, arr, handleShowCustom, persona }) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSucces] = React.useState(false);
  const [isAvailable, setIsAvailable] = React.useState();
  const [filesError, setFilesError] = React.useState(false);
  const [isIngLoi, setIsIngLoi] = React.useState(false);
  const [resevedId, setReservedId] = React.useState(-1);

  const closeThisModal = () => {
    setLoading(false);
    setSucces(false);
    setIsAvailable("");
    setFilesError(false);
    handleShowCustom(false);
    setIsIngLoi(false);
    setReservedId(-1);
  };

  const validatePersonFiles = async () => {
    let flag = false;
    if (persona?.files && persona?.files?.length > 0) {
      const requiredFileTypes = [
        "dni_frente",
        "dni_reverso",
        "cuil",
        "partida_nacimiento",
        "analitico",
      ];
      const missingFiles = requiredFileTypes.filter(
        (type) => !persona.files.some((file) => file.name === type)
      );

      if (missingFiles.length > 0) {
        const textTransformed = missingFiles.map(transformText);
        const stringed = textTransformed.join(", ");

        setIsAvailable(
          `Ups! Al parecer te faltan subir los siguientes archivos: ${stringed} puedes hacerlo desde Mi Perfil > Mi Documentacion`
        );
        flag = true;
      } else {
        flag = false;
      }
    } else {
      setIsAvailable(
        "Ups! Al parecer no has cargado ningun archivo correspondiente (DNI, CUIL, Partida de nacimiento, etc). Puedes cargar los mismos dirigiendote a Mi Perfil > Mi Documentacion"
      );
      flag = true;
    }

    return flag;
  };

  const isIngAndLoiArr = (carrer_type_id) => {
    if (carrer_type_id === 1) {
      return true;
    } else return false;
  };

  const inscribirme = async (id, name, carrera_id) => {
    const validateResponse = await validatePersonFiles();
    if (isIngAndLoiArr(carrera_id)) {
      setIsIngLoi(true);
      setReservedId(id);
    } else if (!validateResponse) {
      try {
        setLoading(true);
        const res = await inscribeme(persona.id, id, name);
        if (res) {
          setLoading(false);
          setSucces(true);
        }
      } catch (error) {
        setFilesError(true);
        setIsAvailable(error.response.data.error[0].message);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setFilesError(true);
    }
  };

  return (
    <>
      <Modal
        show={show}
        size={"lg"}
        animation={false}
        className={`animate__animated modalBody ${
          show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
        }`}
        onHide={() => {}}
      >
        <Modal.Header closeButton className="custom-header">
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            <h3 className="custom-title">
              Selecciona la carrera/curso deseada!
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          {loading ? (
            <div className="modal-spinner-overlay">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}

          {success ? (
            <SuccessMessage
              title={"la inscripción"}
              opciones={true}
              optArray={[{ desc: "Cerrar", func: closeThisModal }]}
              moreContent={`
                    Tus datos seran corroborados para la aprobación de la inscripción`}
            ></SuccessMessage>
          ) : (
            <>
              <div className={`container custom-modal-body`}>
                <div className="row">
                  {filesError ? (
                    <>
                      <h5>{isAvailable}</h5>
                    </>
                  ) : (
                    <>
                      {isIngLoi ? (
                        <>
                          <h1>Antes de inscribirte en Ingeniería & Loi...</h1>
                          <h3>Necesitamos un paso más</h3>
                          <h4>
                            Por favor, indicanos cual es tu carrera de interés
                          </h4>
                          {CarrerasNewArr(resevedId).map((item, idx) => (
                            <React.Fragment key={idx}>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-xl-12 carrer-container p-9 my-5"
                                onClick={() =>
                                  inscribirme(resevedId, item.carrera)
                                }
                              >
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12 col-xl-12 box-title">
                                    <h4 className="box-title__element flex-column">
                                      {item.carrera}
                                    </h4>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12 col-xl-12 box-img justify-content-end text-right">
                                    <p className="inscribirme-text">
                                      Inscribirme
                                    </p>
                                    <span className="svg-icon svg-icon-4x svg-icon-white">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/Add-user.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <>
                          {arr &&
                            arr.length > 0 &&
                            arr?.map((item, idx) => (
                              <React.Fragment key={idx}>
                                <div
                                  className="col-12 col-sm-12 col-md-12 col-xl-12 carrer-container p-9 my-5"
                                  onClick={() =>
                                    inscribirme(
                                      item.id,
                                      item.carrera,
                                      item.carrera_id
                                    )
                                  }
                                >
                                  <div className="row">
                                    {item.carrera_id === 1 ? (
                                      <>
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-12 box-title">
                                          <h4 className="box-title__element flex-column">
                                            {item.descripcion}
                                          </h4>
                                          <div className="box-descriptive">
                                            <span className="box-title__item_descriptive">
                                              Fecha de fin de inscripción: {item.fecha_fin}
                                            </span>
                                            <span className="box-title__item_descriptive">
                                              Fecha de inicio de cursada:{" "}
                                              {item.fecha_inicio}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-12 box-title">
                                          <h4 className="box-title__element flex-column">
                                            {item.carrera}
                                          </h4>
                                          <div className="box-descriptive">
                                            <h5 className="box-title__item_descriptive">
                                              {item.descripcion}
                                            </h5>
                                            <span className="box-title__item_descriptive">
                                              Fecha de inicio:{" "}
                                              {item.fecha_inicio}
                                            </span>
                                            <span className="box-title__item_descriptive">
                                              Fecha de fin: {item.fecha_fin}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    <div className="col-12 col-sm-12 col-md-12 col-xl-12 box-img justify-content-end text-right">
                                      <p className="inscribirme-text">
                                        Inscribirme
                                      </p>
                                      <span className="svg-icon svg-icon-4x svg-icon-white">
                                        <SVG
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Communication/Add-user.svg"
                                          )}
                                        ></SVG>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {!success ? (
            <div className="row justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-secondary font-weight-bold px-9 py-4 my-3"
                onClick={closeThisModal}
              >
                Volver
              </button>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
