import { styled } from "@material-ui/styles";
import React, { useState } from "react";
import Calendar from "react-calendar";
import { dateFormatter } from "../../../_metronic/_helpers";

export default function Calendario({ handleChange, date }) {
  const [tdate, setTDate] = useState(date ? date : new Date());

  const dateChangeHandling = (newVal) => {
    setTDate(newVal);
    handleChange(dateFormatter(newVal));
  };
  return (
    <>
      <Calendar
        onChange={dateChangeHandling}
        value={tdate}
        locale="es-US"
      ></Calendar>
    </>
  );
}
