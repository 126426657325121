import React from "react";
import EstudiosSecundarios from "../../../pages/RegisterWizard/SubWizard/Step2/EstudiosSecundarios";
import OtrosEstudios from "../../../pages/RegisterWizard/SubWizard/Step2/OtrosEstudios";
import Lenguajes from "../../../pages/RegisterWizard/SubWizard/Step2/Lenguajes";
import ConocimientosInformaticos from "../../../pages/RegisterWizard/SubWizard/Step2/ConocimientosInformaticos";
import { Button } from "react-bootstrap";

export default function RegisterStep2({
  handleOtrosLenguajes,
  handleAddOtrosEstudiosChange,
  handleConocimientos,
  handleIdEstudio,
  handleNext,
  form,
  handleEstudiosArrayChange,
  handleBack,
  combos,
}) {
  const handleNextClick = () => {
    form.validateForm().then((errors) => {
      const estudioSecundarioErrors = Object.keys(errors).filter((field) =>
        field.startsWith("estudio_secundario")
      );

      const otrosEstudiosErrors = Object.keys(errors).filter((field) =>
        field.startsWith("otros_estudios")
      );

      if (
        estudioSecundarioErrors.length === 0 &&
        otrosEstudiosErrors.length === 0
      ) {
        handleNext();
      } else {
        console.log(form)
        form.setTouched({ ...form.touched, ...errors }, true);
      }
    });
  };

  return (
    <>
      <div className="container">
        <EstudiosSecundarios
          values={form.values}
          handleIdEstudio={handleIdEstudio}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          escuelas={combos.combos.escuelas}
        ></EstudiosSecundarios>
        <OtrosEstudios
          values={form.values}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          handleAddOtrosEstudiosChange={handleAddOtrosEstudiosChange}
          handleEstudiosArrayChange={handleEstudiosArrayChange}
          niveles={combos.combos.niveles_estudio}
          estados={combos.combos.estados_estudio}
        ></OtrosEstudios>
        <Lenguajes
          values={form.values}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          lenguajes={combos.combos.lenguajes}
          handleOtrosLenguajes={handleOtrosLenguajes}
        ></Lenguajes>
        <ConocimientosInformaticos
          values={form.values}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          conocimientosCombo={combos.combos.conocimientos}
          handleConocimientos={handleConocimientos}
        ></ConocimientosInformaticos>
        <Button
          xvariant="contained"
          type="button"
          className="btn btn-secondary py-3 px-9 mr-2"
          onClick={handleBack}
        >
          Atras
        </Button>
        <Button
          xvariant="contained"
          type="button"
          className="btn btn-primary py-3 px-9"
          onClick={handleNextClick}
        >
          Siguiente
        </Button>
      </div>
    </>
  );
}
