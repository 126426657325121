import React from "react";
import { Image, Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Calendario from "../asistencias/Calendario";

export default function ModalAsistencias({
  show,
  showingAsistenciasModal,
  nav,
  selectedInstance,
}) {
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleOptionClick = (opt) => {
    setSelectedOption(opt);
    renderContent(opt);
  };

  const renderContent = (opt) => {
    switch (opt) {
      case "new":
        nav.push(`/instancias/asistencias/${selectedInstance.id}`);
        break;
      case "edit":
        nav.push(`/instancias/asistencias/edit/${selectedInstance.id}`);
        break;
      default:
        return (
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-12 my-5">
                  <div
                    className="option-box"
                    style={{ backgroundColor: "#B4E7D9" }}
                    onClick={() => handleOptionClick("edit")}
                  >
                    <div className="option-box__title">
                      <span style={{ fontSize: "14px" }}>
                        Editar Registro Asistencia
                      </span>
                    </div>
                    <div className="option-box__img">
                      <Image
                        className=""
                        src={toAbsoluteUrl("/media/svg/avatars/021-boy-8.svg")}
                      ></Image>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12 my-5">
                  <div
                    className="option-box"
                    onClick={() => handleOptionClick("new")}
                    style={{ backgroundColor: "#FFD8B8" }}
                  >
                    <div className="option-box__title">
                      <span style={{ fontSize: "14px" }}>
                        Nuevo Registro de Asistencia
                      </span>
                    </div>
                    <div className="option-box__img">
                      <Image
                        className=""
                        src={toAbsoluteUrl("/media/svg/avatars/010-girl-4.svg")}
                      ></Image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
  };
  return (
    <>
      <Modal
        show={show}
        animation={false}
        size={"xl"}
        className={`animate__animated ${
          show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
        }`}
        onHide={() => {}}
      >
        <Modal.Header className="custom-header" closeButton>
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            <h3 className="custom-title">Asistencias</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {renderContent()}
            <div className="row justify-content-center">
              <div className="col-4 col-sm-4 col-md-4 col-xl-4 text-center">
                <button
                  className="btn btn-secondary py-4 px-2"
                  onClick={() => showingAsistenciasModal(false)}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
