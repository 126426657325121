export const ExampleMaterias = [
  {
    id: 1,
    carrera_id: 1,
    descripcion: "Física",
    nota_minima: 6,
  },
  {
    id: 2,
    carrera_id: 1,
    descripcion: "Análisis matemático",
    nota_minima: 6,
  },
  {
    id: 3,
    carrera_id: 2,
    descripcion: "Mátematica",
    nota_minima: 6,
  },
  {
    id: 4,
    carrera_id: 3,
    descripcion: "Materia TSA",
    nota_minima: 6,
  },
  {
    id: 5,
    carrera_id: 4,
    descripcion: "Materia de Alemán",
    nota_minima: 6,
  },
  {
    id: 6,
    carrera_id: 5,
    descripcion: "Materia de refrigeración",
    nota_minima: 6,
  },
];
