import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetState: "[Change State] Set State",
  SetPersona: "[Set Persona] Set Persona",
  SetSituacionLaboral: "[Set Situacion Laboral] Set Situacion Laboral",
  SetCursos: "[Set Cursos] Set Cursos",
  UpdateCursos: "[Update Cursos] Update Cursos",
};

const initialAuthState = {
  user: undefined,
  token: undefined,
  scope: undefined,
  state_data: undefined,
  persona: undefined,
  situacionlaboral: undefined,
  cursos: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: "initPersist",
    whitelist: [
      "user",
      "token",
      "scope",
      "state_data",
      "persona",
      "situacionlaboral",
      "cursos",
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token, user, scope, state_data, persona } = action.payload;
        return {
          token,
          user: user,
          scope: scope,
          state_data: state_data,
          persona: persona,
        };
      }

      case actionTypes.Register: {
        const { token } = action.payload;

        return { token, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return { token: undefined, user: undefined, scope: undefined };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetState: {
        return {
          ...state,
          state_data: { input_data: action.payload },
        };
      }

      case actionTypes.SetPersona: {
        return {
          ...state,
          persona: action.payload,
        };
      }

      case actionTypes.SetSituacionLaboral: {
        return {
          ...state,
          situacionlaboral: action.payload,
        };
      }

      case actionTypes.SetCursos: {
        return {
          ...state,
          cursos: action.payload,
        };
      }

      case actionTypes.UpdateCursos:
        return {
          ...state,
          cursos: state.cursos.map((int) =>
            int.id === action.payload.id ? action.payload : int
          ),
        };

      default:
        return state;
    }
  }
);

export const actions = {
  login: (token, scope, state_data, persona) => ({
    type: actionTypes.Login,
    payload: { token, scope, state_data, persona },
  }),
  /*register: token => ({
    type: actionTypes.Register,
    payload: { token }
  }),*/
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  stateFulfilled: (value) => ({
    type: actionTypes.SetState,
    payload: value,
  }),
  setPersona: (value) => ({
    type: actionTypes.SetPersona,
    payload: value,
  }),
  setSituacionLaboral: (value) => ({
    type: actionTypes.SetSituacionLaboral,
    payload: value,
  }),
  setCursos: (value) => ({
    type: actionTypes.SetCursos,
    payload: value,
  }),
  updateCursos: (value) => ({
    type: actionTypes.UpdateCursos,
    payload: value,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {
      data: { user },
    } = yield getUserByToken();

    yield put(actions.fulfillUser({ user }));
  });
}
