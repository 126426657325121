import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { generateEstudioSchema } from "../../../../components/registerwizard/schemas/wizardYupSchemas";
import { useFormik } from "formik";
import {
  find_description_by_id,
  find_in_arr_by_id,
} from "../../../../../_metronic/_helpers/GeneralHelpers";

export default function OtrosEstudios({
  handleAddOtrosEstudiosChange,
  values,
  errors,
  touched,
  handleChange,
  handleEstudiosArrayChange,
  handleBlur,
  handleTouchEstudios,
  niveles,
  estados,
}) {
  const [onAdd, setOnAdd] = useState(false);
  const [otrosEstudios, setOtrosEstudios] = useState(
    values ? values.otros_estudios : []
  );
  const [newEstudio, setNewEstudio] = useState({
    nivel_estudio_id: "",
    nombre: "",
    establecimiento: "",
    estado_estudio_id: "",
  });
  const [error, setError] = useState();

  const handleChangeIntern = (e) => {
    const { name, value } = e.target;
    setNewEstudio({
      ...newEstudio,
      [name]: value,
    });
  };

  const agregarEstudio = () => {
    if (
      newEstudio.nivel_estudio_id !== "" &&
      newEstudio.nombre !== "" &&
      newEstudio.establecimiento !== "" &&
      newEstudio.estado_estudio_id !== ""
    ) {
      setError(null);
      setOtrosEstudios([...otrosEstudios, newEstudio]);
      handleAddOtrosEstudiosChange(otrosEstudios);
      setNewEstudio({
        nivel_estudio_id: "",
        nombre: "",
        establecimiento: "",
        estado_estudio_id: "",
      });
    } else {
      setError(
        "Por favor, complete todos los campos antes de añadir un estudio."
      );
    }
  };

  const del = (id) => {
    const replace = otrosEstudios.filter(
      (item, index) => index !== parseInt(id)
    );
    setOtrosEstudios(replace);
    handleAddOtrosEstudiosChange(replace);
  };

  return (
    <>
      <div className="drag-drop-modal-box">
        <span className="svg-icon svg-icon-primary svg-icon-lg">
          <span className="svg-icon-primary svg-icon-2x">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Shopping/settings.svg")}
            />
          </span>
        </span>
        <h3>Otros Estudios</h3>
      </div>
      <div className="">
        {!onAdd ? (
          <>
            <div className="row my-5">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="add-study" onClick={() => setOnAdd(true)}>
                  <span className="svg-icon svg-icon-primary svg-icon-lg">
                    <span className="svg-icon-primary svg-icon-2x">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                      ></SVG>
                      <span className="add-estudy-span">Agregar Estudio</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row mb-5">
              <div className="col-md-6 col-sm-12 col-12 mb-5">
                <div className="row flex-column">
                  <div className="col-12">
                    <label className="form-label" htmlFor="">
                      Nivel de Estudio
                    </label>
                    <select
                      className={`form-control`}
                      onChange={handleChangeIntern}
                      value={newEstudio.nivel_estudio_id}
                      name="nivel_estudio_id"
                      id="nivel_estudio_id"
                    >
                      <option value="-1">Seleccione una opcion...</option>
                      {niveles.map((item, idx) => (
                        <React.Fragment key={idx}>
                          <option value={item.id}>{item.descripcion}</option>
                        </React.Fragment>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12 mb-5">
                <label htmlFor="" className="form-label">
                  Titulo
                </label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={newEstudio.nombre}
                  onChange={handleChangeIntern}
                  className={`form-control`}
                />
              </div>
              <div className="col-md-6 col-sm-12 col-12 mb-5">
                <label htmlFor="" className="form-label">
                  Establecimiento
                </label>
                <input
                  type="text"
                  id="establecimiento"
                  name="establecimiento"
                  value={newEstudio.establecimiento}
                  onChange={handleChangeIntern}
                  className={`form-control`}
                />
              </div>
              <div className="col-md-6 col-sm-12 col-12 mb-5">
                <div className="row flex-column">
                  <div className="col-12">
                    <label className="form-label" htmlFor="">
                      Estado
                    </label>
                    <select
                      className={`form-control`}
                      name="estado_estudio_id"
                      value={newEstudio.estado_estudio_id}
                      id="estado_estudio_id"
                      onChange={handleChangeIntern}
                    >
                      <option value="-1">Seleccione una opcion...</option>
                      {estados.map((item, idx) => (
                        <React.Fragment key={idx}>
                          <option value={item.id}>{item.descripcion}</option>
                        </React.Fragment>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary ml-5"
                type="button"
                onClick={agregarEstudio}
              >
                <span className="svg-icon svg-icon-white svg-icon-lg">
                  <span className="svg-icon-white svg-icon-2x">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Check.svg")}
                    ></SVG>
                  </span>
                </span>
                Guardar
              </button>
            </div>
            {error && <div className="text-danger">{error}</div>}
          </>
        )}
      </div>
      <div className="row mb-5">
        {otrosEstudios.length > 0 &&
          otrosEstudios?.map((item, idx) => (
            <React.Fragment key={idx}>
              <div className="col-xl-2 col-md-3 col-sm-6 col-12 otros-estudios__container">
                <div className="otros-estudios-container__header">
                  <p>
                    {find_description_by_id(
                      item.nivel_estudio_id,
                      niveles,
                      "descripcion"
                    )}
                  </p>
                  <span
                    className="svg-icon svg-icon-white svg-icon-lg"
                    onClick={() => del(idx)}
                  >
                    <span className="svg-icon-white svg-icon-2x">
                      <SVG
                        style={{ color: "#fff" }}
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Close-filled.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                </div>
                <div className="otros-estudios-container__desc-box">
                  <p>{item.nombre}</p>
                  <p>{item.establecimiento}</p>
                </div>
                <span className="label label-lg label-light-success label-inline">
                  {find_description_by_id(
                    item.estado_estudio_id,
                    estados,
                    "descripcion"
                  )}
                </span>
              </div>
            </React.Fragment>
          ))}
      </div>
    </>
  );
}
