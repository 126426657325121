import React, { useEffect } from "react";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import * as RegisterStep from "../../components/registerwizard/steps";
import ModalInitWizard from "../../components/registerwizard/ModalsWizards/ModalInitWizard";
import CustomStepIcon from "./CustomStepIcon";
import { connect, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  getWizardCombos,
  registerFromInstance,
  saveAllData,
  savePersonFiles,
} from "./_redux/registerCrud";
import {
  bloodTypes,
  documentType,
  estadosCiviles,
} from "./StaticCombos/staticCombos";
import Loader from "../../components/registerwizard/Loader/Loader";
import SVG from "react-inlinesvg";
import * as WizardForm from "../../components/registerwizard/schemas";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Button } from "react-bootstrap";
import { injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux";
import ErrorMessage from "../../components/General/ErrorMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepLabel: {
    color: "#fff",
  },
  subwizardContainer: {
    display: "grid",
    gridTemplateColumns: "1fr auto", // Dos columnas, una para el índice y otra para el contenido
    gridColumnGap: theme.spacing(2), // Espacio entre las columnas
    alignItems: "start", // Alinear el contenido al inicio de las columnas
  },
}));

function RegisterMainW({ fromInstance, carrera, stateFulfilled, setPersona }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [dataForm] = React.useState(new FormData());
  const [skipped, setSkipped] = React.useState(new Set());
  const [carreerSelected, setCarreerSelected] = React.useState();
  const [formCombos, setFormCombos] = React.useState();
  const steps = getSteps();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  //TO DO: Juntar todos los attr de los raw correspondientes a los servicios, para cuando venga el servicio principal solo renombrarlos
  const initialValues = WizardForm.generateInitialValues(
    user.user.email,
    fromInstance
  );

  const initialValuesInit = () => {
    const storedFormData = localStorage.getItem("formData");
    return storedFormData ? JSON.parse(storedFormData) : initialValues;
  };

  const wizardSchema = WizardForm.generateWizardSchema(
    bloodTypes,
    documentType,
    estadosCiviles,
    formCombos
  );

  const form = useFormik({
    initialValues: initialValuesInit(),
    validationSchema: wizardSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      console.log(values);
      try {
        setStatus({
          success: false,
          error: false,
          message: "",
        });
        actionLoading(true);

        if (fromInstance) {
          const {
            data: { persona },
          } = await saveAllData(values);

          if (fromInstance.carrera_id === 1) {
            await registerFromInstance(
              persona.id,
              fromInstance,
              carreerSelected
            )
              .then(() => {
                actionLoading(false);
                setSuccessModal(true);
                localStorage.removeItem("currentStep");
                localStorage.removeItem("formData");
              })
              .catch(() => {
                actionLoading(false);
                setStatus({
                  success: false,
                  error: true,
                  message:
                    "Ha ocurrido un error al registrar los datos. Por favor, intente de nuevo.",
                });
              });
          } else {
            await registerFromInstance(persona.id, fromInstance, carrera)
              .then(() => {
                actionLoading(false);
                setSuccessModal(true);
                localStorage.removeItem("currentStep");
                localStorage.removeItem("formData");
              })
              .catch(() => {
                actionLoading(false);
                setStatus({
                  success: false,
                  error: true,
                  message:
                    "Ha ocurrido un error al registrar los datos. Por favor, intente de nuevo.",
                });
              });
          }
        } else {
          const {
            data: { persona },
          } = await saveAllData(values);
          dataForm.append("persona_id", persona.id);
          savePersonFiles(dataForm).then(() => {
            stateFulfilled(true);
            setPersona(persona);
            actionLoading(false);
            setSuccessModal(true);
            localStorage.removeItem("currentStep");
            localStorage.removeItem("formData");
          });
        }
      } catch (error) {
        actionLoading(false);
        if (
          error?.response?.data?.error[0]?.error?.includes(
            "'users_email_unique'"
          )
        ) {
          setStatus({
            success: false,
            error: true,
            message:
              "El Email ingresado ya se encuentra en uso. Por favor, ingrese otro.",
          });
        } else {
          setStatus({
            success: false,
            error: true,
            message:
              "Ha ocurrido un error inesperado. Por favor, intentelo de nuevo.",
          });
        }
      }
    },
  });

  const handleAddOtrosEstudiosChange = (estudios) => {
    form.setFieldValue("otros_estudios", estudios);
  };

  const handleChangeDiscapacidades = (index, field, value) => {
    form.setFieldValue(`discapacidades[${index}].${field}`, value);
  };

  const handleAddDiscapacidad = (_arr) => {
    form.setFieldValue("discapacidades", _arr);
  };

  const handleOtrosLenguajes = (langs) => {
    form.setFieldValue("lenguajes", langs);
  };

  const handleIdEstudio = (id) => {
    form.setFieldValue("estudio_secundario.escuela_id", id);
  };

  const handleConocimientos = (conocimientos) => {
    form.setFieldValue("conocimientos", conocimientos);
  };

  const handleEstudiosArrayChange = (index, field, value) => {
    form.setFieldValue(`otros_estudios[${index}].${field}`, value);
  };

  //FX Carga de datos
  useEffect(() => {
    const storedStep = localStorage.getItem("currentStep");
    if (storedStep !== null && !isNaN(storedStep)) {
      setActiveStep(parseInt(storedStep));
    }

    const fetchCombos = async (setErrorMessage) => {
      try {
        const comboloco = await getWizardCombos(setErrorMessage);
        setFormCombos(comboloco);
      } catch (error) {
        setErrorMessage(true);
      }
    };

    fetchCombos(setErrorMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [activeStep]);

  function isStepOptional(step) {
    return false;
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function handleNext() {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    localStorage.setItem("formData", JSON.stringify(form.values));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    localStorage.setItem("currentStep", activeStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const actionLoading = (val) => {
    setLoading(val);
  };

  

  function getSteps() {
    return ["Datos Personales", "Educacion", "Situacion Laboral", "Confirmar"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <RegisterStep.Step1
            fromInstance={fromInstance ? true : false}
            combos={formCombos}
            documentos={documentType}
            tiposangre={bloodTypes}
            estadoscivil={estadosCiviles}
            classes={classes}
            handleNext={handleNext}
            form={form}
            handleChangeDiscapacidades={handleChangeDiscapacidades}
            handleAddDiscapacidad={handleAddDiscapacidad}
          ></RegisterStep.Step1>
        );
      case 1:
        return (
          <RegisterStep.Step2
            classes={classes}
            handleNext={handleNext}
            handleIdEstudio={handleIdEstudio}
            form={form}
            combos={formCombos}
            handleBack={handleBack}
            handleAddOtrosEstudiosChange={handleAddOtrosEstudiosChange}
            handleEstudiosArrayChange={handleEstudiosArrayChange}
            handleOtrosLenguajes={handleOtrosLenguajes}
            handleConocimientos={handleConocimientos}
          ></RegisterStep.Step2>
        );
      case 2:
        return (
          <RegisterStep.Step3
            classes={classes}
            fromInstance={fromInstance}
            handleNext={handleNext}
            form={form}
            handleBack={handleBack}
            combos={formCombos}
          ></RegisterStep.Step3>
        );
      case 3:
        return (
          <RegisterStep.Step4
            dataForm={dataForm}
            classes={classes}
            handleNext={handleNext}
            form={form}
            handleBack={handleBack}
            fromInstance={fromInstance}
            set={setCarreerSelected}
            loading={loading}
          ></RegisterStep.Step4>
        );
      default:
        return "Unknown step";
    }
  }

  if (!formCombos) {
    return (
      <>
        {errorMessage ? (
          <>
            <ErrorMessage onLoad={true} />
          </>
        ) : (
          <>
            <div className="bg-white p-5 rounded">
              <Loader></Loader>
            </div>
          </>
        )}
      </>
    );
  } else {
    if (!successModal) {
      return (
        <div className={classes.root}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    StepIconComponent={CustomStepIcon}
                    StepLabelProps={{ classes: { label: classes.stepLabel } }}
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className="conatainer-fluid bg-white p-5 rounded">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-12 col-sm-12 col-12 ">
                {form.status && (
                  <div
                    className={`border-10 alert alert-custom  ${
                      form.status.success
                        ? "alert-light-success"
                        : form.status.error
                        ? "alert-light-danger"
                        : ""
                    } alert-dismissible`}
                  >
                    <div className="alert-text font-weight-bold">
                      {form.status.message}
                    </div>
                  </div>
                )}
                <form onSubmit={form.handleSubmit}>
                  {getStepContent(activeStep)}
                </form>
              </div>
            </div>
          </div>
          {!fromInstance ? <ModalInitWizard></ModalInitWizard> : ""}
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <div className="container-fluid bg-white p-5 rounded">
            <div className="row text-center">
              <div className="col-md-12 col-sm-12 col-12">
                <h5 style={{ marginBottom: "25px" }}>
                  Se han registrado con exito todos los datos cargados
                </h5>
                <div className="circle-svg">
                  <span className="svg-icon svg-icon-success svg-icon-7x">
                    <span className="svg-icon-success svg-icon-2x">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/General/Like.svg")}
                      ></SVG>
                    </span>
                  </span>
                </div>
                <div className="custom-continue-to-root">
                  <Link to="/">
                    <Button>Continuar</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default injectIntl(connect(null, auth.actions)(RegisterMainW));
