import React from "react";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function Loader() {
  return (
    <>
      <div className="container m-5">
        <div className="d-flex flex-column justify-content-around align-items-center">
          <img
            src={toAbsoluteUrl("/media/logos/loading-logo.png")}
            alt="Metronic logo"
            className="loader-img animate__animated animate__fadeIn items-spacing"
          />
          <p className="loading-text animate__animated animate__fadeInLeft items-spacing">
            Cargando...
          </p>
          <CircularProgress color="secondary"></CircularProgress>
        </div>
      </div>
    </>
  );
}
