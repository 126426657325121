import React from "react";
import TablaInstancias from "../../../components/instancias/TablaInstancias";
import ModalInstancias from "../../../components/instancias/ModalInstancias";
import { useState } from "react";
import ModalDelete from "../../../components/instancias/ModalDelete";
import { useFetchInstances } from "../hooks/backofficehooks";
import {
  removeInstance,
  updateInstance,
  addInstance,
} from "./_redux/instancesReducer";
import { useDispatch, useSelector } from "react-redux";
import ModalInscriptos from "../../../components/instancias/ModalInscriptos";
import { useHistory } from "react-router-dom";
import ModalAsistencias from "../../../components/instancias/ModalAsistencias";
import ModalNotas from "../../../components/instancias/ModalNotas";
import { find_in_arr_by_id } from "../../../../_metronic/_helpers/GeneralHelpers";
import Loader from "../../../components/registerwizard/Loader/Loader";

export default function Instancias() {
  const [showInscripcion, setShowInscripcion] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedIntance, setSelectedIntance] = useState();
  const [inscriptosModal, setInscriptosModal] = useState(false);
  const [asistenciasModal, setAsistenciasModal] = useState(false);
  const [notasModal, setNotasModal] = useState(false);
  const [carrera, setCarrera] = useState("");
  useFetchInstances();
  const instances = useSelector((state) => state.instances.instances);
  const combos = useSelector((state) => state.instances.combos);
  const dispatch = useDispatch();
  const nav = useHistory();

  const showingHandle = (value) => {
    setShowInscripcion(value);
    setSelectedIntance(null);
  };

  const showingAsistenciasModal = (val, item) => {
    setAsistenciasModal(val);
    if (item !== null && item !== undefined) {
      setSelectedIntance(item);
    } else {
      setSelectedIntance(null);
    }
  };

  const showingNotasModal = (val, item) => {
    setNotasModal(val);
    if (item !== null && item !== undefined) {
      setSelectedIntance(item);
    } else {
      setSelectedIntance(null);
    }
  };
  const showingInscriptosModal = (value, item) => {
    setInscriptosModal(value);

    if (item !== null && item !== undefined) {
      setSelectedIntance(item);
      setCarrera(
        find_in_arr_by_id(
          item.carrera_id,
          combos?.combos?.carreras,
          "id",
          "descripcion"
        )
      );
    } else {
      setSelectedIntance(null);
    }
  };

  const deleteItem = (value) => {
    setShowDelete(value);
  };

  const deletetoDataStructure = (obj) => {
    const updInstances = instances.filter((item) => item.id !== obj.id);
    localStorage.setItem("instancias", JSON.stringify(updInstances));
    dispatch(removeInstance(obj));
  };

  const showingDelete = (value) => {
    setSelectedIntance(value);
    setShowDelete(true);
  };

  const onClickEditInstance = (item) => {
    setShowInscripcion(true);
    setSelectedIntance(item);
  };

  const addToDataStructure = (item) => {
    dispatch(addInstance(item));
  };

  const editInDataStructure = (item) => {
    dispatch(updateInstance(item));
  };

  if (!instances.length > 0 && !Object.keys(combos).length > 0) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="card card-custom">
                <div className="container-fluid">
                  <div className="row">
                    <Loader></Loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div>
          <TablaInstancias
            data={instances}
            combos={combos?.combos}
            chooseInstance={onClickEditInstance}
            clickModalShow={showingHandle}
            showingDeleteInstance={showingDelete}
            showingInscriptosModal={showingInscriptosModal}
            showingAsistenciasModal={showingAsistenciasModal}
            showingNotasModal={showingNotasModal}
            nav={nav}
          ></TablaInstancias>
          <ModalInstancias
            combos={combos?.combos}
            addToDataStructure={addToDataStructure}
            itemSelected={selectedIntance}
            showInscripcion={showInscripcion}
            hiddingInscripcion={showingHandle}
            editInDataStructure={editInDataStructure}
          ></ModalInstancias>
          <ModalDelete
            showDelete={showDelete}
            deleteItem={deleteItem}
            deletetoDataStructure={deletetoDataStructure}
            item={selectedIntance}
          ></ModalDelete>
          <ModalInscriptos
            nav={nav}
            combos={combos}
            show={inscriptosModal}
            carrera={carrera}
            showingInscriptosModal={showingInscriptosModal}
            selectedInstance={selectedIntance}
          ></ModalInscriptos>
          <ModalAsistencias
            show={asistenciasModal}
            showingAsistenciasModal={showingAsistenciasModal}
            nav={nav}
            selectedInstance={selectedIntance}
          ></ModalAsistencias>
          <ModalNotas
            show={notasModal}
            showingNotasModal={showingNotasModal}
            nav={nav}
            selectedInstance={selectedIntance}
          ></ModalNotas>
        </div>
      </>
    );
  }
}
