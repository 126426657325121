import React from "react";
import { Modal } from "react-bootstrap";

export default function ModalMotivoRechazo({
  show,
  carrera,
  motivoRechazo,
  close,
}) {
  return (
    <Modal
      show={show}
      size={"lg"}
      animation={false}
      className={`animate__animated ${
        show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
      }`}
      onHide={() => {}}
    >
      <Modal.Body>
        <div className="text-center mb-4">
          <h4 className="mb-4">Motivo de rechazo de {carrera}</h4>
        </div>
        <div
          className="motivo-rechazo"
          dangerouslySetInnerHTML={{ __html: motivoRechazo }}
        />
        <div className="d-flex justify-content-center">
          <button className="btn btn-secondary btn-md" onClick={close}>
            Volver
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
