import React from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  getAusentes_by_fecha,
  getFilesFromPersona,
  getInscripciones,
  getInscripciones_x_instancia,
  getInstances,
  getInstanciaByID,
  getUserDetails,
} from "../_redux/reduxCrud";
import { useDispatch } from "react-redux";
import {
  getInscriptosAprobados,
  loadingtable,
  setInscripcion,
} from "../inscripciones/_redux/inscripcionesReducer";
import { setInstances } from "../instances/_redux/instancesReducer";

export const useFetchInscripciones = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const fetchingInscripciones = async () => {
      const thereisInscripciones = localStorage.getItem("inscripciones");
      if (thereisInscripciones) {
        dispatch(setInscripcion(thereisInscripciones));
      } else {
        const res = await getInscripciones();
        dispatch(setInscripcion(res.inscriptos ? res.inscriptos : res));
        localStorage.setItem(
          "inscripciones",
          JSON.stringify(res.inscriptos ? res.inscriptos : res)
        );
      }
    };
    fetchingInscripciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useFetchingInscriptos_x_instancia = async () => {
  const dispatch = useDispatch();
  const { instance } = useParams();

  React.useEffect(() => {
    dispatch(loadingtable(true));
    const fetchIns_x_instancia = async (instance) => {
      const res = await getInscripciones_x_instancia(instance);
      dispatch(setInscripcion(res.inscriptos ? res.inscriptos : res));
      dispatch(loadingtable(false));
    };

    const fetchInstancias = async () => {
      const res = await getInstances();
      dispatch(setInstances(res.instancias));
      dispatch(loadingtable(false));
    };

    if (instance) {
      fetchIns_x_instancia(instance);
    } else {
      fetchInstancias();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);
};

export const useInscritos_asistencias = async (
  instance,
  setActiveStep,
  setEditMode,
  setLoadData,
  setIsNotas,
  setInstanciaSelected
) => {
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    dispatch(getInscriptosAprobados([]));
    if (localStorage.getItem("storageInstanceAsistencias" != instance)) {
      localStorage.removeItem("storageInstanceAsistencias");
      localStorage.removeItem("stepAsistencias");
    }
    const storedStep = localStorage.getItem("stepAsistencias");
    if (storedStep !== null && !isNaN(storedStep)) {
      setActiveStep(parseInt(storedStep));
    }

    const realizeFetch = async (setLoadData) => {
      const res = await getInscripciones_x_instancia(instance);
      const ap_x_ins = res.inscriptos?.filter((ins) => ins.estado_id == 2);
      dispatch(getInscriptosAprobados(ap_x_ins.length > 0 ? ap_x_ins : null));
      setLoadData(true);
    };
    if (location.pathname.includes("asistencias/edit")) {
      dispatch(getInscriptosAprobados([]));
      setEditMode(true);
    }
    if (location.pathname.includes("notas")) {
      setIsNotas(true);
    }
    if (location.pathname.includes("notas/edit")) {
      setIsNotas(true);
      setEditMode(true);
    }

    const realizeFetchInstance = async (instance) => {
      const {
        data: { instancia },
      } = await getInstanciaByID(instance);
      setInstanciaSelected(instancia);
    };

    realizeFetchInstance(instance);
    realizeFetch(setLoadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);
};

export const useFetchProfileDetails = (persona_id) => {
  const [persona, setPersona] = React.useState();
  const [files, setFiles] = React.useState();
  React.useEffect(() => {
    const fetchingInscripciones = async () => {
      const res = await getUserDetails(persona_id);
      setPersona(res);
    };

    const getFiles = async (persona_id) => {
      const res = await getFilesFromPersona(persona_id);
      setFiles(res);
    };

    if (persona_id) {
      fetchingInscripciones();
      getFiles(parseInt(persona_id));
    } else {
      setPersona(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona_id]);
  return { persona, files };
};

//asistencias
