import React, { useEffect } from "react";
import {
  createInstance,
  getCombos,
  getInstances,
  getPersonaByDNI,
  modifyInstance,
} from "../_redux/reduxCrud";
import { useDispatch } from "react-redux";
import { setCombos, setInstances } from "../instances/_redux/instancesReducer";
//HOOOK PARA OBTENER LAS INSTANCIAS PARA EL COMPONENTE INSTANCIAS
export const useFetchInstances = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchingInstances = async () => {
      try {
        const res = await getInstances();
        dispatch(setInstances(res.instancias));
      } catch (error) {
        console.log(error);
      }
    };

    const fetchingCombos = async () => {
      try {
        const res = await getCombos();
        dispatch(setCombos(res));
      } catch (error) {
        console.log(error);
      }
    };

    fetchingInstances();
    fetchingCombos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const InstanceAction = async (action, data) => {
  if (!action) {
    return await createInstance(data);
  } else {
    return await modifyInstance(action, data);
  }
};

export const DniFetchingRealize = async (values) => {
  try {
    const res = await getPersonaByDNI(values.dni);
    return res.persona;
  } catch (error) {
    return null;
  }
};

