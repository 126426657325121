import React, { useRef } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export default function DatosNacimiento({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  paises,
  nacionalidades,
}) {
  const dateRef = useRef();
  const showPicker = () => {
    dateRef.current.focus();
  };
  return (
    <>
      <div className="container">
        <div className="drag-drop-modal-box">
          <span className="svg-icon svg-icon-primary svg-icon-lg">
            <span className="svg-icon-primary svg-icon-2x">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
          </span>
          <h3>Datos de Nacimiento</h3>
        </div>
        <div className="row mb-5">
          <div className="col-md-3 col-sm-12 col-12 mb-5">
            <label className="form-label">Fecha Nacimiento</label>
            <input
              type="date"
              id="fecha_nacimiento"
              name="persona.fecha_nacimiento"
              ref={dateRef}
              onClick={showPicker}
              value={values.persona.fecha_nacimiento}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control ${
                touched.persona?.fecha_nacimiento &&
                errors.persona?.fecha_nacimiento
                  ? "is-invalid"
                  : ""
              }`}
            />
            {touched.persona?.fecha_nacimiento &&
            errors.persona?.fecha_nacimiento ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {errors.persona.fecha_nacimiento}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-3 col-sm-12 col-12 mb-5">
            <label className="form-label">Lugar</label>
            <input
              type="text"
              id="lugar_nacimiento"
              name="persona.lugar_nacimiento"
              value={values.persona.lugar_nacimiento}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control ${
                touched.persona?.lugar_nacimiento &&
                errors.persona?.lugar_nacimiento
                  ? "is-invalid"
                  : ""
              }`}
            />
            {touched.persona?.lugar_nacimiento &&
            errors.persona?.lugar_nacimiento ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {errors.persona.lugar_nacimiento}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-3 col-sm-12 col-12 mb-5">
            <div className="row flex-column">
              <div className="col-12">
                <label className="form-label">Pais</label>
                <select
                  className={`form-control ${
                    touched.persona?.pais_id && errors.persona?.pais_id
                      ? "is-invalid"
                      : ""
                  }`}
                  name="persona.pais_id"
                  value={values.persona.pais_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="pais_id"
                >
                  <option value="-1">Seleccione una opcion...</option>
                  {paises.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <option value={item.id}>{item.descripcion}</option>
                    </React.Fragment>
                  ))}
                </select>
                {touched.persona?.pais_id && errors.persona?.pais_id ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {errors.persona.pais_id}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-12 mb-5">
            <div className="row flex-column">
              <div className="col-12">
                <label className="form-label">Nacionalidad</label>
                <select
                  className={`form-control ${
                    touched.persona?.nacionalidad_id &&
                    errors.persona?.nacionalidad_id
                      ? "is-invalid"
                      : ""
                  }`}
                  name="persona.nacionalidad_id"
                  id="nacionalidad_id"
                  value={values.persona.nacionalidad_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="-1">Seleccione una opción...</option>
                  {nacionalidades.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <option value={item.id}>{item.descripcion}</option>
                    </React.Fragment>
                  ))}
                </select>
                {touched.persona?.nacionalidad_id &&
                errors.persona?.nacionalidad_id ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {errors.persona.nacionalidad_id}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
