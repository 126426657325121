import { useDispatch } from "react-redux";
import { setCombos, setUsers } from "../_redux/usersRedux";
import { get_all_users, get_all_user_roles, store_new_user, update_selected_user } from "../_crud/usersCrud";
import { useEffect } from "react";

export const useFetch_allUsers = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const getUsers = async() => {
            try {
                const { data } = await get_all_users();
                dispatch(setUsers(data[0]));
                const {data: {combos: {roles}}} = await get_all_user_roles();
                dispatch(setCombos(roles));
            } catch (error) {
                console.log(error);
            }
        }

        getUsers();
    }, [])
}


/* FORM SUBMITINGS */

export const UserActions = async (action, item) => {

    if(!action){
        return await store_new_user(item);
    }
    else{
        return await update_selected_user(action, item);
    }
}