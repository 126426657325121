import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../modules/Auth/_redux/authRedux";

function SessionTimer({ isAuthenticated }) {
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(actions.logout());
  };

  useEffect(() => {
    let idleTimer;
    const timeout = 30 * 60 * 1000;

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(logoutUser, timeout);
    };

    const handleActivity = () => {
      clearTimeout(idleTimer);
      resetIdleTimer();
    };

    if (isAuthenticated) {
      resetIdleTimer();
      document.addEventListener("mousemove", handleActivity);
      document.addEventListener("keypress", handleActivity);
      document.addEventListener("scroll", handleActivity);

      return () => {
        clearTimeout(idleTimer);
        document.removeEventListener("mousemove", handleActivity);
        document.removeEventListener("keypress", handleActivity);
        document.removeEventListener("scroll", handleActivity);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, dispatch]);

  return null;
}

export default SessionTimer;
