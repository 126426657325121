import * as Yup from "yup";

export const generateEstudioSchema = () => {
  return Yup.object().shape({
    nivel_estudio_id: Yup.string().required("Campo Requerido"),
    titulo: Yup.string().required("Campo requerido"),
    establecimiento: Yup.string().required("Campo requerido"),
    estado_estudio_id: Yup.string().required("Campo requerido"),
  });
};

export const generateEditPersonaSchema = (
  bloodTypes,
  documentType,
  formCombos
) => {
  Yup.setLocale({
    number: {
      min: "Por favor, ingrese un numero valido",
      integer: "Por favor, ingrese un número valido",
      default: "Por favor, ingrese solo números",
    },
  });

  const schema = Yup.object().shape({
    nombre: Yup.string()
      .required("El nombre es obligatorio")
      .max(255, "El campo no debe exceder 255 caracteres."),
    apellido: Yup.string()
      .required("El apellido es obligatorio")
      .max(255, "El campo no debe exceder 255 caracteres."),
    numero_documento: Yup.number("Por favor, ingrese sólo números.")
      .required("El número de documento es obligatorio.")
      .test("is-eight-digits", "El número debe tener 8 dígitos", (value) => {
        if (!value) return false;
        return value.toString().length === 8;
      }),
    cuit: Yup.number("Debe ingresar sólo números.")
      .required("El cuit es obligatorio")
      .test("is-eight-digits", "El número debe tener 11 dígitos", (value) => {
        if (!value) return false;
        return value.toString().length === 11;
      }),
    hijos: Yup.number()
      .typeError("Por favor, ingrese sólo números")
      .min(0, "Por favor, ingrese un dato válido")
      .integer("Por favor, ingrese un número válido")
      .required("La cantidad de hijos es obligatoria."),
    tipo_sangre: Yup.string()
      .oneOf(bloodTypes.map((item) => `${item.id}`))
      .required("Por favor, Elija un tipo de Sangre."),
    tipo_documento: Yup.string()
      .oneOf(documentType.map((item) => `${item.id}`))
      .required("Por favor, Elija un tipo de documento")
      .required("Por favor, elija una opción."),
    estado_civil: Yup.string()
      .required("El estado civil es obligatorio.")
      .max(15, "El campo no debe exceder 15 caracteres."),
    telefono: Yup.number("Debe ingresar sólo números.")
      .positive("Por favor, ingrese un dato valido.")
      .integer("Por favor, Ingrese un dato valido.")
      .nullable(),
    celular: Yup.number("Debe ingresar sólo números.")
      .required("El celular es obligatorio.")
      .positive("Por favor, ingrese un dato valido.")
      .integer("Por favor, Ingrese un dato valido.")
      .test("is-eight-digits", "El número debe tener 10 dígitos", (value) => {
        if (!value) return false;
        return value.toString().length === 10;
      }),
    direccion: Yup.string()
      .required("La direccion es obligatoria.")
      .max(255, "El campo no debe exceder 255 caracteres."),
    pais_id: Yup.lazy((value) =>
      value === undefined || value === null || value === ""
        ? Yup.string()
            .nullable()
            .required("El género es obligatorio.")
        : Yup.string()
            .oneOf(
              formCombos?.combos?.paises?.map((genero) => `${genero.id}`) || []
            )
            .required("El género es obligatorio.")
    ),
    nacionalidad_id: Yup.lazy((value) =>
      value === undefined || value === null || value === ""
        ? Yup.string()
            .nullable()
            .required("El género es obligatorio.")
        : Yup.string()
            .oneOf(
              formCombos?.combos?.nacionalidades?.map(
                (genero) => `${genero.id}`
              ) || []
            )
            .required("El género es obligatorio.")
    ),
    genero_id: Yup.lazy((value) =>
      value === undefined || value === null || value === ""
        ? Yup.string()
            .nullable()
            .required("El género es obligatorio.")
        : Yup.string()
            .oneOf(
              formCombos?.combos?.generos?.map((genero) => `${genero.id}`) || []
            )
            .required("El género es obligatorio.")
    ),
    fecha_nacimiento: Yup.date("Por favor, ingrese una fecha.").required(
      "La fecha de nacimiento es obligatoria."
    ),
    lugar_nacimiento: Yup.string("Por favor, ingrese una fecha.").required(
      "El lugar de nacimiento es obligatorio"
    ),
    provincia: Yup.string()
      .required("La provincia es obligatoria.")
      .max(100, "El campo no debe exceder 100 caracteres."),
    localidad: Yup.string()
      .required("La localidad es obligatoria.")
      .max(100, "El campo no debe exceder 100 caracteres."),
    piso: Yup.number("Debe ingresar sólo números")
      .positive("Por favor, ingrese un dato valido.")
      .integer("Por favor, ingrese un dato valido.")
      .nullable(),
    departamento: Yup.string()
      .max(5, "El campo no debe exceder 5 caracteres.")
      .nullable(),
    email: Yup.string()
      .email("Por favor, Ingrese un dato válido")
      .required("El Email es requerido"),
  });

  return schema;
};

//TODO: Cambiar validacion provincia y localidad
export const generateWizardSchema = (
  bloodTypes,
  documentType,
  estadosCiviles,
  formCombos,
  fromInstance
) => {
  return Yup.lazy((values) =>
    Yup.object().shape({
      persona: Yup.object().shape({
        nombre: Yup.string()
          .required("El nombre es obligatorio.")
          .max(255, "El campo no debe exceder 255 caracteres."),
        apellido: Yup.string()
          .required("El apellido es obligatorio.")
          .max(255, "El campo no debe exceder 255 caracteres."),
        numero_documento: Yup.number()
          .typeError("Por favor, ingrese sólo números.")
          .integer("Por favor, ingrese un número válido.")
          .required("El número de documento es obligatorio.")
          .test(
            "is-eight-digits",
            "El número debe tener 8 dígitos",
            (value) => {
              if (!value) return false;
              return value.toString().length === 8;
            }
          ),
        cuit: Yup.number("Debe ingresar sólo números.")
          .typeError("Por favor, ingrese sólo números.")
          .integer("Por favor, ingrese un número válido.")
          .required("El cuit es obligatorio.")
          .test(
            "is-eight-digits",
            "El número debe tener 11 dígitos.",
            (value) => {
              if (!value) return false;
              return value.toString().length === 11;
            }
          ),
        hijos: Yup.number()
          .typeError("Por favor, ingrese solo números.")
          .min(0, "Por favor, ingrese un dato válido.")
          .integer("Por favor, ingrese un número válido.")
          .required("La cantidad de hijos es obligatoria."),
        tipo_sangre: Yup.string()
          .oneOf(bloodTypes.map((item) => `${item.id}`))
          .required("Por favor, Elija un tipo de Sangre."),
        tipo_documento: Yup.string()
          .oneOf(documentType.map((item) => `${item.id}`))
          .required("Por favor, elija un tipo de documento.")
          .required("Por favor, elija una opción."),
        estado_civil: Yup.string()
          .oneOf(estadosCiviles.map((item) => `${item.id}`))
          .required("Por favor, elija un estado civil."),
        telefono: Yup.number()
          .typeError("Por favor, ingrese solo números.")
          .positive("Por favor, ingrese un dato valido.")
          .integer("Por favor, Ingrese un dato valido.")
          .nullable(),
        celular: Yup.number("Debe ingresar sólo números.")
          .required("El celular es obligatorio.")
          .positive("Por favor, ingrese un dato valido.")
          .integer("Por favor, Ingrese un dato valido.")
          .test(
            "is-eight-digits",
            "El número debe tener 10 dígitos.",
            (value) => {
              if (!value) return false;
              return value.toString().length === 10;
            }
          ),
        direccion: Yup.string()
          .required("La direccion es obligatoria.")
          .max(255, "El campo no debe exceder 255 caracteres."),
        pais_id: Yup.lazy((value) =>
          value === undefined || value === null || value === ""
            ? Yup.string()
                .nullable()
                .required("El género es obligatorio.")
            : Yup.string()
                .oneOf(
                  formCombos?.combos?.paises?.map((genero) => `${genero.id}`) ||
                    []
                )
                .required("El género es obligatorio.")
        ),
        nacionalidad_id: Yup.lazy((value) =>
          value === undefined || value === null || value === ""
            ? Yup.string()
                .nullable()
                .required("El género es obligatorio.")
            : Yup.string()
                .oneOf(
                  formCombos?.combos?.nacionalidades?.map(
                    (genero) => `${genero.id}`
                  ) || []
                )
                .required("El género es obligatorio.")
        ),
        genero_id: Yup.lazy((value) =>
          value === undefined || value === null || value === ""
            ? Yup.string()
                .nullable()
                .required("El género es obligatorio.")
            : Yup.string()
                .oneOf(
                  formCombos?.combos?.generos?.map(
                    (genero) => `${genero.id}`
                  ) || []
                )
                .required("El género es obligatorio.")
        ),
        fecha_nacimiento: Yup.date("Por favor, ingrese una fecha.").required(
          "La fecha de nacimiento es obligatoria."
        ),
        lugar_nacimiento: Yup.string("Por favor, ingrese una fecha.").required(
          "El lugar de nacimiento es obligatorio."
        ),
        provincia: Yup.string()
          .required("La provincia es obligatoria.")
          .max(100, "El campo no debe exceder 100 caracteres.")
          .test(
            "not-negative-one",
            "Por favor, elija una provincia.",
            (value) => value !== "-1"
          ),
        localidad: Yup.string()
          .required("La localidad es obligatoria.")
          .max(100, "El campo no debe exceder 100 caracteres.")
          .test(
            "not-negative-one",
            "Por favor, elija una localidad",
            (value) => value !== "-1"
          ),
        piso: Yup.number("Debe ingresar sólo números.")
          .positive("Por favor, ingrese un dato valido.")
          .integer("Por favor, ingrese un dato valido.")
          .nullable(),
        departamento: Yup.string()
          .max(5, "El campo no debe exceder 5 caracteres.")
          .nullable(),
        email: Yup.string()
          .email("Por favor, Ingrese un dato válido.")
          .required("El Email es requerido."),
      }),
      estudio_secundario: Yup.object().shape({
        pais: Yup.string().when("escuela_id", {
          is: -1,
          then: Yup.string()
            .required("El pais es obligarotio.")
            .max(100, "El campo no debe exceder 100 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        provincia: Yup.string().when("escuela_id", {
          is: -1,
          then: Yup.string()
            .required("La provincia es obligatoria")
            .max(100, "El campo no debe exceder 100 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        localidad: Yup.string().when("escuela_id", {
          is: -1,
          then: Yup.string()
            .required("La localidad es obligatoria")
            .max(100, "El campo no debe exceder 100 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        colegio: Yup.string().when("escuela_id", {
          is: -1,
          then: Yup.string()
            .required("El nombre del establecimiento es obligatoria.")
            .max(150, "El campo no debe exceder 150 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        direccion_colegio: Yup.string().when("escuela_id", {
          is: -1,
          then: Yup.string()
            .required("La direccion del establecimiento es obligatoria.")
            .max(20, "El campo no debe exceder 20 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        tipo_colegio: Yup.string().when("escuela_id", {
          is: -1,
          then: Yup.string()
            .required("El tipo de colegio es obligatorio")
            .max(100, "El campo no debe exceder 100 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        orientacion: Yup.string().required('La orientación es requerida.'),
        anio_graduacion: Yup.number("Por favor, ingrese solo numeros.")
          .typeError("Por favor, ingrese sólo números.")
          .required("El año  de graduacion es obligatorio.")
          .positive("Por favor, ingrese un dato válido.")
          .integer("Por favor, ingrese un dato válido."),
        anios_cursados: Yup.number()
          .required("La cantidad de años de cursada.")
          .typeError("Por favor, ingrese sólo números.")

          .positive("Por favor, ingrese un dato válido.")
          .integer("Por favor, ingrese un dato válido."),
      }),
      otros_estudios: Yup.array()
        .of(
          Yup.object().shape({
            nivel_estudio_id: Yup.lazy((value) =>
              value === undefined || value === null || value === ""
                ? Yup.string()
                    .nullable()
                    .required("Por favor, seleccione una opcion.")
                : Yup.string()
                    .oneOf(
                      formCombos?.combos?.niveles_estudio?.map(
                        (nivel) => `${nivel.id}`
                      ) || []
                    )
                    .required("Por favor, seleccione una opcion.")
            ),
            titulo: Yup.string()
              .required("Por favor, complete el titulo.")
              .max(150, "El campo no debe exceder 150 caracteres."),
            establecimiento: Yup.string()
              .required("Por favor, complete el establecimiento.")
              .max(150, "El campo no debe exceder 150 caracteres."),
            estado_estudio_id: Yup.lazy((value) =>
              value === undefined || value === null || value === ""
                ? Yup.string()
                    .nullable()
                    .required("Por favor, seleccione una opcion.")
                : Yup.string()
                    .oneOf(
                      formCombos?.combos?.estados_estudio?.map(
                        (nivel) => `${nivel.id}`
                      ) || []
                    )
                    .required("Por favor, seleccione una opcion.")
            ),
          })
        )
        .nullable(),
      situacion_laboral: Yup.object().shape({
        trabaja_actualmente: Yup.string()
          .oneOf(["1", "0"], "Debe seleccionar un estado laboral.")
          .required("Debe seleccionar al menos un estado laboral."),
        ocupacion: Yup.string().when("trabaja_actualmente", {
          is: "1",
          then: Yup.string()
            .required("La ocupación es requerida.")
            .max(150, "El campo no debe exceder 150 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        cargo: Yup.string().when("trabaja_actualmente", {
          is: "1",
          then: Yup.string()
            .required("El cargo es requerido.")
            .max(150, "El campo no debe exceder 150 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        empresa: Yup.string().when("trabaja_actualmente", {
          is: "1",
          then: Yup.string()
            .required("La empresa es requerida.")
            .max(150, "El campo no debe exceder 150 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        horas_semanales: Yup.number().when("trabaja_actualmente", {
          is: "1",
          then: Yup.number("El dato ingresado debe ser númerico.")
            .typeError("Por favor, ingrese sólo números.")
            .positive("Por favor, ingrese números mayores a 0")
            .required("Las horas semanales son requeridas."),
          otherwise: Yup.number().nullable(),
        }),
        relacion_carrera: Yup.string().when("trabaja_actualmente", {
          is: "1",
          then: Yup.string()
            .required("La relación con la carrera es obligatoria.")
            .max(50, "El campo no debe exceder 50 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
      }),
      situacion_madre: Yup.object().shape({
        vive: Yup.string()
          .oneOf(["1", "0"], "Debe seleccionar un estado.")
          .required("Por favor, seleccione un estado."),
        ocupacion_madre: Yup.string().when("vive", {
          is: 1,
          then: Yup.string()
            .required("La ocupacion es requerida.")
            .max(150, "El campo no debe exceder 150 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        nivel_estudio_id_madre: Yup.string().when("vive", {
          is: 1,
          then: Yup.lazy((value) =>
            value === undefined || value === null || value === ""
              ? Yup.string()
                  .nullable()
                  .required("El nivel de estudio es obligatorio.")
              : Yup.string()
                  .oneOf(
                    formCombos?.combos?.niveles_estudio?.map(
                      (nivel) => `${nivel.id}`
                    ) || []
                  )
                  .required("El Nivel de estudio es obligatorio.")
          ),
          otherwise: Yup.string().nullable(),
        }),
      }),
      situacion_padre: Yup.object().shape({
        vive: Yup.string()
          .oneOf(["1", "0"], "Debe seleccionar un estado.")
          .required("Por favor, seleccione un estado."),
        ocupacion_padre: Yup.string().when("vive", {
          is: 1,
          then: Yup.string()
            .required("La ocupacion es requerida")
            .max(150, "El campo no debe exceder 150 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        nivel_estudio_id_padre: Yup.string().when("vive", {
          is: 1,
          then: Yup.lazy((value) =>
            value === undefined || value === null || value === ""
              ? Yup.string()
                  .nullable()
                  .required("El nivel de estudio es obligatorio.")
              : Yup.string()
                  .oneOf(
                    formCombos?.combos?.niveles_estudio?.map(
                      (nivel) => `${nivel.id}`
                    ) || []
                  )
                  .required("El nivel de estudio es obligatorio")
          ),
          otherwise: Yup.string().nullable(),
        }),
      }),
      situacion_conyuge: Yup.object().shape({
        concubinato_matrimonio: Yup.string()
          .oneOf(["1", "0"], "Debe seleccionar un estado.")
          .required("Por favor, seleccione un estado."),
        ocupacion_concubinato: Yup.string().when("vive", {
          is: 1,
          then: Yup.string()
            .required("La ocupacion es requerida.")
            .max(150, "El campo no debe exceder 150 caracteres."),
          otherwise: Yup.string().nullable(),
        }),
        nivel_estudio_id_concubinato: Yup.string().when(
          "concubinato_matrimonio",
          {
            is: 1,
            then: Yup.lazy((value) =>
              value === undefined || value === null || value === ""
                ? Yup.string()
                    .nullable()
                    .required("El nivel de estudio es obligatorio.")
                : Yup.string()
                    .oneOf(
                      formCombos?.combos?.niveles_estudio?.map(
                        (nivel) => `${nivel.id}`
                      ) || []
                    )
                    .required("El nivel de estudio es obligatorio.")
            ),
            otherwise: Yup.string().nullable(),
          }
        ),
      }),
    })
  );
};

export const situacionLaboralSchema = () => {
  return Yup.object().shape({
    trabaja_actualmente: Yup.string()
      .oneOf(["1", "0"], "Debe seleccionar un estado laboral.")
      .required("Debe seleccionar al menos un estado laboral."),
    ocupacion: Yup.string().when("trabaja_actualmente", {
      is: 1,
      then: Yup.string()
        .required("La ocupación es requerida.")
        .max(150, "El campo no debe exceder 150 caracteres."),
      otherwise: Yup.string().nullable(),
    }),
    cargo: Yup.string().when("trabaja_actualmente", {
      is: 1,
      then: Yup.string()
        .required("El cargo es requerido.")
        .max(150, "El campo no debe exceder 150 caracteres."),
      otherwise: Yup.string().nullable(),
    }),
    empresa: Yup.string().when("trabaja_actualmente", {
      is: 1,
      then: Yup.string()
        .required("La empresa es requerida.")
        .max(150, "El campo no debe exceder 150 caracteres."),
      otherwise: Yup.string().nullable(),
    }),
    horas_semanales: Yup.string().when("trabaja_actualmente", {
      is: 1,
      then: Yup.number("El dato ingresado debe ser númerico.")
        .positive("Por favor, ingrese un dato válido.")
        .required("Las horas semanales son requeridas."),
      otherwise: Yup.string().nullable(),
    }),
    relacion_carrera: Yup.string().when("trabaja_actualmente", {
      is: 1,
      then: Yup.string()
        .required("Las horas semanales son requeridas.")
        .max(50, "El campo no debe exceder 50 caracteres."),
      otherwise: Yup.string().nullable(),
    }),
  });
};

export const checkEstudianteSchema = (formCombos, bloodTypes, documentType) => {
  return Yup.object().shape({
    persona: Yup.object().shape({
      nombre: Yup.string()
        .required("El nombre es obligatorio")
        .max(255, "El campo no debe exceder 255 caracteres."),
      apellido: Yup.string()
        .required("El apellido es obligatorio")
        .max(255, "El campo no debe exceder 255 caracteres."),
      numero_documento: Yup.number("Por favor, ingrese sólo números.")
        .required("El número de documento es obligatorio.")
        .test("is-eight-digits", "El número debe tener 8 dígitos", (value) => {
          if (!value) return false;
          return value.toString().length === 8;
        }),
      cuit: Yup.number("Debe ingresar sólo números.")
        .required("El cuit es obligatorio")
        .test("is-eight-digits", "El número debe tener 11 dígitos", (value) => {
          if (!value) return false;
          return value.toString().length === 11;
        }),
      hijos: Yup.number("Debe ingresar sólo números.")
        .min(0, "Por favor, ingrese un dato válido")
        .integer()
        .required("La cantidad de hijos es obligatoria."),
      tipo_sangre: Yup.string()
        .oneOf(bloodTypes.map((item) => `${item.id}`))
        .required("Por favor, Elija un tipo de Sangre."),
      tipo_documento: Yup.string()
        .oneOf(documentType.map((item) => `${item.id}`))
        .required("Por favor, Elija un tipo de documento")
        .required("Por favor, elija una opción."),
      estado_civil: Yup.string()
        .required("El estado civil es obligatorio.")
        .max(15, "El campo no debe exceder 15 caracteres."),
      telefono: Yup.number("Debe ingresar sólo números.")
        .positive("Por favor, ingrese un dato valido.")
        .integer("Por favor, Ingrese un dato valido.")
        .nullable(),
      celular: Yup.number("Debe ingresar sólo números.")
        .required("El celular es obligatorio.")
        .positive("Por favor, ingrese un dato valido.")
        .integer("Por favor, Ingrese un dato valido.")
        .test("is-eight-digits", "El número debe tener 10 dígitos", (value) => {
          if (!value) return false;
          return value.toString().length === 10;
        }),
      direccion: Yup.string()
        .required("La direccion es obligatoria.")
        .max(255, "El campo no debe exceder 255 caracteres."),
      pais_id: Yup.lazy((value) =>
        value === undefined || value === null || value === ""
          ? Yup.string()
              .nullable()
              .required("El género es obligatorio.")
          : Yup.string()
              .oneOf(
                formCombos?.combos?.paises?.map((genero) => `${genero.id}`) ||
                  []
              )
              .required("El género es obligatorio.")
      ),
      nacionalidad_id: Yup.lazy((value) =>
        value === undefined || value === null || value === ""
          ? Yup.string()
              .nullable()
              .required("El género es obligatorio.")
          : Yup.string()
              .oneOf(
                formCombos?.combos?.nacionalidades?.map(
                  (genero) => `${genero.id}`
                ) || []
              )
              .required("El género es obligatorio.")
      ),
      genero_id: Yup.lazy((value) =>
        value === undefined || value === null || value === ""
          ? Yup.string()
              .nullable()
              .required("El género es obligatorio.")
          : Yup.string()
              .oneOf(
                formCombos?.combos?.generos?.map((genero) => `${genero.id}`) ||
                  []
              )
              .required("El género es obligatorio.")
      ),
      fecha_nacimiento: Yup.date("Por favor, ingrese una fecha.").required(
        "La fecha de nacimiento es obligatoria."
      ),
      lugar_nacimiento: Yup.string("Por favor, ingrese una fecha.").required(
        "El lugar de nacimiento es obligatorio"
      ),
      provincia: Yup.string()
        .required("La provincia es obligatoria.")
        .max(100, "El campo no debe exceder 100 caracteres."),
      localidad: Yup.string()
        .required("La localidad es obligatoria.")
        .max(100, "El campo no debe exceder 100 caracteres."),
      piso: Yup.number("Debe ingresar sólo números")
        .positive("Por favor, ingrese un dato valido.")
        .integer("Por favor, ingrese un dato valido.")
        .nullable(),
      departamento: Yup.string()
        .max(5, "El campo no debe exceder 5 caracteres.")
        .nullable(),
      email: Yup.string()
        .email("Por favor, Ingrese un dato válido")
        .required("El Email es requerido"),
    }),
  });
};
