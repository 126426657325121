export const generateInitialValues = (email, fromInstance) => {
  return {
    persona: {
      nombre: "",
      apellido: "",
      tipo_documento: "",
      numero_documento: "",
      cuit: "",
      fecha_nacimiento: "",
      lugar_nacimiento: "",
      pais_id: "",
      nacionalidad_id: "",
      genero_id: "",
      tipo_sangre: "",
      estado_civil: "",
      hijos: "",
      direccion: "",
      piso: "",
      departamento: "",
      provincia: "",
      localidad: "",
      telefono: "",
      celular: "",
      email: fromInstance ? "" : email,
    },
    estudio_secundario: {
      escuela_id: "",
      pais_id: "",
      provincia: "",
      localidad: "",
      colegio: "",
      direccion_colegio: "",
      tipo_colegio: "",
      orientacion: "",
      anio_graduacion: "",
      anios_cursados: "",
    },
    discapacidades: [],
    lenguajes: [],
    otros_estudios: [],
    conocimientos: [],
    situacion_laboral: {
      trabaja_actualmente: "",
      ocupacion: "",
      cargo: "",
      empresa: "",
      horas_semanales: "",
      relacion_carrera: "",
    },
    situacion_madre: {
      vive: "",
      nivel_estudio_id_madre: "",
      ocupacion_madre: "",
    },
    situacion_padre: {
      vive: "",
      nivel_estudio_id_padre: "",
      ocupacion_padre: "",
    },
    situacion_conyuge: {
      concubinato_matrimonio: "",
      nivel_estudio_id_concubinato: "",
      ocupacion_concubinato: "",
    },
  };
};

export const generateInitValsFromAusandNotesModules = (instance_id) => {
  return {
    instancia_id: instance_id,
    materia_id: "",
    fecha_clase: "",
    fecha_examen: "",
    inscripto_id: "",
    ausentes: [],
  };
};

export const generateAsistenciasInitVals = (instancia_id) => {
  return {
    instancia_id: instancia_id,
    materia_id: "",
    fecha_clase: "",
    ausentes: [],
  };
};

export const generateNotasInitVals = () => {
  return {
    inscripto_id: "",
    materia_id: "",
    fecha_examen: "",
    nota: "",
  };
};

export const editPersona = (persona) => {
  return {
    id: persona.id,
    nombre: persona.nombre,
    apellido: persona.apellido,
    tipo_documento: persona.tipo_documento,
    numero_documento: persona.numero_documento,
    cuit: persona.cuit,
    fecha_nacimiento: persona.fecha_nacimiento,
    lugar_nacimiento: persona.lugar_nacimiento,
    pais_id: persona.pais_id,
    nacionalidad_id: persona.nacionalidad_id,
    genero_id: persona.genero_id,
    tipo_sangre: persona.tipo_sangre,
    estado_civil: persona.estado_civil,
    hijos: persona.hijos,
    direccion: persona.direccion,
    piso: persona.piso ? persona.piso : "",
    departamento: persona.departamento ? persona.departamento : "",
    provincia: persona.provincia,
    localidad: persona.localidad,
    telefono: persona.telefono,
    celular: persona.celular,
    email: persona.email,
  };
};

export const editTrabajo = (trabajo, persona) => {
  return {
    id: trabajo?.id,
    persona_id: persona.id,
    trabaja_actualmente: trabajo?.trabaja_actualmente,
    ocupacion: trabajo?.ocupacion ? trabajo.ocupacion : "",
    cargo: trabajo?.cargo ? trabajo.cargo : "",
    empresa: trabajo?.empresa ? trabajo.empresa : "",
    horas_semanales: trabajo?.horas_semanales ? trabajo.horas_semanales : "",
    relacion_carrera: trabajo?.relacion_carrera ? trabajo.relacion_carrera : "",
  };
};

export const editDetailsFromProfile = (persona) => {
  return {
    persona: {
      nombre: persona.nombre,
      apellido: persona.apellido,
      tipo_documento: persona.tipo_documento,
      numero_documento: persona.numero_documento,
      cuit: persona.cuit,
      fecha_nacimiento: persona.fecha_nacimiento,
      lugar_nacimiento: persona.lugar_nacimiento,
      pais_id: persona.pais_id,
      nacionalidad_id: persona.nacionalidad_id,
      genero_id: persona.genero_id,
      tipo_sangre: persona.tipo_sangre,
      estado_civil: persona.estado_civil,
      hijos: persona.hijos,
      direccion: persona.direccion,
      piso: persona.piso ? persona.piso : "",
      departamento: persona.departamento ? persona.departamento : "",
      provincia: persona.provincia,
      localidad: persona.localidad,
      telefono: persona.telefono ? persona.telefono : "",
      celular: persona.celular,
      email: persona.email,
    },
    estado: {
      estado_id: "",
    },
  };
};
