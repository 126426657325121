export const CarrerasExample = [
  { id: 1, carrera: "Ingrenierías y LOI" },
  { id: 2, carrera: "TSP" },
  { id: 3, carrera: "TSA" },
  { id: 4, carrera: "Curso de Alemán" },
  { id: 5, carrera: "Curso de refrigeración" },
];

export const CarrerasNewArr = (id) => {
  return [
    {
      id: id,
      carrera: "Ingenieria Civil",
    },
    {
      id: id,
      carrera: "Ingeniería en Energía Eléctrica",
    },
    {
      id: id,
      carrera: "Industria en Automotriz",
    },
    {
      id: id,
      carrera: "Ingeniería Mécanica",
    },
    {
      id: id,
      carrera: "Licenciatura en Organizacion Industrial",
    },
  ];
};

export const CarrerasChoosingFilters = {
  ingenierias: [
    {
      id: "1",
      carrera: "Ingenieria Civil",
    },
    {
      id: "2",
      carrera: "Ingeniería en Energía Eléctrica",
    },
    {
      id: "3",
      carrera: "Ingeniería en Energía Eléctrica",
    },
    {
      id: "4",
      carrera: "Industria en Automotriz",
    },
    {
      id: "5",
      carrera: "Ingeniería Mécanica",
    },
    {
      id: "6",
      carrera: "Licenciatura en Organizacion Industrial",
    },
  ],
  tecnicaturas: [
    {
      id: "7",
      carrera: "Tecnicatura Universitaria en Administración",
    },
    {
      id: "8",
      carrera:
        "Tecnicatura Universitaria en Gestión de la Industria Automotríz",
    },
    {
      id: "9",
      carrera: "Tecnicatura Universitaria en Moldes, Matrices y Dispositivos",
    },
    {
      id: "10",
      carrera:
        "Tecnicatura Universitaria en Programación/Sistemas Informáticos",
    },
  ],
  licenciaturas: [
    {
      id: "11",
      carrera: "Licenciatura en Administración",
    },
    {
      id: "12",
      carrera: "Licenciatura en Higiene y Seguridad en el Trabajo",
    },
    {
      id: "13",
      carrera: "Licenciatura en Logística",
    },
    {
      id: "14",
      carrera: "Licenciatura en Producción de Bioimágenes",
    },
    {
      id: "15",
      carrera: "Licenciatura en Tecnología Educativa",
    },
    {
      id: "16",
      carrera: "Licenciatura en Enseñanza de la Matemática",
    },
  ],
};
