import React from "react";
import ModalMotivoRechazo from "./ModalMotivoRechazo";
import { cambiarEstado } from "../../pages/Usuarios/_redux/UsuariosCrud";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../modules/Auth/_redux/authRedux";

export default function MisCursos() {
  const [showModal, setShowModal] = React.useState(false);
  const [carreraDesc, setCarreraDesc] = React.useState("");
  const [motivoRechazo, setMotivoRechazo] = React.useState("");
  const [errormsg, setErrormsg] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { cursos } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handlingShowMotivoRechazo = (carrera, motivo) => {
    setShowModal(true);
    setCarreraDesc(carrera);
    setMotivoRechazo(motivo);
  };

  const handlingChangeStatus = async (id) => {
    setErrormsg("");
    setLoading(true);
    cambiarEstado(id, { estado: 1, obs: "" })
      .then(() => {
        const curso = cursos.find((item) => item.id === id);
        const updCurso = {
          ...curso,
          estado_id: 1,
          observacion: "",
        };
        dispatch(actions.updateCursos(updCurso));
        setLoading(false);
      })
      .catch(() => {
        setErrormsg("Ha ocurrido un error. Intentelo de nuevo o más tarde.");
        setLoading(false);
      });
  };

  const closeModal = () => {
    setShowModal(false);
    setCarreraDesc("");
    setMotivoRechazo("");
  };
  return (
    <>
      <div className="container-fluid">
        <h5>Mis Incripciones</h5>
        {
          errormsg && (
            <div className="mb-5 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {errormsg}
                </div>
              </div>
          )
        }
        {cursos?.length > 0 ? (
          <>
            <div className="row">
              {cursos?.map((item, key) => (
                <React.Fragment key={key}>
                  <div className="col-xl-2 col-md-3 col-sm-6 col-12 otros-estudios__container my-5 mx-5">
                    <div className="otros-estudios-container__header">
                      <p>{item.carrera}</p>
                    </div>
                    <p className="subparagraph">
                      Estado Inscripcion:{" "}
                      {item?.estado_id &&
                        (() => {
                          switch (item.estado_id) {
                            case 1:
                              return (
                                <span className="label label-lg label-light-warning label-inline">
                                  Pendiente
                                </span>
                              );
                            case 2:
                              return (
                                <span className="label label-lg label-light-success label-inline">
                                  Aprobado
                                </span>
                              );
                            case 3:
                              return (
                                <span className="label label-lg label-light-danger label-inline">
                                  Rechazado
                                </span>
                              );
                            default:
                              return null; // Manejar un caso por defecto si es necesario
                          }
                        })()}
                    </p>
                    {item?.estado_id === 3 && (
                      <>
                        <button
                          onClick={() =>
                            handlingShowMotivoRechazo(
                              item.carrera,
                              item.observacion
                            )
                          }
                          className="btn btn-success py-2 px-4"
                          type="button"
                        >
                          Ver motivo de rechazo
                        </button>
                        <button
                          onClick={() =>
                            handlingChangeStatus(item.id)
                          }
                          className="btn btn-warning py-3 px-5 mt-3"
                          type="button"
                          disabled={loading}
                        >
                          {!loading ? (
                            <>
                            Ya corregí mi documentación.
                              </>
                            )
                            :
                            (
                            <>
                              <div className="d-flex align-items-center p-2 text-center">
                                <div className="spinner spinner-primary mr-10"></div>
                              </div>
                            </>
                            )
                        }
                        </button>
                      </>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </>
        ) : (
          <>
            <h5>No te has inscrito a ninguna carrera/curso aún!</h5>
          </>
        )}
      </div>
      <ModalMotivoRechazo
        show={showModal}
        carrera={carreraDesc}
        motivoRechazo={motivoRechazo}
        close={closeModal}
      ></ModalMotivoRechazo>
    </>
  );
}
