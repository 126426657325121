export const SET_INSTANCES = "SET_INSTANCES";
export const UPDATE_INSTANCE = "UPDATE_INSTANCE";
export const ADD_INSTANCE = "ADD_INSTANCE";
export const REMOVE_INTANCE = "REMOVE_INTANCE";
export const CARREER_COMBOS = "CARREER_COMBOS";

export function setInstances(instances) {
  return {
    type: SET_INSTANCES,
    payload: instances,
  };
}

export function setCombos(combos) {
  return {
    type: CARREER_COMBOS,
    payload: combos,
  };
}

export const updateInstance = (instance) => {
  return {
    type: "UPDATE_INSTANCE",
    payload: instance,
  };
};

export const addInstance = (instance) => {
  return {
    type: "ADD_INSTANCE",
    payload: instance,
  };
};

export const removeInstance = (instance) => {
  return {
    type: "REMOVE_INTANCE",
    payload: instance,
  };
};

const initStates = {
  instances: [],
  combos: [],
};

export function instancesReducer(state = initStates, action) {
  switch (action.type) {
    case SET_INSTANCES:
      return {
        ...state,
        instances: action.payload,
      };

    case UPDATE_INSTANCE:
      return {
        ...state,
        instances: state.instances.map((int) =>
          int.id === action.payload.id ? action.payload : int
        ),
      };
    case ADD_INSTANCE:
      return {
        ...state,
        instances: [...state.instances, action.payload],
      };
    case REMOVE_INTANCE:
      return {
        ...state,
        instances: state.instances.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case CARREER_COMBOS:
      return {
        ...state,
        combos: action.payload,
      };
    default:
      return state;
  }
}
