import { useFormik } from "formik";
import React from "react";
import { editPersona } from "../registerwizard/schemas";
import { updateInformation } from "../../pages/Usuarios/_redux/UsuariosCrud";

export default function MiInformacion({ persona }) {
  const [editPermission, setEditPermission] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const initialValues = editPersona(persona);

  const form = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, { setStatus, setErrors }) => {
      setLoading(true);
      try {
        const { data } = await updateInformation(values);
        if (data) {
          setLoading(false);
          setStatus({
            success: true,
            message: "Información actualizada con exito",
          });
          setEditPermission(true);
        }
      } catch (error) {
        setErrors({
          error: true,
          message: "Ha ocurrido un error al actualizar la información",
        });
      }
    },
    onChange: (e) => {
      const { name, value } = e.target;
      form.setFieldValue(name, value);
    },
  });

  const handlingStatusEdit = (e) => {
    setEditPermission(!editPermission);
    e.preventDefault();
    form.resetForm();
    window.scrollTo(0, 0);
  };

  return (
    <div className="container-fluid">
      {form.status && (
        <div
          className={`border-10 alert alert-custom  ${
            form.status.success
              ? "alert-light-success"
              : form.status.error
              ? "alert-light-danger"
              : ""
          } alert-dismissible`}
        >
          <div className="alert-text font-weight-bold">
            {form.status.message}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 col-sm-12 col-12">
          <form onSubmit={form.handleSubmit}>
            {/* <h5>Datos Personales</h5>
            <div className="row">
              <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Nombre
                  </label>
                  <input
                    name="nombre"
                    id="nombre"
                    disabled={editPermission}
                    value={persona.nombre}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Apellido
                  </label>
                  <input
                    name="apellido"
                    id="apellido"
                    disabled={editPermission}
                    value={persona.apellido}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Tipo de Documento
                  </label>
                  <input
                    name="tipo_documento"
                    id="tipo_documento"
                    disabled={editPermission}
                    value={persona.tipo_documento}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Documento
                  </label>
                  <input
                    value={persona.numero_documento}
                    name="numero_documento"
                    id="numero_documento"
                    disabled={editPermission}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Cuit
                  </label>
                  <input
                    name="cuit"
                    id="cuit"
                    disabled={editPermission}
                    value={persona.cuit}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Genero
                  </label>
                  <input
                    name="genero_id"
                    id="genero_id"
                    disabled={editPermission}
                    value={persona.genero_id}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Tipo de Sangre
                  </label>
                  <input
                    name="tipo_sangre"
                    id="tipo_sangre"
                    disabled={editPermission}
                    value={persona.tipo_sangre}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Estado Civil
                  </label>
                  <input
                    name="estado_civil"
                    id="estado_civil"
                    disabled={editPermission}
                    value={persona.estado_civil}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Hijos
                  </label>
                  <input
                    name="hijos"
                    id="hijos"
                    disabled={editPermission}
                    value={persona.hijos}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <h5>Datos de Nacimiento</h5>
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Fecha de nacimiento
                  </label>
                  <input
                    name="fecha_nacimiento"
                    id="fecha_nacimiento"
                    disabled={editPermission}
                    value={persona.fecha_nacimiento}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Lugar de nacimiento
                  </label>
                  <input
                    name="lugar_nacimiento"
                    id="lugar_nacimiento"
                    disabled={editPermission}
                    value={persona.lugar_nacimiento}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Pais
                  </label>
                  <input
                    name="pais_id"
                    id="pais_id"
                    disabled={editPermission}
                    value={persona.pais}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Nacionalidad
                  </label>
                  <input
                    name="nacionalidad_id"
                    id="nacionalidad_id"
                    value={persona.nacionalidad_id}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    disabled={editPermission}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <h5>Datos de Residencia</h5>
            <div className="row">
              <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Direccion
                  </label>
                  <input
                    name="direccion"
                    id="direccion"
                    disabled={editPermission}
                    value={persona.direccion}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Piso
                  </label>
                  <input
                    name="piso"
                    id="piso"
                    disabled={editPermission}
                    value={persona.piso}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Departamento
                  </label>
                  <input
                    name="departamento"
                    id="departamento"
                    disabled={editPermission}
                    value={persona.departamento}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label" htmlFor="">
                    Provincia
                  </label>
                  <input
                    name="provincia"
                    id="provincia"
                    disabled={editPermission}
                    value={persona.provincia}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label" htmlFor="">
                    Localidad
                  </label>
                  <input
                    name="localidad"
                    id="localidad"
                    disabled={editPermission}
                    value={persona.localidad}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
            </div> */}
            <h5>Datos de Contacto</h5>
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label" htmlFor="">
                    Telefono
                  </label>
                  <input
                    name="telefono"
                    id="telefono"
                    disabled={editPermission}
                    defaultValue={persona.telefono}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label" htmlFor="">
                    Celular
                  </label>
                  <input
                    name="celular"
                    id="celular"
                    disabled={editPermission}
                    defaultValue={persona.celular}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label" htmlFor="">
                    Correo Electronico
                  </label>
                  <input
                    name="email"
                    id="email"
                    disabled={editPermission}
                    defaultValue={persona.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
            </div>
            {!editPermission ? (
              <>
                <button
                  type="button"
                  onClick={handlingStatusEdit}
                  className="btn btn-secondary py-2 px-4 mr-5"
                  disabled={loading}
                >
                  Cancelar
                </button>
                <button
                  className="btn btn-primary py-2 px-4"
                  disabled={loading}
                  type="submit"
                >
                  Confirmar
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </>
            ) : (
              <button
                className="btn btn-success py-2 px-4"
                type="button"
                onClick={handlingStatusEdit}
              >
                Editar Informacion
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
