import React, { useState } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";

export default function TablaUsuarios({
  data,
  className,
  chooseInstance,
  combos,
  changeModalState,
}) {
  const ipp = 5;
  const [currPage, setCurrPage] = useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");

  const startIdx = (currPage - 1) * ipp;
  const endIdx = startIdx + ipp;

  const nextPage = () => {
    setCurrPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrPage((ppage) => ppage - 1);
  };

  
  const nextTenPage = () => {
    setCurrPage((prevPage) => prevPage + 9);
  };

  const prevTenPage = () => {
    setCurrPage((ppage) => ppage - 9);
  };

  const goto = (pnum) => {
    setCurrPage(pnum);
  };

  const filteredItems = data?.filter((item) =>
    item.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currItems =
    filteredItems?.length > 0
      ? filteredItems?.slice(startIdx ?? 0, endIdx ?? 0)
      : [];

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <button
            className="btn btn-primary font-weight-bolder font-size-sm"
            onClick={() => changeModalState(true)}
          >
            Crear nuevo usuario
          </button>
        </h3>
        <div className="card-toolbar">
          <label className="form-lable text-muted" htmlFor="">
            Buscar por email
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Ej: Descripcion x"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
      <div className="row">
          <div className="col-xl-12 col-md-12 col-xs-12 col-sm-12">
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">
                <th style={{ minWidth: "150px" }}>Nombre</th>
                <th style={{ minWidth: "200px" }}>Email</th>
                <th style={{ minWidth: "100px" }}>Rol</th>
                <th style={{ minWidth: "150px" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {currItems.length > 0 ? (
                currItems?.map((item, index) => (
                  <tr key={index}>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {item?.name}
                      </a>
                    </td>
                    <td className="pl-0">
                      <a
                        href="#"
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {item?.email}
                      </a>
                    </td>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        -
                      </span>
                    </td>
                    <td className="pl-0">
                      <button
                        className="btn btn-icon btn-light btn-sm mx-3"
                        onClick={() => changeModalState(true, item)}
                        title="Editar"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                            title="Editar"
                          ></SVG>
                        </span>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <td colSpan={4}>
                    <p>No hay usuarios creados.</p>

                  </td>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="mb-5">
          <div>
            <button
              className="btn btn-light btn-sm"
              disabled={currPage === 1}
              onClick={prevPage}
            >
              Anterior
            </button>
            <button
              className="btn btn-light btn-sm"
              disabled={endIdx >= data.length}
              onClick={prevTenPage}
            >
              {
                '<<'
              }
            </button>
            {data.length > ipp && (
               <>
               {[...Array(Math.ceil(data.length / ipp)).keys()].map((item, idx) => {
                 const startPage = Math.max(1, currPage - 4);
                 const endPage = Math.min(
                   Math.ceil(data.length / ipp),
                   startPage + 9
                 );
                 if (item + 1 >= startPage && item + 1 <= endPage) {
                   return (
                     <button
                       key={idx}
                       className={`btn btn-light btn-sm mx-1 ${
                         currPage === item + 1 ? "active" : ""
                       }`}
                       onClick={() => goto(item + 1)}
                     >
                       {item + 1}
                     </button>
                   );
                 } else {
                   return null;
                 }
               })}
             </>
            )}
            <button
              className="btn btn-light btn-sm"
              disabled={endIdx >= data.length}
              onClick={nextTenPage}
            >
              {
                '>>'
              }
            </button>
            <button
              className="btn btn-light btn-sm"
              disabled={endIdx >= data.length}
              onClick={nextPage}
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
