import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
export default function SituacionLaboral({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  form,
}) {
  const mostrarInformacionTrabajo =
    values.situacion_laboral.trabaja_actualmente === "1";

  const limpiarDatos = () => {
    form.setFieldValue("situacion_laboral.trabaja_actualmente", "0");
    form.setFieldValue("situacion_laboral.ocupacion", "");
    form.setFieldValue("situacion_laboral.cargo", "");
    form.setFieldValue("situacion_laboral.empresa", "");
    form.setFieldValue("situacion_laboral.horas_semanales", "0");
    form.setFieldValue("situacion_laboral.relacion_carrera", "");
  };

  return (
    <>
      <div className="drag-drop-modal-box">
        <span className="svg-icon svg-icon-primary svg-icon-lg">
          <span className="svg-icon-primary svg-icon-2x">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/add-user.svg")}
            />
          </span>
        </span>
        <h3>Situación Laboral</h3>
      </div>
      <div className="row mb-5">
        <div className="col-md-12 col-sm-12 col-12 mb-5">
          <label htmlFor="" className="form-label">
            ¿Actualmente trabajás?
          </label>
          <div className="flex-column">
            <div className="flex-row">
              <input
                type="checkbox"
                checked={values.situacion_laboral.trabaja_actualmente === "1"}
                value="1"
                onChange={() =>
                  handleChange("situacion_laboral.trabaja_actualmente")("1")
                }
                className={`form-checkbox ${
                  touched.situacion_laboral?.trabaja_actualmente &&
                  errors.situacion_laboral?.trabaja_actualmente
                    ? "is-invalid"
                    : ""
                }`}
              />{" "}
              <label htmlFor="">Si</label>
            </div>
            <div className="flex-row">
              <input
                type="checkbox"
                checked={values.situacion_laboral.trabaja_actualmente === "0"}
                value="0"
                onChange={limpiarDatos}
                className={`form-checkbox ${
                  touched.situacion_laboral?.trabaja_actualmente &&
                  errors.situacion_laboral?.trabaja_actualmente
                    ? "is-invalid"
                    : ""
                }`}
              />{" "}
              <label htmlFor="">No</label>
            </div>
          </div>
          {touched.situacion_laboral?.trabaja_actualmente &&
          errors.situacion_laboral?.trabaja_actualmente ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {errors.situacion_laboral?.trabaja_actualmente}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {mostrarInformacionTrabajo && (
        <>
          <div className="row transicion">
            <div
              className={`col-md-4 col-sm-12 col-12 mb-5
              `}
            >
              <label htmlFor="" className="form-label">
                Ocupación
              </label>
              <input
                type="text"
                name="situacion_laboral.ocupacion"
                id="situacion_laboral.ocupacion"
                value={values.situacion_laboral.ocupacion}
                onChange={handleChange}
                className={`form-control ${
                  touched.situacion_laboral?.ocupacion &&
                  errors.situacion_laboral?.ocupacion
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.situacion_laboral?.ocupacion &&
              errors.situacion_laboral?.ocupacion ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_laboral?.ocupacion}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-4 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Cargo
              </label>
              <input
                type="text"
                name="situacion_laboral.cargo"
                id="cargo"
                value={values.situacion_laboral.cargo}
                onChange={handleChange}
                className={`form-control ${
                  touched.situacion_laboral?.cargo &&
                  errors.situacion_laboral?.cargo
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.situacion_laboral?.cargo &&
              errors.situacion_laboral?.cargo ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_laboral?.cargo}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-4 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Empresa
              </label>
              <input
                type="text"
                name="situacion_laboral.empresa"
                id="empresa"
                value={values.situacion_laboral.empresa}
                onChange={handleChange}
                className={`form-control ${
                  touched.situacion_laboral?.empresa &&
                  errors.situacion_laboral?.empresa
                    ? "is-invalid"
                    : ""
                }`}
              />
              {touched.situacion_laboral?.empresa &&
              errors.situacion_laboral?.empresa ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_laboral?.empresa}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Horas Semanales
              </label>
              <input
                type="text"
                className={`form-control ${
                  touched.situacion_laboral?.horas_semanales &&
                  errors.situacion_laboral?.horas_semanales
                    ? "is-invalid"
                    : ""
                }`}
                name="situacion_laboral.horas_semanales"
                id="situacion_laboral.horas_semanales"
                value={values.situacion_laboral.horas_semanales}
                onChange={handleChange}
              />
              {touched.situacion_laboral?.horas_semanales &&
              errors.situacion_laboral?.horas_semanales ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_laboral?.horas_semanales}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Relación con la carrera
              </label>
              <select
                name="situacion_laboral.relacion_carrera"
                id="situacion_laboral.relacion_carrera"
                onChange={handleChange}
                className={`form-control ${
                  touched.situacion_laboral?.relacion_carrera &&
                  errors.situacion_laboral?.relacion_carrera
                    ? "is-invalid"
                    : ""
                }`}
              >
                <option value="Ninguna">Ninguna</option>
                <option value="Parcial">Parcial</option>
                <option value="Total">Total</option>
              </select>
              {touched.situacion_laboral?.relacion_carrera &&
              errors.situacion_laboral?.relacion_carrera ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_laboral?.relacion_carrera}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}
