import React from "react";
import { Suspense } from "react";
import RegisterMainW from "./pages/RegisterWizard/RegisterMainW";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";
import CarrerChoosing from "./pages/CarrerChoosing";
import { useSelector } from "react-redux";
import Perfil from "./pages/Usuarios/Perfil";
import { useEffect } from "react";
import { useState } from "react";

export default function SBasePage() {
  const { input_data } = useSelector((state) => state.auth.state_data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Simula una carga de 1 segundo.
  }, []);

  if (loading) {
    return <LayoutSplashScreen />;
  }
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {!input_data ? (
          <Redirect exact from="/" to="/complete-register" />
        ) : (
          <Redirect exact from="/" to="/carreras" />
        )}
        <ContentRoute path="/complete-register" component={RegisterMainW} />

        <ContentRoute path="/carreras" component={CarrerChoosing} />
        <ContentRoute path="/perfil" component={Perfil}></ContentRoute>
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}
