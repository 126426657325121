import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import HelpTooltip from "../../../components/General/HelpTooltip";

const initialValues = {
  name: "",
  email: "",
  password: "",
  c_password: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const his = useHistory();
  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimo 3 caracteres")
      .max(50, "Maximo 50 caracteres")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Por favor, ingrese un formato de mail adecuado.")
      .min(3, "Minimo 3 caracteres")
      .max(50, "Maximo 50 caracteres")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimo 3 caracteres")
      .max(50, "Maximo 50 caracteres")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    c_password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Las contraseñas deben ser iguales"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "Debes aceptar los terminos y condiciones para poder continuar"
    ),
  });

  const returnToLogin = () => {
    setLoading(false);
    setSuccess(false);
    his.push("/login");
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      register(values.email, values.password, values.c_password, values.name)
        .then(({ data: { message } }) => {
          if (message) {
            setSuccess(true);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          disableLoading();
          if (error?.response?.data?.errors?.email) {
            setStatus(error?.response?.data?.errors?.email);
          } else {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          }
        });
    },
  });

  return (
    <>
      {!success ? (
        <>
          {/*HEADER YA TENGO UNA CUENTA */}
          <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
            <span className="font-weight-bold text-dark-50">
              Ya tengo una cuenta
            </span>
            <Link
              to="/auth/login"
              className="font-weight-bold ml-2"
              id="kt_login_signup"
            >
              Iniciar Sesión
            </Link>
          </div>
          {/* FIN HEADER */}

          {/* INICIO CONTENIDO DE PAGINA */}
          <div
            className="login-form login-signin animated animate__animated animate__slideInUp animate__delay-0s"
            style={{ display: "block" }}
          >
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">
                <FormattedMessage id="AUTH.REGISTER.TITLE" />
              </h3>
              <p className="text-muted font-weight-bold">
                Ingresa tus datos para crear tu cuenta
              </p>
            </div>

            <form
              id="kt_login_signin_form"
              className="form fv-plugins-bootstrap fv-plugins-framework"
              onSubmit={formik.handleSubmit}
            >
              {/* begin: Alert */}
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              {/* end: Alert */}

              {/* begin: Fullname */}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Nombre"
                  type="text"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "name"
                  )}`}
                  name="name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </div>
              {/* end: Name */}

              {/* begin: Email */}
              <div className="form-group fv-plugins-icon-container">
                <div className="text-right mb-2">
                  <HelpTooltip text="Este mail lo utilizaremos para enviarte notificaciones en relacion a las inscripciones que realices. Procura que sea una personal para controlarlo"></HelpTooltip>
                </div>
                <input
                  placeholder="Email"
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              {/* end: Email */}

              {/* begin: Username
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="User name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "username"
                )}`}
                name="username"
                {...formik.getFieldProps("username")}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div>
            {/* end: Username */}

              {/* begin: Password */}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Contrase&ntilde;a"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* end: Password */}

              {/* begin: Confirm Password */}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Confirmar contrase&ntilde;a"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "c_password"
                  )}`}
                  name="c_password"
                  {...formik.getFieldProps("c_password")}
                />
                {formik.touched.c_password && formik.errors.c_password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.c_password}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* end: Confirm Password */}

              {/* begin: Terms and Conditions */}
              <div className="form-group">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="acceptTerms"
                    className="m-1"
                    {...formik.getFieldProps("acceptTerms")}
                  />
                  <Link
                    to="/terms"
                    target="_blank"
                    className="mr-1"
                    rel="noopener noreferrer"
                  >
                    Acepto los terminos y condiciones
                  </Link>
                  <span />
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.acceptTerms}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* end: Terms and Conditions */}
              <div className="form-group d-flex flex-wrap flex-center">
                <Link to="/login">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancelar
                  </button>
                </Link>
                <button
                  type="submit"
                  disabled={formik.isSubmitting || !formik.values.acceptTerms}
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <span>Continuar</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="container animated animate__animated animate__slideInUp animate__delay-0s d-flex justify-content-center align-items-center vh-100">
            <div className="text-center">
              <h3 style={{ marginBottom: "25px" }}>
                Hemos registrado tus datos correctamente
              </h3>
              <h5>Te enviaremos un correo para que puedas activar tu cuenta</h5>
              <div className="circle-svg mt-4">
                <span className="svg-icon svg-icon-primary svg-icon-7x">
                  <span className="svg-icon-primary svg-icon-2x">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Like.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="mt-4">
                <button
                  onClick={() => returnToLogin()}
                  className="btn btn-lg btn-primary"
                  style={{ fontSize: "1rem" }}
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
