import React from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function ErrorMessage({ onLoad }) {
  const [show] = useState(onLoad ? true : false);

  const retry = () => {
    window.location.reload();
  };
  return (
    <Modal
      show={show}
      onHide={() => {}}
      animation={false}
      size="lg"
      className={`animate__animated modalBody ${
        show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
      }`}
    >
      {/* <Modal.Header style={{ backgroundColor: "#F64E60" }}>
        <Modal.Title style={{ color: "#fff" }}>
          Ha ocurrido un error
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-md-12 col-12 text-center">
              <div className="circle-svg-danger">
                <span className="svg-icon svg-icon-danger svg-icon-10x">
                  <span className="svg-icon-danger svg-icon-10x">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Error-circle.svg"
                      )}
                    ></SVG>
                  </span>
                </span>
              </div>
              <h5>Ups... ocurrió un error al procesar la solicitud</h5>
              <p>Por favor, inténtelo dentro de unos minutos o más tarde</p>
              <span>Disculpe las molestias</span>
              <div className="">
                <Button
                  className="btn btn-primary mx-4 my-3 mt-3"
                  onClick={retry}
                >
                  Volver a intentar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
