/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { checkIsActive } from "../../../../_helpers";
import HeaderAdminMenu from "./HeaderAdminMenu";
import { shallowEqual, useSelector } from "react-redux";
import HeaderEstudianteMenu from "./HeaderEstudianteMenu";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const { isAdmin } = useSelector(
    ({ auth }) => ({
      isAdmin: auth.scope != null && auth.scope === "admin",
    }),
    shallowEqual
  );

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      {isAdmin ? (
        <HeaderAdminMenu
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
        ></HeaderAdminMenu>
      ) : (
        <HeaderEstudianteMenu
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
        ></HeaderEstudianteMenu>
      )}
      {/*end::Header Nav*/}
    </div>
  );
}
