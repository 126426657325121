import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

export default function VerifyAccount(props) {
  const { token } = useParams();

  React.useEffect(() => {
    if (token === null || token === undefined || token === "") {
      console.log("no tienes token..., aca redirecciona");
      //history.push('/')
    }

    const verifyTokenFetch = async () => {
      console.log("verificando token");
    };

    verifyTokenFetch();
  }, [token]);

  return (
    <>
      {/* HEADER PARA IR A REGISTRARTE 
      <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">
          Validando Cuenta
        </span>
        <Link
          to="/auth/registration"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
        >
          Registrate!
        </Link>
      </div>
       FIN HEADER */}

      <div
        className="login-form login-signin animated animate__animated animate__slideInUp animate__delay-0s"
        id="kt_login_signin_form"
      >
        <section>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.VERIFYACCOUNT.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">Aguarda, por favor...</p>
            <div className="mt-5">
              <CircularProgress
                size="80px"
                color="secondary"
              ></CircularProgress>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
