import { useFormik } from "formik";
import React from "react";
import { editTrabajo, situacionLaboralSchema } from "../registerwizard/schemas";
import { updateJobSituation } from "../../pages/Usuarios/_redux/UsuariosCrud";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../modules/Auth/_redux/authRedux";

export default function MiSituacionLaboral({ persona }) {
  const [editPermission, setEditPermission] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { situacionlaboral } = useSelector((state) => state.auth);
  const initialValues = editTrabajo(situacionlaboral, persona);
  const form = useFormik({
    initialValues: initialValues,
    validationSchema: situacionLaboralSchema,
    onSubmit: async (values, { setStatus, setErrors }) => {
      setLoading(true);
      try {
        await form.validateForm(values);
        const { data } = await updateJobSituation(values, situacionlaboral?.id);
        if (data) {
          setLoading(false);
          setStatus({
            success: true,
            error: false,
            message: "Información actualizada con exito",
          });
          setEditPermission(true);
          dispatch(actions.setSituacionLaboral(values));
        }
      } catch (error) {
        setLoading(false);
        setEditPermission(false);
        setStatus({
          success: false,
          error: true,
          message: "Ha ocurrido un error al actualizar la informacion",
        });
      }
    },
  });

  const mostrarInformacionTrabajo = form.values.trabaja_actualmente === 1;

  const limpiarDatos = () => {
    form.setFieldValue("trabaja_actualmente", 0);
    form.setFieldValue("ocupacion", "");
    form.setFieldValue("cargo", "");
    form.setFieldValue("empresa", "");
    form.setFieldValue("horas_semanales", "");
    form.setFieldValue("relacion_carrera", "");
  };

  const handlingStatusEdit = (e) => {
    setEditPermission(!editPermission);
    e.preventDefault();
    form.resetForm();
    window.scrollTo(0, 0);
  };

  return (
    <div className="container-fluid">
      {form.status && (
        <div
          className={`border-10 alert alert-custom  ${
            form.status.success
              ? "alert-light-success"
              : form.status.error
              ? "alert-light-danger"
              : ""
          } alert-dismissible`}
        >
          <div className="alert-text font-weight-bold">
            {form.status.message}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 col-sm-12 col-12">
          <form onSubmit={form.handleSubmit}>
            <h5>Situacion Laboral</h5>
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="form-group fv-plugins-icon-container">
                  <label className="form-label" htmlFor="">
                    ¿Actualmente trabaja?
                  </label>
                  <div className="flex-column">
                    <div className="flex-row">
                      <input
                        type="checkbox"
                        disabled={editPermission}
                        checked={form.values.trabaja_actualmente === 1}
                        value={1}
                        name="trabaja_actualmente"
                        id="trabaja_actualmente"
                        onChange={() => {
                          form.setFieldValue("trabaja_actualmente", 1);
                        }}
                        className={`form-checkbox ${
                          form.touched.trabaja_actualmente &&
                          form.errors.trabaja_actualmente
                            ? "is-invalid"
                            : ""
                        }`}
                      />{" "}
                      <label htmlFor="">Si</label>
                    </div>
                    <div className="flex-row">
                      <input
                        type="checkbox"
                        disabled={editPermission}
                        checked={form.values.trabaja_actualmente === 0}
                        value={0}
                        onChange={limpiarDatos}
                        className={`form-checkbox ${
                          form.touched.trabaja_actualmente &&
                          form.errors.trabaja_actualmente
                            ? "is-invalid"
                            : ""
                        }`}
                      />{" "}
                      <label htmlFor="">No</label>
                    </div>
                  </div>
                </div>
                {form.touched.carrera_id && form.errors.carrera_id ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {form.errors.carrera_id}
                    </div>
                  </div>
                ) : null}
              </div>
              {mostrarInformacionTrabajo && (
                <>
                  <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label className="form-label" htmlFor="">
                        Ocupación
                      </label>
                      <input
                        name="ocupacion"
                        id="ocupacion"
                        disabled={editPermission}
                        defaultValue={form.values.ocupacion}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        className="form-control"
                        type="text"
                      />
                    </div>
                    {form.touched.ocupacion && form.errors.ocupacion ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {form.errors.ocupacion}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label className="form-label" htmlFor="">
                        Cargo
                      </label>
                      <input
                        name="cargo"
                        id="cargo"
                        disabled={editPermission}
                        defaultValue={form.values.cargo}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        className="form-control"
                        type="text"
                      />
                    </div>
                    {form.touched.cargo && form.errors.cargo ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{form.errors.cargo}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label className="form-label" htmlFor="">
                        Empresa
                      </label>
                      <input
                        name="empresa"
                        id="empresa"
                        disabled={editPermission}
                        defaultValue={form.values.empresa}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        className="form-control"
                        type="text"
                      />
                    </div>
                    {form.touched.empresa && form.errors.empresa ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {form.errors.empresa}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label className="form-label" htmlFor="">
                        Horas Semanales
                      </label>
                      <input
                        name="horas_semanales"
                        id="horas_semanales"
                        disabled={editPermission}
                        defaultValue={form.values.horas_semanales}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        className="form-control"
                        type="text"
                      />
                    </div>
                    {form.touched.horas_semanales &&
                    form.errors.horas_semanales ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {form.errors.horas_semanales}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                    <div className="form-group fv-plugins-icon-container">
                      <label className="form-label" htmlFor="">
                        Relacion Carrera
                      </label>
                      <select
                        name="relacion_carrera"
                        id="relacion_carrera"
                        disabled={editPermission}
                        defaultValue={form.values.relacion_carrera}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        className="form-control"
                        type="text"
                      >
                        <option value="Ninguna">Ninguna</option>
                        <option value="Parcial">Parcial</option>
                        <option value="Total">Total</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!editPermission ? (
              <>
                <button
                  type="button"
                  onClick={handlingStatusEdit}
                  className="btn btn-secondary py-2 px-4 mr-5"
                  disabled={loading}
                >
                  Cancelar
                </button>
                <button
                  className="btn btn-primary py-2 px-4"
                  disabled={loading}
                  type="submit"
                >
                  Confirmar
                  {loading && (
                    <span className="mx-3 spinner spinner-white"></span>
                  )}
                </button>
              </>
            ) : (
              <button
                className="btn btn-success py-2 px-4"
                type="button"
                onClick={handlingStatusEdit}
              >
                Editar Informacion
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
