import React from "react";
import { Image, Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import RegisterMainW from "../../pages/RegisterWizard/RegisterMainW";
import BuscarPorDni from "./BuscarPorDni";

export default function ModalInscriptos({
  show,
  showingInscriptosModal,
  selectedInstance,
  carrera,
  nav,
  combos,
}) {
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleClickCloseModal = () => {
    setSelectedOption(null);
    showingInscriptosModal(false);
  };

  const handleOptionClick = (opt) => {
    setSelectedOption(opt);
  };
  const handleBackClick = () => {
    setSelectedOption(null);
  };

  const renderContent = () => {
    switch (selectedOption) {
      case "new":
        return (
          <RegisterMainW fromInstance={selectedInstance} carrera={carrera} />
        );
      case "exist":
        return (
          <>
            <BuscarPorDni
              combos={combos}
              handleBackClick={handleBackClick}
              selectedInstance={selectedInstance}
            ></BuscarPorDni>
          </>
        );
      case "all":
        nav.push(`/inscripciones/${selectedInstance.id}`);
        break;
      default:
        return (
          <div className="container-fluid">
            <div className="row">
                <>
                  <div className="col-md-6 col-sm-12 col-12 my-5">
                    <div
                      className="option-box"
                      style={{ backgroundColor: "#B4E7D9" }}
                      onClick={() => handleOptionClick("exist")}
                    >
                      <div className="option-box__title">
                        <span style={{ fontSize: "14px" }}>
                          Inscribir Persona Existente
                        </span>
                      </div>
                      <div className="option-box__img">
                        <Image
                          className=""
                          src={toAbsoluteUrl(
                            "/media/svg/avatars/021-boy-8.svg"
                          )}
                        ></Image>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-12 my-5">
                    <div
                      className="option-box"
                      style={{ backgroundColor: "#FFD8B8" }}
                      onClick={() => handleOptionClick("new")}
                    >
                      <div className="option-box__title">
                        <span style={{ fontSize: "14px" }}>
                          Inscribir Persona Nueva
                        </span>
                      </div>
                      <div className="option-box__img">
                        <Image
                          className=""
                          src={toAbsoluteUrl(
                            "/media/svg/avatars/010-girl-4.svg"
                          )}
                        ></Image>
                      </div>
                    </div>
                  </div>
                </>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    

              <div className="col-md-12 col-sm-12 col-12 my-5">
                <div
                  className="option-box"
                  style={{ backgroundColor: "#FFF3B5" }}
                  onClick={() => handleOptionClick("all")}
                >
                  <div className="option-box__title">
                    <span style={{ fontSize: "14px" }}>
                      Ver todos los Inscriptos
                    </span>
                  </div>
                  <div className="option-box__img-two">
                    <Image
                      className=""
                      src={toAbsoluteUrl("/media/svg/avatars/036-girl-19.svg")}
                    ></Image>
                    <Image
                      className=""
                      src={toAbsoluteUrl("/media/svg/avatars/004-boy-1.svg")}
                    ></Image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <Modal
        show={show}
        animation={false}
        size={"lg"}
        className={`animate__animated ${
          show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
        }`}
        onHide={() => {}}
      >
        <Modal.Header className="custom-header" closeButton>
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            <h3 className="custom-title">Inscriptos</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {renderContent()}
            <div className="row justify-content-center">
              <div className="col-sm-3 col-md-3 col-xl-3 col-3 text-center">
                <button
                  className="btn btn-secondary py-4 px-2 mx-auto"
                  onClick={handleClickCloseModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
