import * as Yup from "yup";



/* INITIAL VALUES */ 
export const newUserInitialValues = (item) =>  {
    
    return {
        name: '',
        email: '',
        password: '',
        c_password: '',
        role: '',
        role_description: ''
    }
}


/* VALIDATION SCHEMAS */
export const generateNewUserSchema = () =>{
    const _schema = Yup.object().shape({
        name: Yup.string().required('El campo es obligatorio.')
        .max(255, "El campo no debe exceder 255 caracteres."),
        email: Yup.string()
        .email("Por favor, Ingrese un dato válido")
        .required("El Email es requerido"),
        password: Yup.string().when('password_required', {
            is: true,
            then: Yup.string().required('El campo es obligatorio.').max(255, "El campo no debe exceder 255 caracteres."),
            otherwise: Yup.string(),
        }),
        c_password: Yup.string().when('password_required', {
            is: true,
            then: Yup.string().required('El campo es obligatorio.').max(255, "El campo no debe exceder 255 caracteres."),
            otherwise: Yup.string(),
        })
    });

    return _schema;
} 