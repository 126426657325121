import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import InputMask from "react-input-mask";

export default function DatosPersonales({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  estadoscivil,
  generos,
  documentos,
  tiposangre,
  email,
}) {
  return (
    <>
      <div className="container">
        <div className="drag-drop-modal-box">
          <span className="svg-icon svg-icon-primary svg-icon-lg">
            <span className="svg-icon-primary svg-icon-2x">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/star.svg")} />
            </span>
          </span>
          <h3>Datos Generales</h3>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12 mb-5">
            <div className="flex-columnjustify-content-around">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-12 mb-5">
                  <label className="form-label" htmlFor="">
                    Apellido
                  </label>
                  <input
                    type="text"
                    value={values.persona.apellido}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="apellido"
                    name="persona.apellido"
                    className={`form-control ${
                      touched.persona?.apellido && errors.persona?.apellido
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {touched.persona?.apellido && errors.persona?.apellido ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.persona.apellido}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-12 mb-5">
                  <label className="form-label">Nombre</label>
                  <input
                    type="text"
                    id="nombre"
                    name="persona.nombre"
                    value={values.persona.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched.persona?.nombre && errors.persona?.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {touched.persona?.nombre && errors.persona?.nombre ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.persona.nombre}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12 col-12 mb-5">
                  <label className="form-label" htmlFor="">
                    Estado Civil
                  </label>
                  <select
                    name="persona.estado_civil"
                    id="persona.estado_civil"
                    defaultValue={values.persona.estado_civil}
                    className={`form-control ${
                      touched.persona?.estado_civil &&
                      errors.persona?.estado_civil
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="-1">Seleccione un estado civil...</option>
                    {estadoscivil.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <option value={item.id}>{item.descripcion}</option>
                      </React.Fragment>
                    ))}
                  </select>
                  {touched.persona?.estado_civil &&
                  errors.persona?.estado_civil ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.persona.estado_civil}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-12 mb-5">
                  <label className="form-label" htmlFor="">
                    Hijos
                  </label>
                  <input
                    type="text"
                    id="persona.hijos"
                    name="persona.hijos"
                    value={values.persona.hijos}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      touched.persona?.hijos && errors.persona?.hijos
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {touched.persona?.hijos && errors.persona?.hijos ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {errors.persona.hijos}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-12 mb-5">
            <div className="row flex-column">
              <div className="col-12">
                <label className="form-label" htmlFor="">
                  Tipo de Documento
                </label>
                <select
                  className={`form-control ${
                    touched.persona?.tipo_documento &&
                    errors.persona?.tipo_documento
                      ? "is-invalid"
                      : ""
                  }`}
                  name="persona.tipo_documento"
                  id="persona.tipo_documento"
                  value={values.persona.tipo_documento}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="-1">Seleccione una opción...</option>
                  {documentos.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <option value={item.id}>{item.descripcion}</option>
                    </React.Fragment>
                  ))}
                </select>
                {touched.persona?.tipo_documento &&
                errors.persona?.tipo_documento ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {errors.persona.tipo_documento}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-12 mb-5">
            <label className="form-label" htmlFor="">
              Numero Documento
            </label>
            <input
              type="text"
              name="persona.numero_documento"
              id="persona.numero_documento"
              value={values.persona.numero_documento}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control ${
                touched.persona?.numero_documento &&
                errors.persona?.numero_documento
                  ? "is-invalid"
                  : ""
              }`}
            />
            {touched.persona?.numero_documento &&
            errors.persona?.numero_documento ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {errors.persona.numero_documento}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4 col-sm-12 col-12 mb-5">
            <label className="form-label" htmlFor="">
              CUIT / CUIL
            </label>
            <InputMask
              mask="99-99999999-9"
              maskChar={"_"}
              value={`${values.persona.cuit.substring(0, 2) || "__"}-${values
                .persona.numero_documento ||
                "________"}-${values.persona.cuit.substring(10, 11) || "_"}`}
              onChange={(e) => {
                const cuitValue = e.target.value;
                const cuitWithHyphens = cuitValue.split("-").join("");
                handleChange({
                  target: { name: "persona.cuit", value: cuitWithHyphens },
                });
              }}
              onBlur={handleBlur}
              name="persona.cuit"
              id="persona.cuit"
              className={`form-control ${
                touched.persona?.cuit && errors.persona?.cuit
                  ? "is-invalid"
                  : ""
              }`}
            ></InputMask>
            {touched.persona?.cuit && errors.persona?.cuit ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.persona.cuit}</div>
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-sm-12 col-12 mb-5">
            <div className="row flex-column">
              <div className="col-12">
                <label className="form-label" htmlFor="">
                  Genero
                </label>
                <select
                  className={`form-control ${
                    touched.persona?.genero_id && errors.persona?.genero_id
                      ? "is-invalid"
                      : ""
                  }`}
                  value={`${values.persona.genero_id}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="persona.genero_id"
                  id="persona.genero_id"
                >
                  <option value="-1">Seleccione una opcion...</option>
                  {generos.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <option value={`${item.id}`}>{item.descripcion}</option>
                    </React.Fragment>
                  ))}
                </select>
                {touched.persona?.genero_id && errors.persona?.genero_id ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {errors.persona.genero_id}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-12 mb-5">
            <div className="row flex-column">
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Grupo Sanguineo
                  </label>
                  <select
                    className={`form-control ${
                      touched.persona?.tipo_sangre &&
                      errors.persona?.tipo_sangre
                        ? "is-invalid"
                        : ""
                    }`}
                    value={values.persona.tipo_sangre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="persona.tipo_sangre"
                    id="persona.tipo_sangre"
                  >
                    <option value="-1">Seleccione una opción...</option>
                    {tiposangre.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <option value={item.id}>{item.descripcion}</option>
                      </React.Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {email ? (
            <>
              <div className={"col-md-4 col-sm-12 col-12 mb-5"}>
                <label className="form-label" htmlFor="">
                  Correo Electronico
                </label>
                <input
                  type="email"
                  id="email"
                  value={values.persona.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="persona.email"
                  className={`form-control ${
                    touched.persona?.email && errors.persona?.email
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {touched.persona?.email && errors.email?.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.persona.email}</div>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            ""
          )}
          <div
            className={`${
              email
                ? "col-md-4 col-sm-12 col-12 mb-5"
                : "col-md-6 col-sm-12 col-12 mb-5"
            }`}
          >
            <label className="form-label" htmlFor="">
              Telefono fijo
            </label>
            <input
              type="text"
              id="telefono"
              value={values.persona.telefono}
              onChange={handleChange}
              onBlur={handleBlur}
              name="persona.telefono"
              className={`form-control ${
                touched.persona?.telefono && errors.persona?.telefono
                  ? "is-invalid"
                  : ""
              }`}
            />
            {touched.persona?.telefono && errors.persona?.telefono ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.persona.telefono}</div>
              </div>
            ) : null}
          </div>
          <div
            className={`${
              email
                ? "col-md-4 col-sm-12 col-12 mb-5"
                : "col-md-6 col-sm-12 col-12 mb-5"
            }`}
          >
            <label className="form-label" htmlFor="">
              Celular
            </label>
            <input
              type="text"
              id="celular"
              name="persona.celular"
              value={values.persona.celular}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control ${
                touched.persona?.celular && errors.persona?.celular
                  ? "is-invalid"
                  : ""
              }`}
            />
            {touched.persona?.celular && errors.persona?.celular ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.persona.celular}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
