import axios from "axios";

const INSTANCIAS = `${process.env.REACT_APP_DOMAIN_NAME}/api/instancias/`;
const INSCRIPCIONES = `${process.env.REACT_APP_DOMAIN_NAME}/api/inscriptos/`;
const INSCRIPCIONES_X_INSTANCIA = `${process.env.REACT_APP_DOMAIN_NAME}/api/inscriptos/get_by_instancia/`;
const ASISTENCIAS = `${process.env.REACT_APP_DOMAIN_NAME}/api/asistencias-inscripto/`;
const AUSENTES_X_FECHA = `${process.env.REACT_APP_DOMAIN_NAME}/api/asistencias-inscripto/get-by-fecha/`;
const NOTAS = `${process.env.REACT_APP_DOMAIN_NAME}/api/notas-inscripto/`;
const PERSONA_X_DNI = `${process.env.REACT_APP_DOMAIN_NAME}/api/personas/get_by_dni/`;
const DETALLEPERSONA = `${process.env.REACT_APP_DOMAIN_NAME}/api/personas/`;
const SET_ESTADO = `${process.env.REACT_APP_DOMAIN_NAME}/api/inscriptos/set_estado`;
const FILES = `${process.env.REACT_APP_DOMAIN_NAME}/api/files/get_docs_persona/`;
const DATA_FORM_INSCRIPCIONES = `${process.env.REACT_APP_DOMAIN_NAME}/api/data-form-inscripcion/`;

const USERS = `${process.env.REACT_APP_DOMAIN_NAME}/api/users/`;

// * =========================== *
//INSTANCIAS CRUD
export const getInstances = async () => {
  const res = await axios.get(INSTANCIAS);
  return res.data;
};

export const getCombos = async () => {
  const res = await axios.get(DATA_FORM_INSCRIPCIONES);
  return res.data;
};

export const createInstance = async (data) => {
  return await axios.post(INSTANCIAS, data);
};

export const modifyInstance = async (action, data) => {
  return await axios.put(INSTANCIAS + action.id, data);
};

export const deleteInstance = async (action) => {
  return await axios.delete(INSTANCIAS + action.id);
};

export const getPersonaByDNI = async (dni) => {
  try {
    const res = await axios.get(PERSONA_X_DNI + dni);
    return res.data;
  } catch (error) {
    return [];
  }
};

export const getInstanciaByID = async (id) => {
  return await axios.get(INSTANCIAS + id);
};
//FIN INSTANCIAS CRUD
// * =========================== *

// * =========================== *
// INICIO INSCRIPCIONES CRUD

export const getInscripciones = async () => {
  try {
    const res = await axios.get(INSCRIPCIONES);
    return res.data;
  } catch (error) {
    return [];
  }
};

export const getInscripciones_x_instancia = async (instance_id) => {
  try {
    const res = await axios.get(INSCRIPCIONES_X_INSTANCIA + instance_id);

    return res.data;
  } catch (error) {
    return [];
  }
};

export const getUserDetails = async (persona_id) => {
  try {
    const res = await axios.get(DETALLEPERSONA + persona_id);
    return res.data;
  } catch (error) {}
};

export const getUserAfterFilesUpload = async (persona) => {
  return await axios.get(DETALLEPERSONA + persona);
};

export const setUserStateInscripto = async (id, estado, obs) => {
  return await axios.put(`${SET_ESTADO}/${id}`, {
    estado_id: estado,
    observacion: obs,
  });
};

// FIN INSCRIPCIONES CRUD
// * ========================== *

// * =========================== *
// INICIO ASISTENCIAS CRUD

export const saveAusentes = async (values) => {
  try {
    const res = await axios.post(`${ASISTENCIAS}store/`, values);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const updateAusentes = async (values) => {
  try {
    const res = await axios.put(`${ASISTENCIAS}update/`, values);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getAusentes_by_fecha = async (values) => {
  try {
    const res = await axios.get(AUSENTES_X_FECHA, {
      params: {
        instancia_id: values.instancia_id,
        materia_id: values.materia_id,
        fecha_clase: values.fecha_clase,
      },
    });
    return res.data;
  } catch (error) {
    return null;
  }
};

// FIN ASISTENCIAS CRUD
// * ========================== *

// * ========================== *
// * ====== INICIO NOTAS ====== *
// * ========================== *

export const setNota_Inscripto = async (values, id) => {
  try {
    const res = await axios.post(`${NOTAS}store`, {
      inscripto_id: id,
      materia_id: values.materia_id,
      fecha_examen: values.fecha_examen,
      nota: values.nota,
    });
    return res.data;
  } catch (error) {
    return null;
  }
};

export const updateNota_inscripto = async (id, nota) => {
  try {
    const res = await axios.put(`${NOTAS}update/${id}`, { nota: nota });
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getNotas_byFecha = async (values) => {
  try {
    const res = await axios.get(`${NOTAS}get-by-fecha/`, {
      params: values,
    });
    return res.data;
  } catch (e) {
    return null;
  }
};

// * ========================== *
// * ======== FIN NOTAS ======= *
// * ========================== *

// * ========================== *
// * ======= F I L E S ======== *
// * ========================== *

export const getFilesFromPersona = async (idpersona) => {
  try {
    const res = await axios.get(`${FILES}${idpersona}`);
    return res.data;
  } catch (error) {
    return null;
  }
};

// * ========================== *
// * ======= END FILES ======== *
// * ========================== *

// * =========================== *
// INICIO INSCRIPCIONES CRUD


export const get_all_users = async() => {
  try {
    return await axios.get(`${USERS}`);
  } catch (error) {
    return [];
  }
}
