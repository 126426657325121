import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export default function SuccessMessage({
  cls,
  titulo,
  othertitle,
  opciones,
  optArray,
  moreContent,
}) {
  const handleOptionClick = (func) => {
    if (func && typeof func === "function") {
      func();
    }
  };

  return (
    <div className={cls}>
      <div className="card card-custom animate__animated animate__fadeIn">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 col-sm-12 col-12">
              <h5 style={{ marginBottom: "25px" }}>
                {othertitle ? (
                  <>{titulo}</>
                ) : (
                  <>
                    Se han registrado con exito todos los datos cargados en{" "}
                    {titulo}
                  </>
                )}
              </h5>
              {moreContent && (
                <div className="col-md-12 col-sm-12 col-12">
                  <h5 style={{ marginBottom: "25px" }}>{moreContent}</h5>
                </div>
              )}
              <div className="circle-svg">
                <span className="svg-icon svg-icon-primary svg-icon-7x">
                  <span className="svg-icon-primary svg-icon-2x">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Like.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              {opciones ? (
                <>
                  <div className="row">
                    {optArray?.map((opt, key) => (
                      <React.Fragment key={key}>
                        <div
                          className={`col-md-${
                            optArray?.length > 1 ? "6" : "12"
                          } col-sm-12 col-12 text-center mt-5`}
                        >
                          {opt.func ? (
                            <button
                              className="btn btn-primary"
                              onClick={() => handleOptionClick(opt.func)}
                            >
                              {opt.desc}
                            </button>
                          ) : (
                            <Link to={opt.path}>{opt.desc}</Link>
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </>
              ) : (
                <div className="custom-continue-to-root">
                  <Link to="/">
                    <Button>Continuar</Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
