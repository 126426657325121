import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Select from "react-select-virtualized";

export default function DatosResidencia({
  values,
  errors,
  touched,
  combos,
  handleChange,
  handleBlur,
}) {
  const [filterLocalidades, setFiltrosLocalidades] = React.useState(
    combos?.localidades
  );
  const [cantSelect, setCantSelect] = React.useState(true);

  const handleProvincia = (e) => {
    const val = e.target.idInternal;
    const _filtered = combos?.localidades.filter(
      (item) => parseInt(item.provincia_id) === parseInt(val)
    );
    setFiltrosLocalidades(_filtered);
    setCantSelect(false);
    handleChange(e);
  };

  return (
    <>
      <div className="container">
        <div className="drag-drop-modal-box">
          <span className="svg-icon svg-icon-primary svg-icon-lg">
            <span className="svg-icon-primary svg-icon-2x">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
          </span>
          <h3>Datos de Residencia</h3>
        </div>
        <div className="row mb-5">
          <div className="col-md-4 col-sm-12 col-12 mb-5">
            <label htmlFor="" className="form-label">
              Dirección y Altura
            </label>
            <input
              type="text"
              id="direccion"
              name="persona.direccion"
              value={values.direccion}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control ${
                touched.persona?.direccion && errors.persona?.direccion
                  ? "is-invalid"
                  : ""
              }`}
            />
            {touched.persona?.direccion && errors.persona?.direccion ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.persona.direccion}</div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4 col-sm-12 col-12 mb-5">
            <label htmlFor="" className="form-label">
              Departamento
            </label>
            <input
              type="text"
              id="departamento"
              name="persona.departamento"
              value={values.departamento}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control ${
                touched.persona?.departamento && errors.persona?.departamento
                  ? "is-invalid"
                  : ""
              }`}
            />
            {touched.persona?.departamento && errors.persona?.departamento ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {errors.persona.departamento}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-4 col-sm-12 col-12 mb-5">
            <label htmlFor="" className="form-label">
              Piso
            </label>
            <input
              type="text"
              name="persona.piso"
              id="persona.piso"
              value={values.piso}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-control ${
                touched.persona?.piso && errors.persona?.piso
                  ? "is-invalid"
                  : ""
              }`}
            />
            {touched.persona?.piso && errors.persona?.piso ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.persona.piso}</div>
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-sm-12 col-12 mb-5">
            <label htmlFor="" className="form-label">
              Provincia
            </label>
            <Select
              isSearchable={true}
              name="persona.provincia"
              id="persona.provincia"
              options={combos?.provincias?.map((item) => ({
                value: item.id,
                label: item.descripcion,
              }))}
              onChange={(selectedOption) => {
                const e = {
                  target: {
                    name: "persona.provincia",
                    value: selectedOption ? selectedOption.label : -1,
                    idInternal: selectedOption ? selectedOption.value : -1,
                  },
                };
                handleProvincia(e);
              }}
              defaultValue={combos?.provincias?.find(
                (item) => item.descripcion === values.persona?.provincia
              )}
            />
            {touched.persona?.provincia && errors.persona?.provincia ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.persona.provincia}</div>
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-sm-12 col-12 mb-5">
            <label htmlFor="" className="form-label">
              Localidad
            </label>
            <Select
              isSearchable={true}
              label="Selecciona una provincia..."
              name="persona.localidad"
              id="localidad"
              options={filterLocalidades?.map((item) => ({
                value: item.id,
                label: item.descripcion,
              }))}
              onChange={(selectedOption) => {
                const e = {
                  target: {
                    name: "persona.localidad",
                    value: selectedOption ? selectedOption.label : -1,
                  },
                };
                handleChange(e);
              }}
              defaultValue={filterLocalidades?.find(
                (item) => item.descripcion === values.persona?.localidad
              )}
              isDisabled={cantSelect}
            ></Select>
            {touched.persona?.localidad && errors.persona?.localidad ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.persona.localidad}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
