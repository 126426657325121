import React, { useRef, useState } from "react";
import DatosPersonales from "../../../pages/RegisterWizard/SubWizard/Step1/DatosPersonales";
import DatosNacimiento from "../../../pages/RegisterWizard/SubWizard/Step1/DatosNacimiento";
import DatosResidencia from "../../../pages/RegisterWizard/SubWizard/Step1/DatosResidencia";
import { Button } from "react-bootstrap";
import Discapacidades from "../../../pages/RegisterWizard/SubWizard/Step1/Discapacidades";

export default function RegisterStep1({
  handleNext,
  form,
  combos,
  estadoscivil,
  documentos,
  tiposangre,
  fromInstance,
  hasNext,
  handleChangeDiscapacidades,
  handleAddDiscapacidad,
}) {

  const [discapacidad, setDiscapacidad] = useState(false);
  const [errorDiscapacidad, setErrorDiscapacidad] = useState('');


  const handleNextClick = () => {
    form.validateForm().then((errors) => {
      const personaErrors = Object.keys(errors).filter((field) =>
        field.startsWith("persona")
      );

      if(discapacidad){
        if (form.values.discapacidades.length <= 0) {
          setErrorDiscapacidad('Por favor, complete el numero de cud o añada el tipo correspondiente.')
          return;
        }
        else{
          setErrorDiscapacidad('');
        }
      }

      if (personaErrors.length === 0) {
        handleNext();
      } else {
        form.setTouched({ ...form.touched, ...errors }, true);
      }
    });
  };

  return (
    <>
      <div className="container">
        <DatosPersonales
          generos={combos.combos.generos}
          values={form.values}
          errors={form.errors}
          form={form}
          touched={form.touched}
          handleChange={form.handleChange}
          email={fromInstance}
          handleBlur={form.handleBlur}
          documentos={documentos}
          estadoscivil={estadoscivil}
          tiposangre={tiposangre}
        ></DatosPersonales>
        <Discapacidades
          values={form.values}
          errorDiscapacidad={errorDiscapacidad}
          hasDiscapacidad={discapacidad}
          setHasDiscapacidad={setDiscapacidad}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          discapacidadesCombos={combos.combos.discapacidades}
          handleChangeDiscapacidades={handleChangeDiscapacidades}
          handleAddDiscapacidad={handleAddDiscapacidad}
        ></Discapacidades>
        <DatosNacimiento
          values={form.values}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          nacionalidades={combos.combos.nacionalidades}
          paises={combos.combos.paises}
        ></DatosNacimiento>
        <DatosResidencia
          values={form.values.persona}
          errors={form.errors}
          combos={combos.combos}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
        ></DatosResidencia>
        {!hasNext && (
          <>
            <Button
              xvariant="contained"
              color="primary"
              type="button"
              className="btn btn-primary py-3 px-9 "
              onClick={handleNextClick}
            >
              Siguiente
            </Button>
          </>
        )}
      </div>
    </>
  );
}
