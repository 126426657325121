import { StepIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const uset = makeStyles((theme) => ({
  stepIcon: {
    color: "#025692", // Cambia el color del círculo a #025692
  },
  activeStepIcon: {
    color: "#025692 !important", // Cambia el color del círculo activo a #025692
  },
  completedStepIcon: {
    color: "#025692 !important", // Cambia el color del círculo completado a #025692
  },
  stepText: {
    fill: "#fff !important",
  },
}));

export default function CustomStepIcon(props) {
  const classes = uset();
  const { active, completed } = props;

  return (
    <StepIcon
      {...props}
      classes={{
        root: classes.stepIcon,
        active: classes.activeStepIcon,
        completed: classes.completedStepIcon,
        text: classes.stepText,
      }}
    />
  );
}
