import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Dropzone from "../Dropzone/Dropzone";
import Select from "react-select";
import { CarrerasNewArr } from "../../carreras/exampleCarreras";

export default function RegisterStep4({
  form,
  handleNext,
  set,
  handleBack,
  loading,
  dataForm,
  fromInstance,
}) {
  const HandleFiles = (name, file) => {
    if (file) {
      dataForm.append(name, file, file.inputName);
    }
  };

  return (
    <main>
      <div className="container">
        <div className="row flex-column">
          {fromInstance?.carrera_id === 1 && (
            <>
              <h3>
                Te pedimos que selecciones la carrera a la cual el estudiante
                esta predispuesto a inscribirse
              </h3>
              <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                <Select
                  isSearcheble={true}
                  name="carrera_id"
                  id="carrera_id"
                  options={CarrerasNewArr(fromInstance?.id).map((item) => ({
                    value: item.id,
                    label: item.carrera,
                  }))}
                  onChange={(selectedOption) => {
                    set(selectedOption.label);
                  }}
                  defaultValue={
                    form.values.carrera
                      ? (() => {
                          const selectedOption = CarrerasNewArr(
                            fromInstance?.id
                          ).find((item) => item.id === form.values.carrera);
                          if (selectedOption) {
                            return {
                              value: selectedOption.id,
                              label: selectedOption.carrera,
                            };
                          }
                        })()
                      : null
                  }
                ></Select>
              </div>
            </>
          )}
          {!fromInstance && (
            <>
              <h3>Subir Archivos</h3>
              <p>
                Te pedimos que te tomes 5 minutos para subir la documentacion
                requerida
              </p>
              <div className="col-md-12 col-sm-12 col-12 mb-5">
                <div className="drag-drop-modal-box">
                  <span className="svg-icon svg-icon-primary svg-icon-lg">
                    <span className="svg-icon-primary svg-icon-2x">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                      />
                    </span>
                  </span>
                  <h3>DATOS PERSONALES</h3>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-12 col-12 mb-5">
                    <Dropzone
                      inputName="dni_frente"
                      dataForm={dataForm}
                      HandleFiles={HandleFiles}
                      title={"D.N.I. Frente"}
                    ></Dropzone>
                  </div>
                  <div className="col-md-4 col-sm-12 col-12 mb-5">
                    <Dropzone
                      inputName="dni_reverso"
                      dataForm={dataForm}
                      HandleFiles={HandleFiles}
                      title={"D.N.I. reverso"}
                    ></Dropzone>
                  </div>
                  <div className="col-md-4 col-sm-12 col-12 mb-5">
                    <Dropzone
                      inputName="cuil"
                      HandleFiles={HandleFiles}
                      dataForm={dataForm}
                      title={"C.U.I.L."}
                    ></Dropzone>
                  </div>
                  <div className="col-md-4 col-sm-12 col-12 mb-5">
                    <Dropzone
                      title={"Partida de Nacimiento"}
                      inputName="partida_nacimiento"
                      HandleFiles={HandleFiles}
                      dataForm={dataForm}
                    ></Dropzone>
                  </div>
                  {form.values.discapacidades.length > 0 && (
                    <div className="col-md-4 col-sm-12 col-12 mb-5">
                      <Dropzone
                        inputName="cud"
                        dataForm={dataForm}
                        HandleFiles={HandleFiles}
                        title={"C.U.D."}
                        ks
                      ></Dropzone>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-12 mb-5">
                <div className="drag-drop-modal-box">
                  <span className="svg-icon svg-icon-primary svg-icon-lg">
                    <span className="svg-icon-primary svg-icon-2x">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Files/File-plus.svg"
                        )}
                      />
                    </span>
                  </span>
                  <h3>Educación</h3>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-12 col-12 mb-5">
                    <Dropzone
                      title={"Analítico/Constancia emitida por el establecimiento"}
                      inputName="analitico"
                      dataForm={dataForm}
                      HandleFiles={HandleFiles}
                    ></Dropzone>
                  </div>
                </div>
              </div>
              {form.values?.situacion_laboral?.trabaja_actualmente === "1" && (
                <div className="col-md-12 col-sm-12 col-12 mb-5">
                  <div className="drag-drop-modal-box">
                    <span className="svg-icon svg-icon-primary svg-icon-lg">
                      <span className="svg-icon-primary svg-icon-2x">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Files/File-plus.svg"
                          )}
                        />
                      </span>
                    </span>
                    <h3>Laboral</h3>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-12 col-12 mb-5">
                      <Dropzone
                        title={"Certificado laboral"}
                        inputName="certificado_laboral"
                        dataForm={dataForm}
                        HandleFiles={HandleFiles}
                      ></Dropzone>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <button
          disabled={loading}
          className="btn btn-secondary py-3 px-9 mr-2 "
          onClick={() => handleBack()}
        >
          Atras
        </button>
        <button
          disabled={loading}
          className="btn btn-primary btn py-3 px-9 "
          type="submit"
        >
          Finalizar
          {loading ? (
            <>
              <span
                className="ml-3 spinner spinner-white"
                style={{ width: "100%" }}
              ></span>
            </>
          ) : (
            ""
          )}
        </button>
      </div>
    </main>
  );
}
