import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { deleteInstance } from "../../pages/Backoffice/_redux/reduxCrud";
import SuccessMessage from "../General/SuccessMessage";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export default function ModalDelete({
  showDelete,
  deleteItem,
  item,
  deletetoDataStructure,
}) {
  const [deleted, setDeleted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const delHandling = async () => {
    setLoading(true);
    try {
      const deletedInstance = await deleteInstance(item);
      setDeleted(true);
      deletetoDataStructure(item);
      setLoading(false);
    } catch (error) {
      const errorMessage = error.response.data.error[0].message;
      setError(errorMessage);
      setLoading(false);
    }
  };

  const closeThisModal = () => {
    deleteItem(false);
    setError("");
    setLoading(false);
    setDeleted(false);
  };
  const resetModal = (value) => {
    deleteItem(value);
    setDeleted(false);
  };

  return (
    <>
      <Modal show={showDelete} onHide={() => {}}>
        <Modal.Header closeButton className="custom-header">
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            {deleted ? "Eliminado Exitoso" : "Eliminar Instancia"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="modal-spinner-overlay">
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          ) : (
            ""
          )}
          {error ? (
            <>
              <div className="row justify-content-center">
                <div className="col-md-12 col-sm-12 col-12 col-xl-12 text-center">
                  <div className="circle-svg-danger">
                    <span className="svg-icon svg-icon-danger svg-icon-10x">
                      <span className="svg-icon-danger svg-icon-10x">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Error-circle.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                  </div>
                  <h5>{error}</h5>
                  <p>Intente nuevamente en otro momento</p>
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-sm-12 col-12 text-center">
                      <button
                        className="btn btn-secondary"
                        onClick={() => closeThisModal(false)}
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {deleted ? (
                <>
                  <SuccessMessage
                    othertitle={true}
                    titulo={"Se ha eliminado la instancia con exito"}
                    opciones={true}
                    optArray={[{ desc: "Cerrar", func: closeThisModal }]}
                  ></SuccessMessage>
                </>
              ) : (
                <>
                  <h3>¿Está seguro que desea eliminar esta instancia?</h3>
                  <div className="row justify-content-center align-items-space around mt-5">
                    <div className="col-md-12 col-sm-12 col-12 text-center">
                      <button
                        className="btn btn-secondary"
                        onClick={() => closeThisModal(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        className="btn btn-primary ml-2"
                        onClick={delHandling}
                      >
                        Confirmar
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
