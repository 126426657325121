import React from "react";
import { transformText } from "../../../_metronic/_helpers/GeneralHelpers";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { deleteFileWithId } from "../../pages/Usuarios/_redux/UsuariosCrud";
import { useDispatch } from "react-redux";
import { actions } from "../../modules/Auth/_redux/authRedux";

export default function MiDocumentacion({
  persona,
  showModalDocumentacionHandle,
}) {
  const [loadMap, setLoadMap] = React.useState({});
  const dispatch = useDispatch();

  const deleteFile = async (id) => {
    setLoadMap((prev) => ({
      ...prev,
      [id]: true,
    }));
    try {
      await deleteFileWithId(id);
      setLoadMap((prev) => ({
        ...prev,
        [id]: false,
      }));

      const updatePersona = {
        ...persona,
        files: persona.files.filter((file) => file.id !== id),
      };

      dispatch(actions.setPersona(updatePersona));
    } catch (error) {
      console.log(error);
      setLoadMap((prev) => ({
        ...prev,
        [id]: false,
      }));
    } finally {
      setLoadMap((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h5>Mi Documentacion</h5>
        <div className="mb-10 mb-lg-20">
          <p className="text-muted font-weight-bold">
            Si debes presentar nuevamente documentación, elimina la que se te ha
            mencionado y subela nuevamente
          </p>
        </div>
        {persona?.files?.length > 0 ? (
          <div className="row ">
            {persona?.files?.map((item, key) => (
              <div
                className="col-xl-3 col-md-4 col-sm-12 col-12 pdf-box"
                key={key}
              >
                {loadMap[item.id] ? (
                  <>
                    <span
                      className="ml-3 spinner spinner-primary"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        margin: "0 auto",
                      }}
                    ></span>
                  </>
                ) : (
                  <>
                    <div
                      className="pdf-box__deleter"
                      onClick={() => deleteFile(item.id)}
                    >
                      <span className="svg-icon svg-icon-white svg-icon-2x">
                        <span className="svg-icon-white svg-icon-2x">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Close-filled.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="pdf-box__content">
                        <p className="pdf-box-content__title">
                          {transformText(item.name)}
                        </p>
                        <span className="svg-icon svg-icon-primary svg-icon-2x">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Eye.svg"
                            )}
                          ></SVG>
                        </span>
                      </div>
                    </a>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>No hay documentos disponibles.</p>
        )}
        <div className="row mt-5">
          <div className="col-md-12 col-sm-12 col-12">
            <h5>
              ¿Te faltó subir documentación o debes presentar nuevamente
              documentación?
            </h5>
          </div>
          <div className="col-md-12 col-sm-12 col-12">
            <button
              className="btn btn-success mx-5 my-3"
              onClick={() => showModalDocumentacionHandle(true)}
            >
              Hace click acá para subir la documentación restante
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
