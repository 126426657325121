import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Button } from "react-bootstrap";
import { useState } from "react";

export default function ConocimientosInformaticos({
  handleConocimientos,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  conocimientosCombo,
}) {
  const [conocimientos, setConocimientos] = useState([]);
  const [showingConocimientos, setShowingConocimientos] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [availableConocimientos, setAvailableConocimientos] = useState(
    conocimientosCombo
  );

  useEffect(() => {
    if (values && values.conocimientos && values.conocimientos) {
      const showingConocimientosData = values.conocimientos.map((cono) => {
        const selectedLang = conocimientosCombo.find(
          (item) => item.id == cono.conocimiento_id
        );
        if (selectedLang) {
          return {
            id: selectedLang.id,
            descripcion: selectedLang.descripcion,
          };
        }
        return null;
      });
      const filteredShowingConocimientosData = showingConocimientosData.filter(
        (lang) => lang !== null
      );
      setShowingConocimientos(filteredShowingConocimientosData);

      const selectedLangIds = filteredShowingConocimientosData.map(
        (lang) => lang.id
      );
      const updatedAvailableLangs = conocimientosCombo.filter(
        (lang) => !selectedLangIds.includes(lang.id)
      );
      setAvailableConocimientos(updatedAvailableLangs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agregarLenguaje = (event) => {
    if (selectedId && selectedId != "-1") {
      const nLang = {
        conocimiento_id: selectedId,
      };
      const updatedconocimientos = [...conocimientos, nLang];
      setConocimientos(updatedconocimientos);
      const selectedLang = conocimientosCombo.find(
        (lang) => lang.id === parseInt(selectedId)
      );
      if (selectedLang) {
        const newLang = {
          id: selectedLang.id,
          descripcion: selectedLang.descripcion,
        };

        const updatedshowingConocimientos = [...showingConocimientos, newLang];
        setShowingConocimientos(updatedshowingConocimientos);
      }

      const updatedavailableConocimientos = availableConocimientos.filter(
        (lang) => lang.id !== parseInt(selectedId)
      );
      setAvailableConocimientos(updatedavailableConocimientos);

      setSelectedId(null);
      handleConocimientos(updatedconocimientos);
    }
  };

  const delLang = (index) => {
    const replace = conocimientos.filter(
      (lang) => lang.conocimiento_id !== index
    );
    setConocimientos(replace);

    // Obtener el lenguaje eliminado del array 'showingConocimientos' basado en el ID
    const deletedLang = showingConocimientos.find((lang) => lang.id === index);

    // Agregar el lenguaje eliminado nuevamente a 'availableConocimientos'
    if (deletedLang) {
      const ordererAvailable = [...availableConocimientos, deletedLang]
        .slice()
        .sort((a, b) => a.id - b.id);
      setAvailableConocimientos(ordererAvailable);
    }

    // Actualizar el estado de 'showingConocimientos' para eliminar el lenguaje
    const delSconocimientos = showingConocimientos.filter(
      (lang) => lang.id !== index
    );
    setShowingConocimientos(delSconocimientos);

    handleConocimientos(conocimientos);
  };

  return (
    <>
      <div className="drag-drop-modal-box">
        <span className="svg-icon svg-icon-primary svg-icon-lg">
          <span className="svg-icon-primary svg-icon-2x">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Shopping/settings.svg")}
            />
          </span>
        </span>
        <h3>Conocimientos Informáticos</h3>
      </div>
      <div className="row flex-row mb-5">
        <div className="col-xs-12 col-md-4 col-sm-4 col-6">
          <label className="form-label" htmlFor="lenguaje_id">
            ¿Con qué conocimientos contás?
          </label>
          <select
            className="form-control"
            name="conocimiento_id"
            id="conocimiento_id"
            onChange={(e) => setSelectedId(e.target.value)}
          >
            <option value="-1">Seleccione una opción...</option>
            {availableConocimientos.map((item, idx) => (
              <React.Fragment key={idx}>
                <option value={item.id}>{item.descripcion}</option>
              </React.Fragment>
            ))}
          </select>
        </div>
        <div className="col-xs-12 col-md-4 col-sm-4 col-6">
          <Button
            onClick={agregarLenguaje}
            style={{
              position: "absolute",
              bottom: "0",
              backgroundColor: "#025692",
            }}
          >
            Agregar
          </Button>
        </div>
      </div>
      <div className="row mb-5">
        {showingConocimientos.map((item, index) => (
          <React.Fragment key={index}>
            <div className="lang-box">
              <p>{item.descripcion}</p>
              <span
                className="svg-icon svg-icon-white svg-icon-lg"
                onClick={() => delLang(item.id)}
              >
                <span className="svg-icon-white svg-icon-2x">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                  />
                </span>
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
