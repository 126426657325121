export const SET_INSCRIPCION = "SET_INSCRIPCION";
export const UPDATE_INSCRIPCION = "UPDATE_INSCRIPCION";
export const ADD_INSCRIPCION = "ADD_INSCRIPCION";
export const REMOVE_INSCRIPCION = "REMOVE_INSCRIPCION";
export const ISLOADING = "ISLOADING";
export const GETAPROBADOS = "GETAPROBADOS";

export function setInscripcion(inscripto) {
  return {
    type: SET_INSCRIPCION,
    payload: inscripto,
  };
}

export const updateinscripto = (inscripto) => {
  return {
    type: "UPDATE_INSCRIPCION",
    payload: inscripto,
  };
};

export const addinscripto = (inscripto) => {
  return {
    type: "ADD_INSCRIPCION",
    payload: inscripto,
  };
};

export const removeinscripto = (inscripto) => {
  return {
    type: "REMOVE_INSCRIPCION",
    payload: inscripto,
  };
};

export const loadingtable = (value) => {
  return {
    type: "ISLOADING",
    payload: value,
  };
};

export const getInscriptosAprobados = (value) => {
  return {
    type: "GETAPROBADOS",
    payload: value,
  };
};

const initStates = {
  inscripciones: [],
  loadingTable: true,
  inscriptosAprobados: [],
};

export function inscripcionesReducer(state = initStates, action) {
  switch (action.type) {
    case SET_INSCRIPCION:
      return {
        ...state,
        inscripciones: action.payload,
      };

    case UPDATE_INSCRIPCION:
      return {
        ...state,
        inscripciones: state.inscripciones.map((int) =>
          int.id === action.payload.id ? action.payload : int
        ),
      };
    case ADD_INSCRIPCION:
      return {
        ...state,
        inscripciones: [...state.inscripciones, action.payload],
      };
    case REMOVE_INSCRIPCION:
      return {
        ...state,
        inscripciones: state.inscripciones.filter(
          (item) => item.id !== action.payload.id
        ),
      };

    case ISLOADING:
      return {
        ...state,
        loadingTable: action.payload,
      };

    case GETAPROBADOS:
      return {
        ...state,
        inscriptosAprobados: action.payload,
      };
    default:
      return state;
  }
}
