import React from "react";
import { Button, Modal } from "react-bootstrap";
import { dateFormatter, toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useFormik } from "formik";
import { InstanceAction } from "../../pages/Backoffice/hooks/backofficehooks";
import { instanceValidationSchema } from "./schemas";
import SuccessMessage from "../General/SuccessMessage";
import Select from "react-select";
import SVG from "react-inlinesvg";

export default function ModalInstancias({
  showInscripcion,
  combos,
  hiddingInscripcion,
  itemSelected,
  addToDataStructure,
  editInDataStructure,
}) {
  const [loading, setLoading] = React.useState(false);
  const [succ, setSucces] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [initialValues] = React.useState({
    carrera_id: 0,
    descripcion: "",
    fecha_publicacion: "",
    fecha_inicio: "",
    fecha_fin: "",
  });

  React.useEffect(() => {
    if (itemSelected) {
      formik.setValues({
        carrera_id: itemSelected.carrera_id || 0,
        descripcion: itemSelected.descripcion || "",
        fecha_publicacion: itemSelected.fecha_publicacion || "",
        fecha_inicio: itemSelected.fecha_inicio || "",
        fecha_fin: itemSelected.fecha_fin || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSelected]);

  const resetForm = () => {
    setLoading(false);
    setSucces(false);
    setErrorMessage(null);
    formik.resetForm();
  };

  const closeThisModal = () => {
    setSucces(false);
    setErrorMessage(null);
    setLoading(false);
    formik.resetForm();
    hiddingInscripcion(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: instanceValidationSchema(combos?.carreras),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const {
          data: { instancia },
        } = await InstanceAction(itemSelected, values);
        if (instancia) {
          setLoading(false);
          setSucces(true);
          itemSelected
            ? editInDataStructure(instancia)
            : addToDataStructure(instancia);
        }
      } catch (error) {
        setErrorMessage(error.response.data.error[0].message);
        setLoading(false);
      }
    },
    onChange: (e) => {
      const { name, value } = e.target;
      formik.setFieldValue(name, value);
    },
  });

  return (
    <Modal
      show={showInscripcion}
      animation={false}
      className={`animate__animated ${
        showInscripcion ? "animate__zoomIn animate__fast" : "animate__fadeOut"
      }`}
      onHide={() => {}}
    >
      <Modal.Header closeButton className="custom-header">
        <Modal.Title className="custom-title" style={{ color: "#fff" }}>
          <h3 className="custom-title">
            {itemSelected ? "EDITAR INSTANCIA" : "CREAR INSTANCIA"}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white">
        {errorMessage ? (
          <>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-12 col-md-12 col-12 text-center">
                  <div className="circle-svg-danger">
                    <span className="svg-icon svg-icon-danger svg-icon-10x">
                      <span className="svg-icon-danger svg-icon-10x">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Error-circle.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                  </div>
                  <h3>Ups... ocurrió un error al procesar la solicitud</h3>
                  <h5>{errorMessage}</h5>
                  <div className="">
                    <Button
                      className="btn btn-primary mx-4 my-3 mt-3"
                      onClick={resetForm}
                    >
                      Volver a intentar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {!succ ? (
              <>
                <form className="form" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12 d-flex flex-column mb-5">
                      <label htmlFor="" className="form-label">
                        Carrera
                      </label>
                      <Select
                        isSearcheble={true}
                        name="carrera_id"
                        id="carrera_id"
                        options={combos?.carreras?.map((item) => ({
                          value: item.id,
                          label: item.descripcion,
                        }))}
                        onChange={(selectedOption) => {
                          const e = {
                            target: {
                              name: "carrera_id",
                              value: selectedOption.value,
                            },
                          };
                          formik.handleChange(e);
                        }}
                        defaultValue={
                          itemSelected
                            ? (() => {
                                const selectedOption = combos?.carreras?.find(
                                  (item) => item.id === itemSelected.carrera_id
                                );
                                if (selectedOption) {
                                  return {
                                    value: selectedOption.id,
                                    label: selectedOption.descripcion,
                                  };
                                }
                              })()
                            : null
                        }
                      ></Select>
                      {formik.touched.carrera_id && formik.errors.carrera_id ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.carrera_id}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 d-flex flex-column mb-5">
                      <label className="form-label">Descripcion</label>
                      <input
                        type="text"
                        name="descripcion"
                        className={`form-control ${
                          formik.touched.descripcion &&
                          formik.errors.descripcion
                            ? "is-invalid"
                            : ""
                        }`}
                        defaultValue={
                          itemSelected
                            ? itemSelected.descripcion
                            : formik.values.descripcion
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.descripcion &&
                      formik.errors.descripcion ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.descripcion}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 d-flex flex-column mb-5">
                      <label className="form-label">Fecha publicación de inscripción</label>
                      <input
                        type="date"
                        className={`form-control ${
                          formik.touched.fecha_publicacion &&
                          formik.errors.fecha_publicacion
                            ? "is-invalid"
                            : ""
                        }`}
                        name="fecha_publicacion"
                        defaultValue={
                          itemSelected
                            ? itemSelected.fecha_publicacion
                            : formik.values.fecha_publicacion
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.fecha_publicacion &&
                      formik.errors.fecha_publicacion ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.fecha_publicacion}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 d-flex flex-column mb-5">
                      <label className="form-label">Fecha fin de inscripción</label>
                      <input
                        type="date"
                        className={`form-control ${
                          formik.touched.fecha_fin && formik.errors.fecha_fin
                            ? "is-invalid"
                            : ""
                        }`}
                        name="fecha_fin"
                        defaultValue={
                          itemSelected
                            ? itemSelected.fecha_fin
                            : formik.values.fecha_fin
                          }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.fecha_fin && formik.errors.fecha_fin ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.fecha_fin}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 d-flex flex-column mb-5">
                      <label className="form-label">Fecha inicio de cursada</label>
                      <input
                        type="date"
                        className={`form-control ${
                          formik.touched.fecha_inicio &&
                          formik.errors.fecha_inicio
                            ? "is-invalid"
                            : ""
                        }`}
                        name="fecha_inicio"
                        defaultValue={
                          itemSelected
                            ? itemSelected.fecha_inicio
                            : formik.values.fecha_inicio
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.fecha_inicio &&
                      formik.errors.fecha_inicio ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.fecha_inicio}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-sm-12 col-12 text-center">
                      <button
                        disabled={loading}
                        type="button"
                        className="btn btn-secondary font-weight-bold px-9 py-4 my-3"
                        onClick={() => hiddingInscripcion(false)}
                      >
                        Cerrar
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 text-center">
                      <button
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        disabled={loading}
                        type="submit"
                      >
                        OK
                        {loading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                <SuccessMessage
                  titulo="la instancia"
                  opciones={true}
                  optArray={[
                    { desc: "Cerrar", func: closeThisModal },
                    { desc: "Cargar Otro", func: resetForm },
                  ]}
                ></SuccessMessage>
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
