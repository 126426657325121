import React, { useState } from "react";

const HelpTooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
    >
      <span className="question-mark text-muted">?</span>
      {showTooltip && <div className="tooltip-text">{text}</div>}
      {children}
    </div>
  );
};

export default HelpTooltip;
