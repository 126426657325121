import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { DniFetchingRealize } from "../../pages/Backoffice/hooks/backofficehooks";
import {
  registerInscriptos_x_existing,
} from "../../pages/RegisterWizard/_redux/registerCrud";
import SuccessMessage from "../General/SuccessMessage";
import { Select } from "react-select-virtualized";
import { CarrerasNewArr } from "../carreras/exampleCarreras";

export default function BuscarPorDni({
  handleBackClick,
  selectedInstance,
  combos,
}) {
  const [estudianteData, setEstudianteData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccesMessage] = React.useState(false);
  const [errorMSG, setErrorMsg] = React.useState("");
  const [overlayLoading, setOverlayLoading] = React.useState(false);
  function noWhitespace() {
    return this.transform((value, originalValue) =>
      /\s/.test(originalValue) ? NaN : value
    );
  }

  const resetSearch = () => {
    setEstudianteData(null);
  };

  const researchComponent = () => {
    setEstudianteData(null);
    setLoading(false);
    setOverlayLoading(false);
    setSuccesMessage(false);
  };

  Yup.addMethod(Yup.number, "noWhitespace", noWhitespace);

  const form = useFormik({
    initialValues: {
      dni: "",
    },
    validationSchema: Yup.object().shape({
      dni: Yup.number("Por favor solo ingrese numeros")
        .typeError("Por favor, solo ingrese numeros")
        .integer("Por favor, ingresar numero entero")
        .positive("Por favor, ingrese valores correctos")
        .required("El campo dni es requerido para continuar")
        .noWhitespace(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const res = await DniFetchingRealize(values);
        setEstudianteData(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
  });

  const formInscripcion = useFormik({
    initialValues: {
      carrera:
        selectedInstance.carrera_id === 1
          ? ""
          : combos.combos.carreras.find(
              (item) => item.id === selectedInstance.carrera_id
            ).descripcion,
    },
    onSubmit: async (values) => {
      try {
        setOverlayLoading(true);
        registerInscriptos_x_existing({
          persona_id: estudianteData.id,
          instancia_id: selectedInstance.id,
          carrera: values.carrera,
          es_beca: 0,
          abona_empresa: 0
        })
          .then(() => {
            setOverlayLoading(false);
            setSuccesMessage(true);
          })
          .catch((e) => {
            setOverlayLoading(false);
            setErrorMsg(e.response.data.error[0].message);
          });
      } catch (error) {}
    },
  });

  return (
    <>
      <div className="container">
        {!errorMSG ? (
          <>
            {!successMessage ? (
              <>
                <h5>
                  Por favor, ingrese el numero de documento de la persona a
                  buscar
                </h5>
                <form onSubmit={form.handleSubmit}>
                  <div className="row">
                    <div className="input-group col-md-6 col-sm-12 col-12 mx-4">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="svg-icon svg-lg svg-icon-primary">
                            <SVG
                              className="search-i"
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Search.svg"
                              )}
                            ></SVG>
                          </span>
                        </div>
                      </div>
                      <input
                        type="text"
                        name="dni"
                        id="dni"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        className={`form-control ${
                          form.touched.dni && form.errors.dni
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                    </div>
                    {form.touched.dni && form.errors.dni ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{form.errors.dni}</div>
                      </div>
                    ) : null}
                  </div>

                  <button
                    className="btn btn-secondary font-weight-bold px-7 py-4 my-3 mx-2"
                    disabled={loading || overlayLoading}
                    onClick={handleBackClick}
                  >
                    Volver
                  </button>
                  <button
                    disabled={
                      (form.touched.dni && form.errors.dni) ||
                      loading ||
                      overlayLoading
                    }
                    className="btn btn-primary font-weight-bold px-7 py-4 my-3 mx-2"
                  >
                    Buscar
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                </form>
                {estudianteData && (
                  <>
                    <div className="info-box">
                      <span className="close-button" onClick={resetSearch}>
                        x
                      </span>
                      <div className="row">
                        <div className="col-md-6 col-sm-12 col-12">
                          <span className="symbol symbol-100 symbol-light-success">
                            <span className="symbol-label font-size-h5 font-weight-bold">
                              {estudianteData.nombre[0]}
                            </span>
                          </span>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                          <label className="form-label">
                            Nombre y Apellido
                          </label>

                          <input
                            type="text"
                            value={`${estudianteData.nombre}, ${estudianteData.apellido}`}
                            disabled={true}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                          <label htmlFor="" className="form-label">
                            Tipo de Documento
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={estudianteData.tipo_documento.toUpperCase()}
                            disabled={true}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                          <label htmlFor="" className="form-label">
                            Numero Documento
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={estudianteData.numero_documento}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                          <label htmlFor="" className="form-label">
                            CUIT
                          </label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={estudianteData.cuit}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                          <label htmlFor="" className="form-label">
                            Fecha Nacimiento
                          </label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={estudianteData.fecha_nacimiento}
                          />
                        </div>
                      </div>
                      <form onSubmit={formInscripcion.handleSubmit}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 col-12">
                            <label
                              htmlFor=""
                              className="form-label font-weight-bolder"
                            >
                              Carrera a Inscribir
                            </label>
                            {selectedInstance.carrera_id === 1 ? (
                              <React.Fragment>
                                <Select
                                  isSearcheble={true}
                                  name="carrera"
                                  id="carrera"
                                  options={CarrerasNewArr(
                                    selectedInstance.carrera_id
                                  ).map((item) => ({
                                    value: item.id,
                                    label: item.carrera,
                                  }))}
                                  onChange={(selectedOption) => {
                                    const e = {
                                      target: {
                                        name: "carrera",
                                        value: selectedOption.label
                                          ? selectedOption.label
                                          : -1,
                                      },
                                    };
                                    formInscripcion.handleChange(e);
                                  }}
                                ></Select>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <p>
                                  {
                                    combos?.combos?.carreras?.find(
                                      (item) =>
                                        item.id === selectedInstance.carrera_id
                                    ).descripcion
                                  }
                                </p>
                              </React.Fragment>
                            )}
                          </div>
                          <button
                            style={{ marginTop: "15px" }}
                            disabled={overlayLoading}
                            type="submit"
                            className="btn btn-primary px-9 py-4"
                          >
                            Inscribir
                            {overlayLoading && (
                              <span className="mx-3 spinner spinner-white"></span>
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <SuccessMessage
                  cls={""}
                  title="la inscripción"
                  opciones={true}
                  optArray={[
                    { desc: "Volver", func: handleBackClick },
                    { desc: "Cargar otro", func: researchComponent },
                  ]}
                ></SuccessMessage>
              </>
            )}
          </>
        ) : (
          <>
            <h5>{errorMSG}</h5>
          </>
        )}
      </div>
    </>
  );
}
