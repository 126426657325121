/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import ResetPassword from "./ResetPassword";
import VerifyAccount from "./VerifyAccount";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(33,33,33,0.7), rgba(33,33,33,0.7)), url(${toAbsoluteUrl(
                "/media/bg/bg-12.jpg"
              )})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-center ">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mx-auto mt-auto">
                <img
                  alt="Logo"
                  className="max-h-100px logo-animation"
                  src={toAbsoluteUrl("/media/logos/utn-logo-blanco.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white text-center">
                  Bienvenido al Sistema de Ingreso de UTN
                </h3>
                {/* <p className="font-weight-lighter text-white opacity-80">
                    The ultimate Bootstrap & React 16 admin theme framework for next
                    generation web apps.
                  </p> */}
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-center mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; 2023 OffnenSystem
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}

            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password/:token"
                  component={ResetPassword}
                />
                <ContentRoute
                  path="/auth/verify-account/:token"
                  component={VerifyAccount}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy;{" "}
                <a
                  href="https://offnensystem.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark-75 text-hover-primary"
                >
                  2023 OffnenSystem
                </a>
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <a
                  href="https://www.frgp.utn.edu.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link pr-3 pl-0"
                >
                  UTN - FRGP
                </a>
                <a
                  href="https://www.frgp.utn.edu.ar/contacto/directo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link px-3"
                >
                  Carreras
                </a>
                <a
                  href="https://www.frgp.utn.edu.ar/extension/guia_cursos"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link pl-3 pr-0"
                >
                  Cursos
                </a>
                <a
                  href="https://www.frgp.utn.edu.ar/contacto/directo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link pl-3 pr-0"
                >
                  Contacto
                </a>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
