import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Dropzone from "../registerwizard/Dropzone/Dropzone";
import { savePersonFiles } from "../../pages/RegisterWizard/_redux/registerCrud";
import SuccessMessage from "../General/SuccessMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFileWithId,
} from "../../pages/Usuarios/_redux/UsuariosCrud";
import { transformText } from "../../../_metronic/_helpers/GeneralHelpers";
import { updateinscripto } from "../../pages/Backoffice/inscripciones/_redux/inscripcionesReducer";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getUserAfterFilesUpload } from "../../pages/Backoffice/_redux/reduxCrud";

export default function ModalUploadFiles({ show, item, close, errorHandling }) {
  const [dataForm] = useState(new FormData());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadMap, setLoadMap] = React.useState({});
  const inscripciones = useSelector(
    (state) => state.inscripciones.inscripciones
  );
  const dispatch = useDispatch();
  const [missingFiles, setMissingFiles] = useState();

  const deleteFile = async (id) => {
    setLoadMap((prev) => ({
      ...prev,
      [id]: true,
    }));
    try {
      await deleteFileWithId(id);
      setLoadMap((prev) => ({
        ...prev,
        [id]: false,
      }));

      const inscriptoIndex = inscripciones.findIndex(
        (ins) => ins.id === item.id
      );

      if (inscriptoIndex !== -1) {
        inscripciones[
          inscriptoIndex
        ].persona.files = item?.persona?.files?.filter(
          (item) => item.id !== id
        );
        dispatch(updateinscripto([...inscripciones]));
      }
    } catch (error) {
      console.log(error);
      setLoadMap((prev) => ({
        ...prev,
        [id]: false,
      }));
    } finally {
      setLoadMap((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };

  React.useEffect(() => {
    const files = [
      "dni_frente",
      "dni_reverso",
      "cuil",
      "partida_nacimiento",
      "analitico",
      "certificado_laboral",
      "cud",
    ];
    setMissingFiles(
      files.filter(
        (type) => !item?.persona?.files?.some((file) => file.name === type)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.persona?.files]);

  const handleModalClose = () => {
    setLoading(false);
    setSuccess(false);
    close();
  };

  const HandleFiles = (name, file) => {
    if (file) {
      dataForm.append(name, file, file.inputName);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      dataForm.append("persona_id", item.persona.id);
      savePersonFiles(dataForm)
        .then(() => {
          getUserAfterFilesUpload(item.persona.id)
            .then(({ data: { persona: pUpdate } }) => {
              const inscriptoIndex = inscripciones.findIndex(
                (ins) => ins.id === item.id
              );

              if (inscriptoIndex !== -1) {
                inscripciones[inscriptoIndex].persona = pUpdate;
                dispatch(updateinscripto([...inscripciones]));
              }
              setLoading(false);
              setSuccess(true);
            })
            .catch(() => {
              setLoading(false);
              errorHandling(true);
            });
        })
        .catch(() => {
          setLoading(false);
          errorHandling(true);
        });
    } catch (error) {
      setLoading(false);
      errorHandling(true);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {}}
        animation={false}
        size="xl"
        className={`animate__animated modalBody ${
          show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
        }`}
      >
        <Modal.Header closeButton className="custom-header">
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            <h3 className="custom-title">
              Documentacion de {item?.persona?.nombre} {item?.persona?.apellido}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="modal-spinner-overlay">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}

          {success ? (
            <>
              <SuccessMessage
                title={"la documentación"}
                opciones={true}
                optArray={[{ desc: "Cerrar", func: handleModalClose }]}
              ></SuccessMessage>
            </>
          ) : (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row">
                        <h5>Documetnacion Faltante</h5>
                        <div className="col-md-12 col-sm-12 col-12 mb-5">
                          <div className="row">
                            {missingFiles?.map((item, idx) => (
                              <React.Fragment key={idx}>
                                <div className="col-md-4 col-sm-12 col-12 mb-5">
                                  <Dropzone
                                    inputName={item}
                                    dataForm={dataForm}
                                    HandleFiles={HandleFiles}
                                    title={transformText(item)}
                                  ></Dropzone>
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <h5>Documetnacion Vigente</h5>
                        <div className="col-md-12 col-sm-12 col-12 mb-5">
                          <div className="row">
                            {item?.persona?.files?.length > 0 ? (
                              <div className="row ">
                                {item?.persona?.files?.map((item, key) => (
                                  <div
                                    className="col-xl-3 col-md-4 col-sm-12 col-12 pdf-box"
                                    key={key}
                                  >
                                    {loadMap[item.id] ? (
                                      <>
                                        <span
                                          className="ml-3 spinner spinner-primary"
                                          style={{
                                            width: "100%",
                                            textAlign: "center",
                                            margin: "0 auto",
                                          }}
                                        ></span>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className="pdf-box__deleter"
                                          onClick={() => deleteFile(item.id)}
                                        >
                                          <span className="svg-icon svg-icon-white svg-icon-2x">
                                            <span className="svg-icon-white svg-icon-2x">
                                              <SVG
                                                src={toAbsoluteUrl(
                                                  "/media/svg/icons/Navigation/Close-filled.svg"
                                                )}
                                              />
                                            </span>
                                          </span>
                                        </div>
                                        <a
                                          href={item.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <div className="pdf-box__content">
                                            <p className="pdf-box-content__title">
                                              {transformText(item.name)}
                                            </p>
                                            <span className="svg-icon svg-icon-primary svg-icon-2x">
                                              <SVG
                                                src={toAbsoluteUrl(
                                                  "/media/svg/icons/General/Eye.svg"
                                                )}
                                              ></SVG>
                                            </span>
                                          </div>
                                        </a>
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p>No hay documentos disponibles.</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row flex-row justify-content-center">
                        <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-center">
                          <button
                            type="button"
                            onClick={close}
                            className="btn btn-secondary px-9 py-4"
                          >
                            Volver
                          </button>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-center">
                          <button
                            className="btn btn-primary px-9 py-4"
                            type="submit"
                          >
                            Guardar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
