import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Dropzone from "../registerwizard/Dropzone/Dropzone";
import { savePersonFiles } from "../../pages/RegisterWizard/_redux/registerCrud";
import SuccessMessage from "../General/SuccessMessage";
import { useDispatch, useSelector } from "react-redux";
import { getMiInfo } from "../../pages/Usuarios/_redux/UsuariosCrud";
import { actions } from "../../modules/Auth/_redux/authRedux";
import { transformText } from "../../../_metronic/_helpers/GeneralHelpers";

export default function ModalUploadDocumentacion({
  show,
  persona,
  handleShow,
  errorHandling,
}) {
  const [dataForm] = useState(new FormData());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { user } = useSelector((state) => state.auth.user);
  const { situacionlaboral } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [missingFiles, setMissingFiles] = useState();

  React.useEffect(() => {
    const files = [
      "dni_frente",
      "dni_reverso",
      "cuil",
      "partida_nacimiento",
      "analitico",
      ...(situacionlaboral?.trabaja_actualmente === 1
        ? ["certificado_laboral"]
        : []),
      ...(persona?.discapacidad ? ["cud"] : []),
    ];
    setMissingFiles(
      files.filter(
        (type) => !persona?.files?.some((file) => file.name === type)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [situacionlaboral, persona.files]);

  const handleModalClose = () => {
    setLoading(false);
    setSuccess(false);
    handleShow();
  };

  const HandleFiles = (name, file) => {
    if (file) {
      dataForm.append(name, file, file.inputName);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      dataForm.append("persona_id", persona.id);
      await savePersonFiles(dataForm);
      const {
        data: { persona: persona_resave },
      } = await getMiInfo(user.id);
      dispatch(actions.setPersona(persona_resave));
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      errorHandling(true);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {}}
        animation={false}
        size="xl"
        className={`animate__animated modalBody ${
          show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
        }`}
      >
        <Modal.Header closeButton className="custom-header">
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            <h3 className="custom-title">Mi Documentacion</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="modal-spinner-overlay">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}

          {success ? (
            <>
              <SuccessMessage
                title={"la documentación"}
                opciones={true}
                optArray={[{ desc: "Cerrar", func: handleModalClose }]}
              ></SuccessMessage>
            </>
          ) : (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 mb-5">
                          <div className="row">
                            {missingFiles?.map((item, idx) => (
                              <React.Fragment key={idx}>
                                <div className="col-md-4 col-sm-12 col-12 mb-5">
                                  <Dropzone
                                    inputName={item}
                                    dataForm={dataForm}
                                    HandleFiles={HandleFiles}
                                    title={transformText(item)}
                                  ></Dropzone>
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="row flex-row justify-content-center">
                        <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-center">
                          <button
                            type="button"
                            onClick={handleShow}
                            className="btn btn-secondary px-9 py-4"
                          >
                            Volver
                          </button>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-6 col-6 text-center">
                          <button
                            className="btn btn-primary px-9 py-4"
                            type="submit"
                          >
                            Guardar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
