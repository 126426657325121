import React from "react";

import SituacionLaboral from "../../../pages/RegisterWizard/SubWizard/Step3/SituacionLaboral";
import SituacionPadre from "../../../pages/RegisterWizard/SubWizard/Step3/SituacionPadre";
import SituacionMadre from "../../../pages/RegisterWizard/SubWizard/Step3/SituacionMadre";
import SituacionConcubinato from "../../../pages/RegisterWizard/SubWizard/Step3/SituacionConcubinato";

export default function RegisterStep3({
  handleNext,
  handleBack,
  form,
  combos,
}) {
  const handleNextClick = () => {
    form.validateForm().then((errors) => {
      const estudioSecundarioErrors = Object.keys(errors).filter(
        (field) =>
          field.startsWith("situacion_laboral") ||
          field.startsWith("situacion_madre") ||
          field.startsWith("situacion_padre") ||
          field.startsWith("situacion_conyuge")
      );
      if (estudioSecundarioErrors.length === 0) {
        handleNext();
      } else {
        form.setTouched({ ...form.touched, ...errors }, true);
      }
    });
  };
  return (
    <>
      <div className="container">
        <SituacionLaboral
          values={form.values}
          form={form}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
        ></SituacionLaboral>
        <SituacionPadre
          values={form.values}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          niveles={combos.combos.niveles_estudio}
        ></SituacionPadre>
        <SituacionMadre
          values={form.values}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          niveles={combos.combos.niveles_estudio}
        ></SituacionMadre>
        <SituacionConcubinato
          values={form.values}
          errors={form.errors}
          touched={form.touched}
          handleChange={form.handleChange}
          handleBlur={form.handleBlur}
          niveles={combos.combos.niveles_estudio}
        ></SituacionConcubinato>
        <button
          className="btn btn-secondary py-3 px-9 mr-2"
          onClick={() => handleBack()}
        >
          Atras
        </button>
        <button className="btn btn-primary py-3 px-9" onClick={handleNextClick}>
          Siguiente
        </button>
      </div>
    </>
  );
}
