import React, { useEffect } from "react";
import { useState } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export default function Discapacidades({
  handleBlur,
  values,
  handleChangeDiscapacidades,
  handleAddDiscapacidad,
  touched,
  hasDiscapacidad,
  setHasDiscapacidad,
  errors,
  discapacidadesCombos,
  errorDiscapacidad,
}) {
 
  const [nroCertificado, setNroCertificado] = useState(
    values ? values?.discapacidades[0]?.numero_certificado : ""
  );
  const [arrayDiscapacidades, setArrayDiscapacidades] = useState(
    values ? values.discapacidades : []
  );
  const [selectedId, setSelectedId] = useState();
  const [availableOptions, setAvailableOptions] = useState(
    discapacidadesCombos
  );
  const [error, setError] = useState("");

  useEffect(() => {
    if (values && values.discapacidades && values.discapacidades.length > 0) {
      setHasDiscapacidad(true);
      const showDiscaData = values.discapacidades.map((disca) => {
        const selectedDisca = discapacidadesCombos.find(
          (item) => item.id === parseInt(disca.discapacidad_id)
        );
        if (selectedDisca) {
          return selectedDisca;
        } else {
          return null;
        }
      });

      const optionsToSet = values.discapacidades.map((disca) => {
        const selectedDisca = discapacidadesCombos.find(
          (item) => item.id === parseInt(disca.discapacidad_id)
        );
        if (selectedDisca) {
          return selectedDisca.id;
        } else {
          return null;
        }
      });

      const filteredShowDiscapacidades = showDiscaData.filter(
        (disca) => disca !== null
      );
      setArrayDiscapacidades(filteredShowDiscapacidades);

      const updateAvailableDisca = discapacidadesCombos.filter(
        (disca) => !optionsToSet.includes(disca.id)
      );
      setAvailableOptions(updateAvailableDisca);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeNroCertificado = (e) => {
    setNroCertificado(e.target.value);
  };

  const agregarDiscapacidad = () => {
    if (nroCertificado && selectedId && selectedId !== "-1") {
      setError(null);
      const nuevaDiscapacidad = {
        discapacidad_id: selectedId,
        certificado: 1,
        numero_certificado: nroCertificado,
      };
      const selectedOption = availableOptions.find(
        (option) => option.id === parseInt(selectedId)
      );
      const nombreDiscapacidad = selectedOption ? selectedOption : "";
      const updArrayDiscapacidades = [
        ...arrayDiscapacidades,
        nombreDiscapacidad,
      ];

      const upArrFormik = [...values.discapacidades, nuevaDiscapacidad];
      setArrayDiscapacidades(updArrayDiscapacidades);
      handleAddDiscapacidad(upArrFormik);

      const updAvailable = availableOptions.filter(
        (item) => item.id !== parseInt(selectedId)
      );

      setAvailableOptions(updAvailable);
      setSelectedId(undefined);
    } else {
      setError(
        "Por favor, seleccione una discapacidad y complete el número unico de Certificado de Discapacidad"
      );
    }
  };

  
  const actualizarDiscapacidad = (value) => {
    if (value === false) {
      setArrayDiscapacidades([]);
      handleAddDiscapacidad([]);
    }
    setHasDiscapacidad(value);
  };


  const eliminarDiscapacidad = (id) => {
    const updatedArray = arrayDiscapacidades.filter((item) => item.id !== id);
    setArrayDiscapacidades(updatedArray);

    // Elimina el elemento correspondiente de values.discapacidades
    const updatedValuesDiscapacidades = values.discapacidades.filter(
      (item) => parseInt(item.discapacidad_id) !== parseInt(id)
    );
    handleAddDiscapacidad(updatedValuesDiscapacidades);

    const removedOption = discapacidadesCombos.find(
      (option) => parseInt(option.id) === parseInt(id)
    );
    if (removedOption) {
      const updatedAvailableOptions = [...availableOptions, removedOption];
      setAvailableOptions(updatedAvailableOptions);
    }
  };


  return (
    <>
      <div className="container">
        <div className="drag-drop-modal-box">
          <span className="svg-icon svg-icon-primary svg-icon-lg">
            <span className="svg-icon-primary svg-icon-2x">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
          </span>
          <h3>Discapacidad</h3>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12 mb-5">
            <label className="form-label" htmlFor="">
              ¿Contás con Certificado Unico de Discapacidad?
            </label>
            <div className="form-check">
              <input
                type="checkbox"
                checked={hasDiscapacidad === true}
                className="form-check-input"
                onChange={() => actualizarDiscapacidad(true)}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Si
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                checked={hasDiscapacidad === false}
                className="form-check-input"
                onChange={() => actualizarDiscapacidad(false)}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                No
              </label>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-12-mb 5">
            {hasDiscapacidad && (
              <>
                <div className="row mb-5">
                  <div className="col-md-4 col-sm-12 col-12">
                    <label htmlFor="" className="form-label">
                      Nro de Certificado
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChangeNroCertificado}
                      defaultValue={
                        values?.discapacidades[0]?.numero_certificado
                          ? values?.discapacidades[0]?.numero_certificado
                          : ""
                      }
                    />
                  </div>
                </div>
                <h5>Por favor, añada las discapacidades correspondientes</h5>
                <div className="row mb-5">
                  <div className="col-md-6 col-sm-12 col-12 mb-5">
                    <div className="row flex-column">
                      <div className="col-12">
                        <label className="form-label" htmlFor="">
                          Tipo de discapacidad
                        </label>
                        <select
                          className={`form-control`}
                          value={selectedId}
                          name="discapacidad_id"
                          id="discapacidad_id"
                          onChange={(e) => setSelectedId(e.target.value)}
                        >
                          <option value="-1">Seleccione una opcion...</option>
                          {availableOptions.map((item, idx) => (
                            <React.Fragment key={idx}>
                              <option value={item.id}>
                                {item.descripcion}
                              </option>
                            </React.Fragment>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-12">
                    <div
                      className="row
                    align-items-start
                    h-100"
                    >
                      <span
                        className="svg-icon svg-icon-white svg-icon-lg btn-save-discapacidad"
                        onClick={agregarDiscapacidad}
                      >
                        <span className="svg-icon-white svg-icon-2x">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Check.svg"
                            )}
                          ></SVG>
                          <span className="add-estudy-span">Guardar</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row my-5 mb-5">
                  {arrayDiscapacidades.length > 0 &&
                    arrayDiscapacidades.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <div className="lang-box">
                          <p>{item.descripcion}</p>
                          <span
                            onClick={() => eliminarDiscapacidad(item.id)}
                            className="svg-icon svg-icon-white svg-icon-lg"
                          >
                            <span className="svg-icon svg-icon-white svg-icon-2x">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Navigation/Close.svg"
                                )}
                              />
                            </span>
                          </span>
                        </div>
                      </React.Fragment>
                    ))}
                </div>
                {error && <div className="text-danger">{error}</div>}
                {errorDiscapacidad && <div className="text-danger">{errorDiscapacidad}</div>}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
