import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as instances from "../app/pages/Backoffice/instances/_redux/instancesReducer";
import * as inscripciones from "../app/pages/Backoffice/inscripciones/_redux/inscripcionesReducer";
import * as users from "../app/pages/Backoffice/usuarios/_redux/usersRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  instances: instances.instancesReducer,
  inscripciones: inscripciones.inscripcionesReducer,
  users: users.usersReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
