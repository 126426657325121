import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Button } from "react-bootstrap";
import { useState } from "react";

export default function Lenguajes({
  handleOtrosLenguajes,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  lenguajes,
}) {
  const [langs, setLangs] = useState([]);
  const [showingLangs, setShowingLangs] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [availableLangs, setAvailableLangs] = useState(lenguajes);

  useEffect(() => {
    if (values && values.lenguajes) {
      const showingLangsData = values.lenguajes.map((lang) => {
        const selectedLang = lenguajes.find(
          (item) => item.id == lang.lenguaje_id
        );
        if (selectedLang) {
          return {
            id: selectedLang.id,
            descripcion: selectedLang.descripcion,
          };
        }
        return null;
      });
      const filteredShowingLangsData = showingLangsData.filter(
        (lang) => lang !== null
      );
      setShowingLangs(filteredShowingLangsData);

      const selectedLangIds = filteredShowingLangsData.map((lang) => lang.id);
      const updatedAvailableLangs = lenguajes.filter(
        (lang) => !selectedLangIds.includes(lang.id)
      );
      setAvailableLangs(updatedAvailableLangs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agregarLenguaje = (event) => {
    if (selectedId && selectedId != "-1") {
      const nLang = {
        lenguaje_id: selectedId,
      };
      const updatedLangs = [...langs, nLang];
      setLangs(updatedLangs);
      const selectedLang = lenguajes.find(
        (lang) => lang.id === parseInt(selectedId)
      );
      if (selectedLang) {
        const newLang = {
          id: selectedLang.id,
          descripcion: selectedLang.descripcion,
        };

        const updatedShowingLangs = [...showingLangs, newLang];
        setShowingLangs(updatedShowingLangs);
      }

      const updatedAvailableLangs = availableLangs.filter(
        (lang) => lang.id !== parseInt(selectedId)
      );
      setAvailableLangs(updatedAvailableLangs);

      setSelectedId(null);
      handleOtrosLenguajes(updatedLangs);
    }
  };

  const delLang = (index) => {
    const replace = langs.filter((lang) => lang.lenguaje_id !== index);
    setLangs(replace);
    const deletedLang = showingLangs.find((lang) => lang.id === index);

    if (deletedLang) {
      const ordererAvailable = [...availableLangs, deletedLang]
        .slice()
        .sort((a, b) => a.id - b.id);
      setAvailableLangs(ordererAvailable);
    }
    const delSLangs = showingLangs.filter((lang) => lang.id !== index);
    setShowingLangs(delSLangs);
    handleOtrosLenguajes(langs);
  };

  return (
    <>
      <div className="drag-drop-modal-box">
        <span className="svg-icon svg-icon-primary svg-icon-lg">
          <span className="svg-icon-primary svg-icon-2x">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Shopping/settings.svg")}
            />
          </span>
        </span>
        <h3>Lenguajes</h3>
      </div>
      <div className="row flex-row mb-5">
        <div className="col-xs-12 col-md-4 col-sm-4 col-12">
          <label className="form-label" htmlFor="lenguaje_id">
            ¿Qué Lenguajes Conoces?
          </label>
          <select
            className="form-control"
            name="lenguaje_id"
            id="lenguaje_id"
            onChange={(e) => setSelectedId(e.target.value)}
          >
            <option value="-1">Seleccione una opción...</option>
            {availableLangs.map((item, idx) => (
              <React.Fragment key={idx}>
                <option value={item.id}>{item.descripcion}</option>
              </React.Fragment>
            ))}
          </select>
        </div>
        <div className="col-xs-12 col-md-4 col-sm-4 col-12">
          <Button
            onClick={agregarLenguaje}
            style={{
              position: "absolute",
              bottom: "0",
              backgroundColor: "#025692",
            }}
          >
            Agregar
          </Button>
        </div>
      </div>
      <div className="row mb-5">
        {showingLangs.map((item, index) => (
          <React.Fragment key={index}>
            <div className="lang-box">
              <p>{item.descripcion}</p>
              <span
                className="svg-icon svg-icon-white svg-icon-lg"
                onClick={() => delLang(item.id)}
              >
                <span className="svg-icon-white svg-icon-2x">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                  />
                </span>
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
