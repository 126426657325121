import React from "react";
import { Image, Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export default function ModalNotas({
  show,
  showingNotasModal,
  nav,
  selectedInstance,
}) {
  return (
    <>
      <Modal
        show={show}
        animation={false}
        size={"xl"}
        className={`animate__animated ${
          show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
        }`}
        onHide={() => {}}
      >
        <Modal.Header className="custom-header" closeButton>
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            <h3 className="custom-title">Notas</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-12 my-5">
                <div
                  className="option-box"
                  onClick={() =>
                    nav.push(`/instancias/notas/edit/${selectedInstance.id}`)
                  }
                  style={{ backgroundColor: "#B4E7D9" }}
                >
                  <div className="option-box__title">
                    <span style={{ fontSize: "14px" }}>Modificar Notas</span>
                  </div>
                  <div className="option-box__img">
                    <Image
                      className=""
                      src={toAbsoluteUrl("/media/svg/avatars/021-boy-8.svg")}
                    ></Image>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12 my-5">
                <div
                  className="option-box"
                  style={{ backgroundColor: "#FFD8B8" }}
                  onClick={() =>
                    nav.push(`/instancias/notas/${selectedInstance.id}`)
                  }
                >
                  <div className="option-box__title">
                    <span style={{ fontSize: "14px" }}>
                      Nuevo Registro de Notas
                    </span>
                  </div>
                  <div className="option-box__img">
                    <Image
                      className=""
                      src={toAbsoluteUrl("/media/svg/avatars/010-girl-4.svg")}
                    ></Image>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center align-center">
              <button
                className="btn btn-secondary py-4 px-5"
                onClick={() => showingNotasModal(false)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
