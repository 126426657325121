import { Button, Checkbox } from "@material-ui/core";
import React from "react";

export default function AsistenciaStep3({
  estudiantes,
  handleNext,
  handleBack,
  handleAusentes,
  form,
  loading,
}) {
  const [ausentes, setAusentes] = React.useState(
    form.values.ausentes ? form.values.ausentes : []
  );

  const [searchQuery, setSearchQuery] = React.useState("");

  const ipp = 10;
  const [currPage, setCurrPage] = React.useState(1);

  const startIdx = (currPage - 1) * ipp;
  const endIdx = startIdx + ipp;

  const nextPage = () => {
    setCurrPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrPage((ppage) => ppage - 1);
  };

  const goto = (pnum) => {
    setCurrPage(pnum);
  };

  const filteredItems = estudiantes.filter((item) =>
    item.persona?.numero_documento.includes(searchQuery)
  );

  const currItems =
    filteredItems?.length > 0
      ? filteredItems?.slice(startIdx ?? 0, endIdx ?? 0)
      : [];

  const handleAusente = (id) => {
    if (ausentes.includes(id)) {
      setAusentes((curr) => curr.filter((c) => c != id));
    } else {
      setAusentes((curr) => [...curr, id]);
    }
  };

  React.useEffect(() => {
    handleAusentes(ausentes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ausentes]);

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-12">
            <h5>Seleccione los Estudiantes que estuvieron ausentes</h5>
          </div>
        </div>
        <div className="card-header border-0 pt-5">
          <div className="card-toolbar">
            <label htmlFor="" className="form-label">
              Buscar por dni
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Buscar..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
          <thead>
            <tr className="text-left text-uppercase">
              <th style={{ minWidth: "200px" }}>Nombre y Apellido</th>
              <th style={{ minWidth: "100px" }}>DNI</th>
              <th style={{ minWidth: "125px" }}>Ausente</th>
            </tr>
          </thead>
          <tbody>
            {currItems ? (
              currItems?.map((item, index) => (
                <tr key={index}>
                  <td className="pl-0">
                    <span>
                      {item.persona?.apellido}, {item.persona?.nombre}
                    </span>
                  </td>
                  <td className="pl-0">
                    <span>{item.persona?.numero_documento}</span>
                  </td>
                  <td className="pl-0">
                    <Checkbox
                      checked={ausentes.includes(item.id)}
                      onChange={() => handleAusente(item.id)}
                    ></Checkbox>
                  </td>
                </tr>
              ))
            ) : (
              <>
                <p>No hay instancias creadas!</p>
                <p>Crea la primer instancia para empezar a trabjar</p>
              </>
            )}
          </tbody>
        </table>
        <div className="mb-5">
          <div>
            <button
              className="btn btn-light btn-sm"
              disabled={currPage === 1}
              onClick={prevPage}
            >
              Anterior
            </button>
            {estudiantes.length > ipp && (
              <>
                {[...Array(Math.ceil(estudiantes.length / ipp)).keys()].map(
                  (item, idx) => (
                    <button
                      key={idx}
                      className={`btn btn-light btn-sm mx-1 ${
                        currPage === item + 1 ? "active" : ""
                      }`}
                      onClick={() => goto(item + 1)}
                    >
                      {item + 1}
                    </button>
                  )
                )}
              </>
            )}
            <button
              className="btn btn-light btn-sm"
              disabled={endIdx >= estudiantes.length}
              onClick={nextPage}
            >
              Siguiente
            </button>
          </div>
        </div>
        <button
          className="btn btn-secondary btn-md mr-5"
          disabled={loading}
          onClick={() => handleBack()}
        >
          Atras
        </button>
        <button
          className="btn btn-primary btn-md"
          disabled={loading}
          type="submit"
        >
          Finalizar
          {loading && (
            <>
              <span
                className="ml-3 spinner spinner-white"
                style={{ width: "100%" }}
              ></span>
            </>
          )}
        </button>
      </div>
    </React.Fragment>
  );
}
