import axios from "axios";
export const LOGIN_URL = `${process.env.REACT_APP_DOMAIN_NAME}/api/login/`;
export const REGISTER_URL = `${process.env.REACT_APP_DOMAIN_NAME}/api/register/`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_DOMAIN_NAME}/api/forgotpassword`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_DOMAIN_NAME}/api/resetpassword`;
export const ME_URL = `${process.env.REACT_APP_DOMAIN_NAME}/api/userinfo/`;
export const GET_USERDETAILS = `${process.env.REACT_APP_DOMAIN_NAME}/api/personas/get_by_user/`;

export function login(values) {
  return axios.post(LOGIN_URL, values);
}

export function register(email, password, c_password, name) {
  return axios.post(REGISTER_URL, { email, password, c_password, name });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(token, password, c_password) {
  return axios.post(RESET_PASSWORD_URL, { token, password, c_password });
}

export function getUserByToken() {
  return axios.post(ME_URL);
}

export const getDatos = async (id, token) => {
  return await axios.get(`${GET_USERDETAILS}${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
