import axios from "axios";

const DATOSPERSONALES_URL = `${process.env.REACT_APP_DOMAIN_NAME}/api/bff/personas/`;
const SAVEALLDATA_URL = `${process.env.REACT_APP_DOMAIN_NAME}/api/wizard-persona/`;
const GETWIZARDCOMBOS_URL = `${process.env.REACT_APP_DOMAIN_NAME}/api/data-form-persona`;
const FROM_INSTANCE = `${process.env.REACT_APP_DOMAIN_NAME}/api/inscriptos/`;
const SAVE_FILES = `${process.env.REACT_APP_DOMAIN_NAME}/api/files/store_docs_persona/`;

export const registerDatosPersonales = (values) => {
  return axios.post(DATOSPERSONALES_URL, values);
};

export const registerDatosEducacion = (values) => {
  return axios.post(DATOSPERSONALES_URL, values);
};

export const saveAllData = (values) => {
  return axios.post(SAVEALLDATA_URL, values);
};

export const getWizardCombos = async (setErrorMessage) => {
  try {
    const res = await axios.get(GETWIZARDCOMBOS_URL);
    return res.data;
  } catch (error) {
    setErrorMessage(true);
  }
};

export const savePersonFiles = async (formdata) => {
  return await axios.post(SAVE_FILES, formdata);
};

export const registerFromInstance = (id, fromInstance, carreer) => {
  return axios.post(FROM_INSTANCE, {
    persona_id: id,
    instancia_id: fromInstance.id,
    carrera: carreer,
    es_beca: 0,
    abona_empresa: 0
  });
};

export const registerInscriptos_x_existing = async (values) => {
  return await axios.post(FROM_INSTANCE, values);
};
