export const documentType = [
  {
    id: "dni",
    descripcion: "D.N.I",
  },
  {
    id: "lc",
    descripcion: "Libreta Civica",
  },
  {
    id: "le",
    descripcion: "Libreta Enrolamiento",
  },
  {
    id: "ci",
    descripcion: "Cédula de Identidad",
  },
];

export const estadosCiviles = [
  {
    id: "soltero",
    descripcion: "Soltero/a/e",
  },
  {
    id: "casado",
    descripcion: "Casado/a/e",
  },
  {
    id: "viudo",
    descripcion: "Viudo/a/e",
  },
  {
    id: "divorciado",
    descripcion: "Divorciado/a/e",
  },
];

export const bloodTypes = [
  {
    id: "a+",
    descripcion: "A+",
  },
  {
    id: "a-",
    descripcion: "A-",
  },
  {
    id: "b+",
    descripcion: "B+",
  },
  {
    id: "b-",
    descripcion: "B-"
  },
  {
    id: "AB-",
    descripcion: "AB-",
  },
  {
    id: "AB+",
    descripcion: "AB+",
  },
  {
    id: "o+",
    descripcion: "o+",
  },
  {
    id: "o-",
    descripcion: "O-",
  },
];
