import React from "react";
import { Link } from "react-router-dom";

export default function HeaderEstudianteMenu({
  layoutProps,
  getMenuItemActive,
}) {
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/carreras"
          )}`}
        >
          <Link className="menu-link" to="/carreras">
            <span className="menu-text">Carreras</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </Link>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/*end::1 Level*/}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}
      </ul>
    </>
  );
}
