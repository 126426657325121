import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("El formato de email ingresado es erroneo")
      .min(3, "Minimo debe ingresar 3 caracteres")
      .max(50, "Maximo debe ingresar 50 caracteres")
      .required("El campo es requerido."),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then(() => {
          setIsRequested(true);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.FORGOT.SUCCESS.EMAIL.SEND" },
              { name: values.email }
            )
          );
          setSubmitting(false);
        })
        .catch((e) => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  return (
    <>
      <div
        className="login-form login-forgot animated animate__animated animate__slideInUp animate__delay-0s"
        style={{ display: "block" }}
      >
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">¿Has olvidado tu contrase&ntilde;a?</h3>
          <div className="text-muted font-weight-bold">
            Ingresa tu email para reiniciar tu contrase&ntilde;a
          </div>
        </div>
        {isRequested
          ? formik.status && (
              <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )
          : formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            {isRequested ? (
              <>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Volver
                  </button>
                </Link>
              </>
            ) : (
              <>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancelar
                  </button>
                </Link>
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  disabled={formik.isSubmitting}
                >
                  Continuar{" "}
                  {formik.isSubmitting && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
