import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
export default function SituacionMadre({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  niveles
}) {
  const situacionMadre = values.situacion_madre.vive === "1";

  return (
    <>
      <div className="drag-drop-modal-box">
        <span className="svg-icon svg-icon-primary svg-icon-lg">
          <span className="svg-icon-primary svg-icon-2x">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/add-user.svg")}
            />
          </span>
        </span>
        <h3>Situación de la Madre</h3>
      </div>
      <div className="row mb-5">
        <div className="col-md-12 col-sm-12 col-12 mb-5">
          <label htmlFor="" className="form-label">
            ¿Su Madre vive?
          </label>
          <div className="flex-column">
            <div className="flex-row">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={values.situacion_madre.vive === "1"}
                value="1"
                onChange={() => handleChange("situacion_madre.vive")("1")}
              />{" "}
              <label htmlFor="">Si</label>
            </div>
            <div className="flex-row">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={values.situacion_madre.vive === "0"}
                value="0"
                onChange={() => handleChange("situacion_madre.vive")("0")}
              />{" "}
              <label htmlFor="">No</label>
            </div>
          </div>
          {touched.situacion_madre?.vive &&
          errors.situacion_madre?.vive ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {errors.situacion_madre.vive}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {situacionMadre && (
        <>
          <div className="row mb-5 transicion">
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <div className="row flex-column">
                <div className="col-12">
                  <label className="form-label">
                    Nivel de Estudios del Madre
                  </label>
                  <select
                    className={`form-control ${
                      touched.situacion_madre?.nivel_estudio_id_madre && errors.situacion_madre?.nivel_estudio_id_madre ? "is-invalid" : ""
                    }`}
                    name="situacion_madre.nivel_estudio_id_madre"
                    value={values.situacion_madre.nivel_estudio_id_madre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="nivel_estudio_id_madre"
                  >
                      <option value="-1">Seleccione una opcion...</option>
                      {niveles.map((item, idx) => (
                        <React.Fragment key={idx}>
                          <option value={item.id}>{item.descripcion}</option>
                        </React.Fragment>
                      ))}
                  </select>
                  {touched.situacion_madre?.nivel_estudio_id_madre &&
                errors.situacion_madre?.nivel_estudio_id_madre ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_madre.nivel_estudio_id_madre}
                  </div>
                </div>
              ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12 mb-5">
              <label htmlFor="" className="form-label">
                Ocupacion de la Madre
              </label>
              <input
                type="text"
                id="ocupacion_madre"
                name="situacion_madre.ocupacion_madre"
                value={values.situacion_madre.ocupacion_madre}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`form-control ${
                  touched.situacion_madre?.ocupacion_madre && errors.situacion_madre?.ocupacion_madre ? "is-invalid" : ""
                }`}
              />
              {touched.situacion_madre?.ocupacion_madre &&
                errors.situacion_madre?.ocupacion_madre ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {errors.situacion_madre.ocupacion_madre}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}
