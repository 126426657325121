import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../_redux/authCrud";
import { useParams, useHistory, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  password: "",
  c_password: "",
};

export default function ResetPassword(props) {
  const { token } = useParams();
  const his = useHistory();

  const [isRequested, setIsRequested] = useState();
  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, "Minimo debe ingresar 3 caracteres")
      .max(50, "Maximo debe ingresar 50 caracteres")
      .required("El campo es requerido."),
    c_password: Yup.string()
      .required("El campo es requerido.")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Las contraseñas deben ser iguales"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      resetPassword(token, values.password, values.c_password)
        .then(() => {
          console.log("success");
          setIsRequested(true);
          setStatus({
            success: true,
            message: "Contraseña reestablecida con exito",
          });
          setSubmitting(false);
        })
        .catch(() => {
          setStatus({
            error: true,
            message: "Ha ocurrido un error. Por favor, intente de nuevo",
          });
          setIsRequested(false);
          setSubmitting(false);
        });
    },
  });

  const returnToLogin = () => {
    setIsRequested(false);
    his.push("/login");
  };

  return (
    <>
      {!isRequested ? (
        <>
          <div
            className="login-form login-forgot animated animate__animated animate__slideInUp animate__delay-0s"
            style={{ display: "block" }}
          >
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">Reestablecer contraseña</h3>
              <div className="text-muted font-weight-bold">
                Ingresa tu nueva contraseña
              </div>
            </div>
            {isRequested
              ? formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )
              : formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
            >
              <div className="form-group fv-plugins-icon-container">
                <input
                  type="password"
                  placeholder="Contraseña"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Confirmar contrase&ntilde;a"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "c_password"
                  )}`}
                  name="c_password"
                  {...formik.getFieldProps("c_password")}
                />
                {formik.touched.c_password && formik.errors.c_password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.c_password}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                {isRequested ? (
                  <>
                    <Link to="/auth">
                      <button
                        type="button"
                        id="kt_login_forgot_cancel"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                      >
                        Volver
                      </button>
                    </Link>
                  </>
                ) : (
                  <>
                    <button
                      id="kt_login_forgot_submit"
                      type="submit"
                      className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                      disabled={formik.isSubmitting}
                    >
                      Continuar{" "}
                      {formik.isSubmitting && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="container animated animate__animated animate__slideInUp animate__delay-0s d-flex justify-content-center align-items-center">
            <div className="text-center">
              <h3 style={{ marginBottom: "25px" }}>
                Tu contraseña fue restablecida con exito
              </h3>
              <div className="circle-svg mt-4">
                <span className="svg-icon svg-icon-primary svg-icon-7x">
                  <span className="svg-icon-primary svg-icon-2x">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Like.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="mt-5">
                <button
                  onClick={() => returnToLogin()}
                  className="btn btn-lg btn-primary"
                  style={{ fontSize: "1rem" }}
                >
                  Ir a Login
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
