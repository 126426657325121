import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import Asistencias from "./pages/Backoffice/instances/AsistenciasWizard/Asistencias";
import Inscripciones from "./pages/Backoffice/inscripciones/Inscripciones";
import Instancias from "./pages/Backoffice/instances/Instancias";
import Usuarios from "./pages/Backoffice/usuarios/Usuarios";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/instancias" />
        }
        <ContentRoute path="/instancias" component={Instancias}>
          <Route exact path="/instancias" component={Instancias}></Route>
          <Route
            exact
            path="/instancias/asistencias/:instance"
            component={Asistencias}
          ></Route>
          <Route
            exact
            path="/instancias/asistencias/edit/:instance"
            component={Asistencias}
          ></Route>
          <Route
            exact
            path="/instancias/notas/:instance"
            component={Asistencias}
          ></Route>
          <Route
            exact
            path="/instancias/notas/edit/:instance"
            component={Asistencias}
          ></Route>
        </ContentRoute>

        <ContentRoute
          exact
          path="/inscripciones"
          component={Inscripciones}
        ></ContentRoute>
        <ContentRoute
          path="/inscripciones/:instance"
          component={Inscripciones}
        ></ContentRoute>
        <ContentRoute
          path="/usuarios"
          component={Usuarios}
        ></ContentRoute>
        
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
