import React from "react";
import TablaInscripciones from "../../../components/inscripciones/TablaInscripciones";
import { useFetchingInscriptos_x_instancia } from "../hooks/inscripcionesHooks";
import ModalInscripciones from "../../../components/inscripciones/ModalInscripciones";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { dateFormatter, dateParser, toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import Loader from "../../../components/registerwizard/Loader/Loader";
import ModalUploadFiles from "../../../components/inscripciones/ModalUploadFiles";

export default function Inscripciones() {
  const [showInscripciones, setShowInscripciones] = React.useState(false);
  const [showUpload, setShowUpload] = React.useState(false);
  const [selectedInscripcion, setSelectedInscripcion] = React.useState({});
  const [selectedInstance, setSelectedInstance] = React.useState();
  const [query, setQuery] = React.useState("");
  const [suggestingInstances, setSuggestingInstances] = React.useState([]);
  const nav = useHistory();
  const { instance } = useParams();
  useFetchingInscriptos_x_instancia();
  const inscripciones = useSelector(
    (state) => state.inscripciones.inscripciones
  );
  const instancias = useSelector((state) => state.instances.instances);
  const loadingTable = useSelector((state) => state.inscripciones.loadingTable);

  const showingHandle = (value) => {
    setShowInscripciones(value);
    setSelectedInscripcion(null);
  };

  const showingModalFilesUpload = (value) => {
    setShowUpload(true);
    setSelectedInscripcion(value);
  };

  const closeFileModal = () => {
    setShowUpload(false);
    setSelectedInscripcion(null);
  };

  const callInscriptos_instance = async (id) => {
    nav.push(`/inscripciones/${id}`);
  };

  const resetSearch = () => {
    setSelectedInstance(null);
    setQuery(null);
  };

  const onClickEditInstance = (item) => {
    setShowInscripciones(true);
    setSelectedInscripcion(item);
  };

  const handleSuggering = (event) => {
    const newQ = event.target.value;
    setQuery(newQ);

    const filInstances = instancias.filter((inst) =>
      inst.descripcion.toLowerCase().includes(newQ.toLowerCase())
    );
    setSuggestingInstances(filInstances);
  };

  if (instance) {
    return (
      <>
        <TablaInscripciones
          data={inscripciones}
          chooseInstance={onClickEditInstance}
          clickModalShow={showingHandle}
          isLoad={loadingTable}
          showingModalFilesUpload={showingModalFilesUpload}
        ></TablaInscripciones>
        {showInscripciones && (
          <>
            <ModalInscripciones
              show={showInscripciones}
              itemSelected={selectedInscripcion}
              hiddingInscripcion={showingHandle}
            ></ModalInscripciones>
          </>
        )}
        {showUpload && (
          <>
            <ModalUploadFiles
              close={closeFileModal}
              show={showUpload}
              item={selectedInscripcion}
            ></ModalUploadFiles>
          </>
        )}
      </>
    );
  } else {
    if (loadingTable) {
      return (
        <div className="container">
          <div className="card card-custom">
            <div className="card-body">
              <Loader></Loader>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="container">
            <div className="card card-custom">
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center">
                      <h3>Antes de continuar...</h3>
                      <h5>
                        Por favor, ingresa el nombre de la instancia
                        correspondiente a las incripciones
                      </h5>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 justify-content-center align-items-center">
                      <div className="image-container">
                        <SVG
                          className="custom-svg"
                          src={toAbsoluteUrl("/media/svg/humans/custom-11.svg")}
                        ></SVG>
                      </div>
                    </div>
                  </div>
                  <div className="input-group col-md-6 col-sm-12 col-12">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <span className="svg-icon svg-lg svg-icon-primary">
                          <SVG
                            className="search-i"
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          ></SVG>
                        </span>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={query}
                      onChange={handleSuggering}
                      disabled={!selectedInstance ? false : true}
                    />
                  </div>

                  {!selectedInstance ? (
                    <React.Fragment>
                      {suggestingInstances.length > 0 ? (
                        <React.Fragment>
                          <div className="result-box">
                            {suggestingInstances?.map((item, idx) => (
                              <React.Fragment key={idx}>
                                <div
                                  className="result-box__content"
                                  onClick={() => setSelectedInstance(item)}
                                >
                                  <div className="result-box-content__init">
                                    <span className="symbol symbol-35 symbol-light-success">
                                      <span className="symbol-label font-size-h5 font-weight-bold">
                                        {item.descripcion[0]}
                                      </span>
                                    </span>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg result-box-content-init__desc">
                                      {item.descripcion}
                                    </span>
                                  </div>
                                  <div className="result-box-content__rest">
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg result-box-content-init__desc">
                                      {item?.carrera?.descripcion}
                                    </span>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg result-box-content-init__desc">
                                      Fecha de inicio:{" "}
                                      {dateParser(item.fecha_inicio)}
                                    </span>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="info-box">
                        <span className="close-button" onClick={resetSearch}>
                          x
                        </span>
                        <div className="row">
                          <div className="col-md-6 col-sm-12 col-12">
                            <span className="symbol symbol-100 symbol-light-success">
                              <span className="symbol-label font-size-h5 font-weight-bold">
                                {selectedInstance.descripcion[0]}
                              </span>
                            </span>
                          </div>
                          <div className="col-md-6 col-sm-12 col-12">
                            <label className="form-label">Descripcion</label>

                            <input
                              type="text"
                              value={selectedInstance.descripcion}
                              disabled={true}
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 col-12">
                            <label htmlFor="" className="form-label">
                              Carrera
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedInstance?.carrera?.descripcion}
                              disabled={true}
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 col-12">
                            <label htmlFor="" className="form-label">
                              Fecha de Inicio
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              value={dateParser(
                                selectedInstance.fecha_inicio
                              )}
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 col-12">
                            <label htmlFor="" className="form-label">
                              Fecha de Fin
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              value={dateParser(selectedInstance.fecha_fin)}
                            />
                          </div>
                          <div className="col-md-6 col-sm-12 col-12">
                            <label htmlFor="" className="form-label">
                              Fecha de Públicación
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              value={dateParser(
                                selectedInstance.fecha_publicacion
                              )}
                            />
                          </div>
                        </div>
                        <Button
                          style={{ marginTop: "15px" }}
                          className="btn btn-primary"
                          onClick={() =>
                            callInscriptos_instance(selectedInstance.id)
                          }
                        >
                          Buscar
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
