import React from "react";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@material-ui/core";
import * as AsistenciasStep from "./Steps";
import CustomStepIcon from "../../../RegisterWizard/CustomStepIcon";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Loader from "../../../../components/registerwizard/Loader/Loader";
import * as WizardForm from "../../../../components/registerwizard/schemas";
import { useInscritos_asistencias } from "../../hooks/inscripcionesHooks";
import {
  getAusentes_by_fecha,
  getInscripciones_x_instancia,
  getNotas_byFecha,
  saveAusentes,
  setNota_Inscripto,
  updateAusentes,
} from "../../_redux/reduxCrud";
import SuccessMessage from "../../../../components/General/SuccessMessage";
import { Link, useParams } from "react-router-dom";
import NotaStep3 from "../NotasWizard/Steps/NotasStep3";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepLabel: {
    color: "#fff",
  },
  subwizardContainer: {
    display: "grid",
    gridTemplateColumns: "1fr auto", // Dos columnas, una para el índice y otra para el contenido
    gridColumnGap: theme.spacing(2), // Espacio entre las columnas
    alignItems: "start", // Alinear el contenido al inicio de las columnas
  },
}));

export default function Asistencias() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [ausentes, setAusentes] = React.useState();
  const steps = getSteps();
  const [loading, setLoading] = React.useState(false);
  const [instanciaSelected, setInstanciaSelected] = React.useState();
  const [successModal, setSuccessModal] = React.useState(false);
  const [notas, setNotas] = React.useState();
  const [editMode, setEditMode] = React.useState(false);
  const [isNotas, setIsNotas] = React.useState(false);
  const [loadData, setLoadData] = React.useState(false);
  const { instance } = useParams();

  useInscritos_asistencias(
    instance,
    setActiveStep,
    setEditMode,
    setLoadData,
    setIsNotas,
    setInstanciaSelected
  );

  const estudiantes = useSelector(
    (state) => state.inscripciones.inscriptosAprobados
  );

  const buscarAusentes = async () => {
    if (editMode) {
      if (isNotas) {
        setLoading(true);
        try {
          const data = await getNotas_byFecha({
            instancia_id: instance,
            materia_id: form.values.materia_id,
            fecha_examen: form.values.fecha_examen,
          });

          setNotas(data.notas);
        } catch (error) {
        } finally {
          setLoading(false);
          handleNext();
        }
      } else {
        setLoading(true);

        try {
          const data = await getAusentes_by_fecha({
            instancia_id: instance,
            materia_id: form.values.materia_id,
            fecha_clase: form.values.fecha_clase,
          });
          const response = data.asistencias.map((item) =>
            item.asistencia == 0 ? item.inscripto_id : ""
          );
          form.setFieldValue("ausentes", response);
        } catch (error) {
          console.error("Error al obtener ausentes:", error);
        } finally {
          setLoading(false);
          handleNext();
        }
      }
    }
  };

  const initialValues = WizardForm.generateInitValsFromAusandNotesModules(
    instance
  );

  const initialValuesInit = () => {
    const storedFormData = localStorage.getItem("asistenciasData");
    return storedFormData ? JSON.parse(storedFormData) : initialValues;
  };

  const form = useFormik({
    initialValues: initialValuesInit,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        actionLoading(true);

        if (!editMode) {
          await saveAusentes({
            instancia_id: parseInt(instance),
            materia_id: parseInt(values.materia_id),
            fecha_clase: values.fecha_clase,
            ausentes: values.ausentes,
          });
        } else {
          await updateAusentes({
            instancia_id: parseInt(instance),
            materia_id: parseInt(values.materia_id),
            fecha_clase: values.fecha_clase,
            ausentes: values.ausentes,
          });
        }

        actionLoading(false);
        setSuccessModal(true);
        localStorage.removeItem("stepAsistencias");
        localStorage.removeItem("asistenciasData");
      } catch (error) {
        console.error("Ocurrió un error:", error);
        actionLoading(false);
      }
    },
  });

  const handleMateria_id = (id) => {
    form.setFieldValue("materia_id", id);
    handleNext();
  };

  const handleFecha = (fecha) => {
    isNotas
      ? form.setFieldValue("fecha_examen", fecha)
      : form.setFieldValue("fecha_clase", fecha);
  };
  const handleAusentes = (ausentes) => {
    form.setFieldValue("ausentes", ausentes);
  };

  function isStepOptional(step) {
    return false;
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function handleNext() {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    localStorage.setItem("asistenciasData", JSON.stringify(form.values));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    localStorage.setItem("stepAsistencias", activeStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const actionLoading = (val) => {
    setLoading(val);
  };

  function handleSkip() {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  }

  function handleReset() {
    setActiveStep(0);
    localStorage.removeItem("activeStep");
    localStorage.removeItem("asistenciasData");
    form.resetForm();
    setSuccessModal(false);
  }

  function getSteps() {
    return ["Materia", "Fecha", "Estudiantes"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AsistenciasStep.Step1
            instance={instanciaSelected}
            handleMateria_id={handleMateria_id}
            form={form}
            editMode={editMode}
          ></AsistenciasStep.Step1>
        );
      case 1:
        return (
          <AsistenciasStep.Step2
            handleNext={handleNext}
            handleBack={handleBack}
            handleFecha={handleFecha}
            form={form}
            isNotas={isNotas}
            loading={loading}
            editMode={editMode}
            buscarAusentes={buscarAusentes}
          ></AsistenciasStep.Step2>
        );
      case 2:
        return (
          <>
            {isNotas ? (
              <NotaStep3
                estudiantes={estudiantes}
                handleNext={handleNext}
                handleBack={handleBack}
                handleAusentes={handleAusentes}
                form={form}
                editMode={editMode}
                loading={loading}
                notas={notas}
              ></NotaStep3>
            ) : (
              <AsistenciasStep.Step3
                estudiantes={estudiantes}
                ausentes={ausentes}
                handleAusentes={handleAusentes}
                handleNext={handleNext}
                handleBack={handleBack}
                form={form}
                loading={loading}
                editMode={editMode}
              ></AsistenciasStep.Step3>
            )}
          </>
        );
      default:
        return "Unknown step";
    }
  }

  if (estudiantes) {
    if (!loadData && !instanciaSelected) {
      return (
        <>
          <div className="bg-white p-5 rounded">
            <Loader></Loader>
          </div>
        </>
      );
    } else {
      if (!successModal) {
        return (
          <div className={classes.root}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      StepIconComponent={CustomStepIcon}
                      StepLabelProps={{ classes: { label: classes.stepLabel } }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className="bg-white p-5 rounded">
              <form onSubmit={form.handleSubmit}>
                {getStepContent(activeStep)}
              </form>
            </div>
          </div>
        );
      } else {
        return (
          <SuccessMessage
            cls={classes.root}
            titulo={"las ausencias"}
            opciones={true}
            optArray={[
              { path: "/instancias", desc: "Volver" },
              {
                path: "/instancias/asistencias/1",
                desc: "Cargar Nuevo",
                func: handleReset,
              },
            ]}
          ></SuccessMessage>
        );
      }
    }
  } else {
    return (
      <>
        <div className="card custom-card">
          <div className="card-header">
            <h5>Ups!</h5>
          </div>
          <div className="card-body">
            <p>
              Al parecer todavia no hay estudiantes inscriptos a esta instancia
              de carrera!
            </p>
            <Link to="/instancias">Volver</Link>
          </div>
        </div>
      </>
    );
  }
}
