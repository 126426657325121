import { Button, Checkbox } from "@material-ui/core";
import React from "react";
import {
  setNota_Inscripto,
  updateNota_inscripto,
} from "../../../_redux/reduxCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { find_in_arr_by_id } from "../../../../../../_metronic/_helpers/GeneralHelpers";
import { isSupportedEnvironment } from "react-inlinesvg/lib/helpers";

export default function NotaStep3({
  estudiantes,
  handleNext,
  handleBack,
  handleAusentes,
  form,
  loading,
  notas,
  editMode,
}) {
  const [loadMap, setLoadMap] = React.useState({});
  const [successMap, setSuccessMap] = React.useState({});
  const [errorMap, setErrorMap] = React.useState({});
  const [searchQuery, setSearchQuery] = React.useState("");

  const ipp = 10;
  const [currPage, setCurrPage] = React.useState(1);

  const startIdx = (currPage - 1) * ipp;
  const endIdx = startIdx + ipp;

  const nextPage = () => {
    setCurrPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrPage((ppage) => ppage - 1);
  };

  const goto = (pnum) => {
    setCurrPage(pnum);
  };

  const filteredItems = estudiantes.filter((item) =>
    item.persona?.numero_documento.includes(searchQuery)
  );

  const currItems =
    filteredItems?.length > 0
      ? filteredItems?.slice(startIdx ?? 0, endIdx ?? 0)
      : [];

  const cargarNota = async (id_inscripto) => {
    setLoadMap((prev) => ({
      ...prev,
      [id_inscripto]: true,
    }));
    setErrorMap((prev) => ({
      ...prev,
      [id_inscripto]: false,
    }));
    try {
      let data = null;
      if (editMode) {
        let id_nota = find_in_arr_by_id(
          id_inscripto,
          notas,
          "inscripto_id",
          "id"
        );
        data = await updateNota_inscripto(id_nota, form.values.nota);
      } else {
        data = await setNota_Inscripto(form.values, id_inscripto);
      }
      if (data) {
        setSuccessMap((prev) => ({
          ...prev,
          [id_inscripto]: true,
        }));
      } else {
        setErrorMap((prev) => ({
          ...prev,
          [id_inscripto]: true,
        }));
      }
    } catch (error) {
      setErrorMap((prev) => ({
        ...prev,
        [id_inscripto]: true,
      }));
    } finally {
      setLoadMap((prev) => ({
        ...prev,
        [id_inscripto]: false,
      }));
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-12">
            <h5>Seleccione los Estudiantes que estuvieron ausentes</h5>
          </div>
        </div>
        <div className="card-header border-0 pt-5">
          <div className="card-toolbar">
            <label htmlFor="" className="form-label">
              Buscar por dni
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Buscar..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
          <thead>
            <tr className="text-left text-uppercase">
              <th style={{ minWidth: "200px", textAlign: "center" }}>
                Nombre y Apellido
              </th>
              <th style={{ minWidth: "150px", textAlign: "center" }}>DNI</th>
              <th style={{ minWidth: "100px", textAlign: "center" }}>Nota</th>
            </tr>
          </thead>
          <tbody>
            {currItems ? (
              currItems?.map((item, index) => (
                <tr key={index}>
                  <td className="pl-0 text-center">
                    <span>
                      {item.persona?.apellido}, {item.persona?.nombre}
                    </span>
                  </td>
                  <td className="pl-0 text-center">
                    <span>{item.persona?.numero_documento}</span>
                  </td>
                  <td className="pl-0 text-center">
                    <div className="row justify-content-center">
                      <div className="col-md-4 col-sm-12 col-12">
                        <input
                          type="text"
                          className="form-control"
                          name="nota"
                          id="nota"
                          defaultValue={
                            editMode
                              ? find_in_arr_by_id(
                                  item.id,
                                  notas,
                                  "inscripto_id",
                                  "nota"
                                )
                              : ""
                          }
                          onChange={form.handleChange}
                          placeholder="Nota..."
                          disabled={successMap[item.id]}
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-6">
                        <Button
                          disabled={successMap[item.id] || loadMap[item.id]}
                          className={`btn py-3 px-9 ${
                            errorMap[item.id] ? "btn-danger" : ""
                          } ${successMap[item.id] ? "btn-success" : ""} ${
                            !errorMap[item.id] && !successMap[item.id]
                              ? "btn-primary"
                              : ""
                          }`}
                          onClick={() => cargarNota(item?.id)}
                        >
                          {errorMap[item.id] ? (
                            <>
                              Reintentar
                              <span className="ml-2 svg-icon svg-icon-md svg-icon-white">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Update.svg"
                                  )}
                                  title="Reintentar"
                                ></SVG>
                              </span>
                            </>
                          ) : (
                            <>
                              {successMap[item.id] ? (
                                <>
                                  <span className="ml-2 svg-icon svg-icon-md svg-icon-white">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Check.svg"
                                      )}
                                      title="Reintentar"
                                    ></SVG>
                                  </span>
                                </>
                              ) : (
                                <>{editMode ? <>Modificar</> : <>Cargar</>}</>
                              )}
                            </>
                          )}
                          {loadMap[item.id] && (
                            <>
                              <span
                                className="ml-3 spinner spinner-white"
                                style={{ width: "100%" }}
                              ></span>
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <>
                <p>No hay alumnos inscritos!</p>
              </>
            )}
          </tbody>
        </table>
        <div className="mb-5">
          <div>
            <button
              className="btn btn-light btn-sm"
              disabled={currPage === 1}
              onClick={prevPage}
            >
              Anterior
            </button>
            {estudiantes.length > ipp && (
              <>
                {[...Array(Math.ceil(estudiantes.length / ipp)).keys()].map(
                  (item, idx) => (
                    <button
                      key={idx}
                      className={`btn btn-light btn-sm mx-1 ${
                        currPage === item + 1 ? "active" : ""
                      }`}
                      onClick={() => goto(item + 1)}
                    >
                      {item + 1}
                    </button>
                  )
                )}
              </>
            )}
            <button
              className="btn btn-light btn-sm"
              disabled={endIdx >= estudiantes.length}
              onClick={nextPage}
            >
              Siguiente
            </button>
          </div>
        </div>
        <button
          className="btn btn-secondary btn-md mr-5"
          disabled={loading}
          onClick={() => handleBack()}
        >
          Atras
        </button>
        <button
          className="btn btn-primary btn-md"
          disabled={loading}
          type="submit"
        >
          Finalizar
        </button>
      </div>
    </React.Fragment>
  );
}
