import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Image } from "react-bootstrap";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#025692",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#000",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function Dropzone(props) {
  const [previewFiles, setPreviewFiles] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  const onDrop = useCallback(
    (af, rf) => {
      if (af?.length === 1) {
        setErrorMsg("");
        const file = af[0];
        props.HandleFiles(props.inputName, file);
        setPreviewFiles(
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );
      }

      if (af?.length >= 2) {
        setErrorMsg("Por favor, suba un solo archivo.");
      }

      if (rf?.length) {
        setErrorMsg(
          "El peso del archivo supera 2MB. Por favor, suba otro archivo."
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "image/*": [], "application/pdf": [] },
    onDrop,
    maxSize: 2 * 1024 * 1024,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleRemoveFile = (fileToRemove) => {
    setPreviewFiles(null);
  };

  return (
    <div className="">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {!previewFiles ? (
          <React.Fragment>
            <span className="svg-icon svg-icon-primary svg-icon-lg">
              <span className="svg-icon-primary svg-icon-2x">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")}
                />
              </span>
            </span>
            <span className="iborrainputfile">Subir {props.title}</span>
          </React.Fragment>
        ) : (
          <>
            <ul>
              {previewFiles?.type === "image/jpeg" && (
                <Image src={previewFiles?.preview} className="image-preview" />
              )}
              <p>{previewFiles?.name}</p>
              <button
                onClick={handleRemoveFile}
                className="btn btn-danger btn-md btn-remove-file"
                type="button"
              >
                Eliminar
              </button>
            </ul>
          </>
        )}
        {errorMsg && <p className="text-danger">{errorMsg}</p>}
      </div>
    </div>
  );
}
