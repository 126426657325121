import React from "react";
import Calendario from "../../../../../components/asistencias/Calendario";
import { Button } from "react-bootstrap";
import { useFind_by_fecha } from "../../../hooks/inscripcionesHooks";

export default function AsistenciaStep2({
  handleBack,
  handleNext,
  handleFecha,
  form,
  editMode,
  buscarAusentes,
  loading,
  isNotas,
}) {
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-12">
            <h3>
              {editMode
                ? "Seleccione la fecha a buscar"
                : "Ahora, Seleccione la fecha de la clase"}
            </h3>
          </div>
        </div>
        <div className="row justify-content-center align-items-center my-5">
          <div className="col-md-12 col-sm-12 col-12 text-center">
            <div>
              <Calendario
                date={form.values.fecha_clase ? form.values.fecha_clase : null}
                handleChange={handleFecha}
              ></Calendario>
            </div>
          </div>
        </div>
        <Button
          xvariant="contained"
          type="button"
          style={{ backgroundColor: "#7c7c7c", color: "#fff" }}
          onClick={handleBack}
        >
          Atras
        </Button>
        {editMode ? (
          <>
            <Button
              xvariant="contained"
              type="button"
              style={{ backgroundColor: "#025692", color: "#fff" }}
              onClick={buscarAusentes}
              disabled={loading}
            >
              Buscar
            </Button>
          </>
        ) : (
          <>
            <Button
              xvariant="contained"
              type="button"
              style={{ backgroundColor: "#025692", color: "#fff" }}
              onClick={handleNext}
            >
              Siguiente
            </Button>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
