import React, { useState } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  dateFormatter,
  isExpired,
} from "../../../_metronic/_helpers";
import Loader from "../registerwizard/Loader/Loader";
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from "@material-ui/core";
import { Button } from "react-bootstrap";

export default function TablaInscripciones({
  data,
  className,
  clickModalShow,
  chooseInstance,
  showingDeleteInstance,
  showingModalFilesUpload,
  isLoad,
}) {
  const ipp = 10;
  const [currPage, setCurrPage] = useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState();
  const [filteredItems, setFilteredItems] = React.useState();


  React.useEffect(() => {
    const filteredItems = data.filter((item) =>
      item.persona?.numero_documento.includes(searchQuery)
    );
    setFilteredItems(filteredItems);
  }, [data, searchQuery]);

  const startIdx = (currPage - 1) * ipp;
  const endIdx = startIdx + ipp;
  const nextPage = () => {
    setCurrPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrPage((ppage) => ppage - 1);
  };

  
  const openStateFilter = () => {
    setOpenFilterDialog(!openFilterDialog);
  }

  const set_filter = (e) => {
    setFilterValue(e.target.value);
  }

  const apply_state_filter = () => {
    if(filterValue != 0){
      const filteredItems = data.filter((item) => parseInt(item.estado_id) === parseInt(filterValue));
      setFilteredItems(filteredItems);
    }
    else{
      setFilteredItems(data);
    }

    setOpenFilterDialog(!openFilterDialog);
  }

  const goto = (pnum) => {
    setCurrPage(pnum);
  };


  const currItems =
    filteredItems?.length > 0
      ? filteredItems?.slice(startIdx ?? 0, endIdx ?? 0)
      : [];

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <div className="card-toolbar">
          <label htmlFor="" className="form-label">
            <b>Buscar por dni</b>
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Buscar..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">
                <th style={{ minWidth: "150px" }}>Nombre</th>
                <th style={{ minWidth: "150px" }}>Apellido</th>
                <th style={{ minWidth: "70px" }}>Tipo de Documento</th>
                <th style={{ minWidth: "100px" }}>Numero de Documento</th>
                <th style={{ minWidth: "150px" }}>Codigo Tramite</th>
                <th className="" style={{ minWidth: "125px" }} onClick={openStateFilter}>Estado</th>
                <th style={{ minWidth: "150px" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {isLoad ? (
                <>
                  <tr>
                    <td colSpan="7">
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-12 col-12 col-sm-12 text-center">
                          <Loader></Loader>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {currItems.length > 0 ? (
                    currItems?.map((item, index) => (
                      <tr key={index}>
                        <td className="pl-0">
                          <a
                            href="#"
                            className="text-dark-75 font-weight-bolder text-center text-hover-primary mb-1 font-size-lg"
                          >
                            {item?.persona?.nombre}
                          </a>
                        </td>
                        <td className="pl-0">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {item?.persona?.apellido}
                          </span>
                        </td>
                        <td className="pl-0 text-center">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {item?.persona?.tipo_documento}
                          </span>
                        </td>
                        <td className="pl-0">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {item?.persona?.numero_documento}
                          </span>
                        </td>
                        <td className="pl-0 text-center">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {item?.cod_tramite}
                          </span>
                        </td>
                        <td className="pl-0">
                          {item?.estado_id &&
                            (() => {
                              switch (item.estado_id) {
                                case 1:
                                  return (
                                    <span className="label label-lg label-light-warning label-inline">
                                      Pendiente
                                    </span>
                                  );
                                case 2:
                                  return (
                                    <span className="label label-lg label-light-success label-inline">
                                      Aprobado
                                    </span>
                                  );
                                case 3:
                                  return (
                                    <span className="label label-lg label-light-danger label-inline">
                                      Rechazado
                                    </span>
                                  );
                                case 4:
                                  return(
                                    <span className="label label-lg label-light-info label-inline">
                                      Por empresa
                                    </span>
                                  )
                                  case 5:
                                  return(
                                    <span className="label label-lg label-light-light label-inline">
                                      Por Beca
                                    </span>
                                  )
                                  case 6:
                                  return(
                                    <span className="label label-lg label-light-dark label-inline">
                                      Lista de Espera
                                    </span>
                                  )
                                default:
                                  return null; // Manejar un caso por defecto si es necesario
                              }
                            })()}
                        </td>
                        <td className="pl-0">
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => chooseInstance(item)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => showingModalFilesUpload(item)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Upload.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td>
                          <h3>
                            No hay ingresantes inscriptos en esta instancia!
                          </h3>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="mb-5 d-flex justify-content-between align-items-center w-100">
          <section>
            <button
              className="btn btn-light btn-sm"
              disabled={currPage === 1}
              onClick={prevPage}
            >
              Anterior
            </button>
            {data.length > ipp && (
              <>
              {[...Array(Math.ceil(data.length / ipp)).keys()].map((item, idx) => {
                const startPage = Math.max(1, currPage - 4);
                const endPage = Math.min(
                  Math.ceil(data.length / ipp),
                  startPage + 9
                );
                if (item + 1 >= startPage && item + 1 <= endPage) {
                  return (
                    <button
                      key={idx}
                      className={`btn btn-light btn-sm mx-1 ${
                        currPage === item + 1 ? "active" : ""
                      }`}
                      onClick={() => goto(item + 1)}
                    >
                      {item + 1}
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </>
            )}
            <button
              className="btn btn-light btn-sm"
              disabled={endIdx >= data.length}
              onClick={nextPage}
            >
              Siguiente
            </button>
          </section>
          <section>
            <span>Cantidad de inscriptos {data?.length}</span>
          </section>
        </div>
      </div>
      <Dialog open={openFilterDialog} >
        <DialogTitle>Seleccione un estado a filtrar</DialogTitle>
        <DialogContent>
          <Select onChange={set_filter} >
            <MenuItem value="0">Desfiltrar</MenuItem>
            <MenuItem value="1">Pendiente</MenuItem>
            <MenuItem value="2">Aprobado</MenuItem>
            <MenuItem value="3">Rechazado</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" onClick={openStateFilter}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={apply_state_filter}>
            Filtrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
