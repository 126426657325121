import React, { useState, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Estilos predeterminados de Quill

function QuillEditor({ handling, initialText }) {

  const [editor, setEditor] = useState(null);

  useEffect(() => {
    const editorRef = document.createElement('div');
    document.querySelector('.editor-container').appendChild(editorRef);
    const newEditor = new Quill(editorRef, {
      theme: 'snow'
    });

    if (initialText) {
        newEditor.root.innerHTML = initialText
    }

    newEditor.on('text-change', () => {
        handling(newEditor.root.innerHTML);
    });

    setEditor(newEditor);

    return () => {
      newEditor.off('text-change');
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="editor-container" />;
}
export default QuillEditor;
