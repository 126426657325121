import React from "react";
import { ExampleMaterias } from "../../../../../components/asistencias/exampleMaterias";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";

export default function AsistenciaStep1({ instance, handleMateria_id }) {

  return (
    <React.Fragment>
      <div className="container p-5">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-12">
            <h3>Materias correspondientes a {instance?.descripcion}</h3>
            <h5>
              Por favor, elegi la materia correspondiente de las asistencias de
              los estudiantes
            </h5>
          </div>
        </div>
        <div className="row my-3">
          {instance?.carrera?.materias?.length > 0 ? (
            <>
              {instance?.carrera?.materias?.map((m, i) => (
                <React.Fragment key={i}>
                  <div className="col-md-4 col-sm-12 col-12 my-5">
                    <div
                      className="mat-box"
                      onClick={() => handleMateria_id(m.id)}
                    >
                      <div className="mat-box__title">{m.descripcion}</div>
                      <div className="mat-box__img">
                        <span className="svg-icon svg-icon-lg">
                          <span className="svg-icon svg-icon-white">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Clipboard-list.svg"
                              )}
                            ></SVG>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          ) : (
            <>
              <p>Esta instancia no cuenta con materias</p>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
